/* eslint-disable no-return-await */
import React from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import IconText from 'shared/components/iconText/IconText';
import Button from 'shared/components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'redux-store/auth/authActions';
import { NavLink, useNavigate } from 'react-router-dom';
import { URLS } from 'routes';

import { emptyFieldValidator } from 'shared/utils/validators';
import { PasswordRequirements } from 'shared/components/passwordRequirements/PasswordRequirements';
import appIcon from '../../../assets/imgs/logo.png';
import '../_authentication.scss';

function Login() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = (vals) => {
    dispatch(login(vals))
      .then((payload) => {
        if (!payload.error) {
          navigate(URLS.OTP_URL);
        }
      });
  };
  return (
    <div className="container">
      <div className="formBox">
        <img src={appIcon} alt="" height={48} />
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="email"
            className="formfield"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              { type: 'email', message: 'This is not a valid email' },
            ]}
          >
            <InputField label="Email Address" placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="password"
            className="formfield"
            rules={[{
              validator: emptyFieldValidator, message: 'This field is required',
            }]}
          >
            <InputField label="Password" type="password" placeholder="Enter Password" />
          </Form.Item>
          <div className="formfield">
            <PasswordRequirements />
          </div>
          <div className="formfield">
            <NavLink to={URLS.RESET_PASSWORD_URL}>
              <IconText icon={faCircleQuestion} text="Reset Password" />
            </NavLink>
          </div>
          <div className="actionContainer">
            <Button loading={isLoading} variant={Button.variant.filled} htmlType="submit">
              Log In
            </Button>
            <div style={{ height: 8 }} />
          </div>
          {/* <p className="signUpPrompt">
            Need an account?
            <NavLink to={URLS.SIGNUP_URL}>
              Sign Up
            </NavLink>
          </p> */}
        </Form>
      </div>
    </div>
  );
}

export default Login;
