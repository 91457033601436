import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDeepLinkSettings } from 'redux-store/promosSetup/promosSetupActions';

import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import DropDown from 'shared/components/dropdown/DropDown';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import Grid from 'shared/components/grid/Grid';
import { ProgramsGridColumns } from 'utils/grid/columns';
import './_programs.scss';
import { cancelProgramsSetup, listProgram } from 'redux-store/programs/programActions';
import SearchBox from 'shared/components/searchbox/SearchBox';
import DeepLinkPromo from '../promos/DeepLink';
import memberImage from '../../../../assets/imgs/memberpause.png';

export default function Programs() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, depth: 0, searchText: '',
  };
  const [drpValue, setDrpValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('');
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [dialogOpen, setDialogOpen] = useState({
    title: '', isOpen: false, isResume: false, isCancel: false, isDeepLink: false, data: null,
  });
  const { programs } = useSelector((state) => state.program);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.PROGRAMS_URL}/${id}`);
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.PROGRAMS_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    setStatus('');
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'members') {
      navigate(`${URLS.PROGRAMS_URL}/${item.id}/members`);
    } else if (identifier === 'clone') {
      navigate(`${URLS.PROGRAMS_URL}/${item.id}?clone=true`);
    } else if (identifier === 'delete') {
      setDialogOpen({
        title: 'Program Actions', isOpen: true, isResume: false, isCancel: true, isDeepLink: false, data: item,
      });
    } else if (identifier === 'resume') {
      setStatus('live');
      setDialogOpen({
        title: 'Reactivate Program', isOpen: true, isResume: true, isCancel: false, isDeepLink: false, data: item,
      });
    } else if (identifier === 'deeplink') {
      setDialogOpen({
        title: 'Deep Link', isOpen: true, isResume: false, isCancel: false, isDeepLink: true, data: item,
      });
    }
  };
  useEffect(() => {
    dispatch(getDeepLinkSettings());
  }, []);

  useEffect(() => {
    dispatch(listProgram({ ...params, 'where[and][0][or][0][_status][not_equals]': 'draft', 'where[and][0][or][1][createdByType][not_equals]': 'provider' }));
  }, [params]);

  useEffect(() => {
    setParams({ ...params, page: programs?.page || 1 });
  }, [programs?.page]);

  const applyFilter = (val) => {
    setDrpValue(val);
    const payload = { ...INITIAL_PARAMS };
    if (searchText) {
      payload['where[and][2][programName][like]'] = searchText;
    }
    if (val !== '') {
      setParams({ ...payload, 'where[and][1][status][equals]': val });
    } else {
      setParams({ ...payload });
    }
  };

  const onCancelDialogPopup = () => {
    setDialogOpen({
      ...dialogOpen, title: '', isOpen: false, isResume: false, isCancel: false, isDeepLink: false, data: null,
    });
  };

  const onConfirmCancel = () => {
    dispatch(cancelProgramsSetup({ id: dialogOpen.data?.id, status })).then((payload) => {
      if (_.isEmpty(payload.error)) {
        onCancelDialogPopup();
      }
    });
  };

  const onSearch = (v) => {
    setSearchText(v);
    const payload = {
      ...INITIAL_PARAMS,
      limit: 10,
      page: 1,
    };
    if (drpValue) {
      payload['where[and][2][status][equals]'] = drpValue;
    }
    setParams({
      ...payload,
      'where[and][1][programName][like]': v,
    });
  };

  return (
    <div className="provider-list-container promo-listing">
      <div className="provider-list-container__header">
        <div>
          <span className="search-label">Search</span>
          <SearchBox placeholder="Search Program Title" waitTime={500} onChange={onSearch} />
        </div>
        <div>
          <span className="list-promo-filter">Status</span>
          <DropDown
            placeholder="Status"
            isOptionsObj
            className="provider-list-dropdown promo-drp"
            options={[{ label: 'ALL', value: '' },
              { label: 'LIVE', value: 'live' },
              { label: 'In-Progress', value: 'inReview' },
              { label: 'Cancelled', value: 'cancelled' },
              { label: 'Paused', value: 'paused' },
              { label: 'Full', value: 'full' },
            ]}
            onChange={(e) => { applyFilter(e); }}
            value={drpValue}
          />
        </div>
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Program
          </div>
        </Button>
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={ProgramsGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )}
          data={programs.docs}
          pagination={{
            total: programs.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>

      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen.isOpen}
        title={dialogOpen.title}
        handleOk={() => {}}
        handleCancel={() => onCancelDialogPopup()}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <div className="cancel-section">
          {
          dialogOpen.isCancel && (
            <div className="cancel-program">
              <div className="program-container">
                <span>{`What action do you want to take for ${dialogOpen?.data?.programName}?`}</span>
              </div>
              <div className="program-container">
                <div className="items">
                  <div onClick={() => { setStatus('paused'); }} className={status === 'paused' ? 'action-item item-active' : 'action-item'}>
                    <img src={memberImage} alt="" />
                    <div>
                      <h4>Pause Members</h4>
                      <span>Limit new members from joining the program.</span>
                    </div>
                  </div>
                  <div onClick={() => { setStatus('cancelled'); }} className={status === 'cancelled' ? 'action-item item-active' : 'action-item'}>
                    <img src={memberImage} alt="" />
                    <div>
                      <h4>Cancel Program</h4>
                      <span>End the program permanently.</span>
                      <br />
                      <span>
                        This program will be cleared from Member Home Screen,
                        but the Members remain and you are accountable for them.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-container">
                <Button
                  variant={Button.variant.outlined}
                  onClick={() => { onCancelDialogPopup(); }}
                >
                  Cancel
                </Button>
                <Button
                  variant={Button.variant.filled}
                  onClick={onConfirmCancel}
                  disabled={status === ''}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )
        }
          {
          dialogOpen.isResume && (
            <div className="cancel-program resume-container">
              <div className="program-container">
                <span>{`Are you sure that you want to reactivate this program (${dialogOpen?.data?.programName})?`}</span>
              </div>
              <div className="btn-container">
                <Button
                  variant={Button.variant.outlined}
                  onClick={() => { onCancelDialogPopup(); }}
                >
                  Cancel
                </Button>
                <Button
                  variant={Button.variant.filled}
                  onClick={onConfirmCancel}
                >
                  {status === 'inReview' ? 'Yes' : 'Confirm'}
                </Button>
              </div>
            </div>
          )
        }
          {
          dialogOpen.isDeepLink && (
            <DeepLinkPromo data={dialogOpen.data} tag="Program" onClose={onCancelDialogPopup} />
          )
        }
        </div>

      </DialogPopup>
    </div>
  );
}
