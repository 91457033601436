import { createSlice } from '@reduxjs/toolkit';
import {
  createProgram,
  updateProgram,
  listProgram,
  getPrograms,
  cancelProgramsSetup,
  updateStatusProgramsSetup,
  listProgramsProviders,
  listProgramsStaff,
  listProgramMembers,
  listProgramsAppointments,
  createProgramAppointments,
  updateProgramAppointments,
  updateProgramLinkedAppointments,
  createProgramProvider,
  updateProgramProvider,
  bulkUpdateProgramAppointments,
  bulkUpdateProgramProvider,
  createProgramMember,
  updateProgramMemebr,
  getProgramMember,
  searchUsAddress,
  getProgramPrice,
} from './programActions';

const INITIAL_STATE = {
  isLoading: false,
  isCalcLoading: false,
  programs: { docs: [] },
  programMembers: { docs: [] },
  programStaff: { docs: [] },
  selectedProgram: {},
  programCalculations: {},
  selectedProgramMember: {},
  programsProviders: { docs: [] },
  programsAppointments: { docs: [] },
  address: [],
};

export const programSlice = createSlice({
  name: 'programSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProgram.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProgram.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProgram.fulfilled, (state, action) => {
      state.isLoading = false;
      state.programs = action.payload;
    });
    builder.addCase(listProgramMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProgramMembers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProgramMembers.fulfilled, (state, action) => {
      state.programMembers = action.payload;
      state.isLoading = false;
    });
    builder.addCase(listProgramsProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProgramsProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProgramsProviders.fulfilled, (state, action) => {
      const data = {
        ...action.payload,
        docs: action.payload?.docs?.map((p) => ({
          pid: p.id,
          id: p.provider?.id,
          biography: p.provider?.biography,
          firstName: p.provider?.firstName,
          middleName: p.provider?.middleName,
          lastName: p.provider?.lastName,
          providerType: p.provider?.providerType,
          verificationChecklist: p.provider?.verificationChecklist,
          completedHipaaTrainingDates: p.provider?.completedHipaaTrainingDates,
          deliveryAddresses: p.provider?.deliveryAddresses,
          defaultProcedures: p.provider?.defaultProcedures,
          licensedStateCodes: p.provider?.licensedStateCodes,
          providerStatus: p.provider?.providerStatus,
          mobilePhoneNumber: p.provider?.mobilePhoneNumber,
          email: p.provider?.email,
          status: p.status,
        })),
      };
      state.programsProviders = data;
      state.isLoading = false;
    });
    builder.addCase(listProgramsStaff.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProgramsStaff.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProgramsStaff.fulfilled, (state, action) => {
      const data = {
        ...action.payload,
        docs: action.payload?.docs?.map((p) => ({
          id: p.id,
          pid: p.id,
          biography: p.provider?.biography,
          firstName: p.provider?.firstName,
          middleName: p.provider?.middleName,
          lastName: p.provider?.lastName,
          providerType: p.provider?.providerType,
          verificationChecklist: p.provider?.verificationChecklist,
          completedHipaaTrainingDates: p.provider?.completedHipaaTrainingDates,
          deliveryAddresses: p.provider?.deliveryAddresses,
          defaultProcedures: p.provider?.defaultProcedures,
          licensedStateCodes: p.provider?.licensedStateCodes,
          providerStatus: p.provider?.providerStatus,
          mobilePhoneNumber: p.provider?.mobilePhoneNumber,
          email: p.provider?.email,
          status: p.status,
        })),
      };
      state.programStaff = data;
      state.isLoading = false;
    });
    builder.addCase(listProgramsAppointments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProgramsAppointments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProgramsAppointments.fulfilled, (state, action) => {
      state.programsAppointments = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getPrograms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPrograms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPrograms.fulfilled, (state, action) => {
      state.selectedProgram = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getProgramMember.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProgramMember.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProgramMember.fulfilled, (state, action) => {
      state.selectedProgramMember = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createProgram.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProgram.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgram.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgram.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProgram.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgram.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(cancelProgramsSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelProgramsSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(cancelProgramsSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateStatusProgramsSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateStatusProgramsSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateStatusProgramsSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramAppointments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProgramAppointments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramAppointments.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramAppointments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProgramAppointments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramAppointments.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramLinkedAppointments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProgramLinkedAppointments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramLinkedAppointments.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(bulkUpdateProgramAppointments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bulkUpdateProgramAppointments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(bulkUpdateProgramAppointments.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(bulkUpdateProgramProvider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bulkUpdateProgramProvider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(bulkUpdateProgramProvider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramProvider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProgramProvider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramProvider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramProvider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProgramProvider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramProvider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramMember.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProgramMember.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramMember.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProgramPrice.pending, (state) => {
      state.isCalcLoading = true;
    });
    builder.addCase(getProgramPrice.rejected, (state) => {
      state.isCalcLoading = false;
    });
    builder.addCase(getProgramPrice.fulfilled, (state, action) => {
      state.isCalcLoading = false;
      state.programCalculations = action.payload;
    });
    builder.addCase(updateProgramMemebr.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProgramMemebr.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramMemebr.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchUsAddress.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchUsAddress.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchUsAddress.fulfilled, (state, action) => {
      const data = action.payload?.features?.map((f, index) => ({
        label: f?.properties?.full_address,
        value: f?.properties?.full_address,
        key: index,
        item: {
          addressLine1: f?.properties?.context?.address?.name || f?.properties.name,
          city: f?.properties?.context?.place?.name,
          location: f?.geometry?.coordinates,
          postalCode: f?.properties?.context?.postcode?.name,
          stateCode: f?.properties?.context?.region?.region_code,
        },
      }));
      state.address = data;
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = programSlice.actions;
