import React from 'react';

import './RadioButton.scss';

export default function RadioButton(props) {
  const {
    checked, name, className, label, value, onChange, disabled,
  } = props;
  return (
    <div className={disabled ? 'radio-container ant-disabled' : 'radio-container'}>
      <input
        type="radio"
        checked={checked}
        name={name}
        className={className}
        onClick={() => onChange(value)}
        disabled={disabled}
      />
      <span className="label">{label}</span>
      <span className="checkmark" />
    </div>
  );
}
