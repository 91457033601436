import { createSlice } from '@reduxjs/toolkit';
import {
  getProviderState,
  listProviderState,
  createProviderState,
  updateProviderState,
} from './providerStateActions';

const INITIAL_STATE = {
  isLoading: false,
  providerStates: { docs: [] },
  selectedProviderState: {},
};

export const providerStateSlice = createSlice({
  name: 'providerStateSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderState.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderState.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderState.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providerStates = action.payload;
    });
    builder.addCase(getProviderState.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProviderState.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderState.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProviderState = action.payload;
    });
    builder.addCase(createProviderState.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProviderState.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProviderState.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderState.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProviderState.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderState.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = providerStateSlice.actions;
