import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import chatTick from '../../../assets/imgs/chatTick.png';

export function ProfileImage({ user }) {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);
  const providerName = useMemo(() => {
    const firstName = _.get(user, 'firstName', '') !== null ? _.get(user, 'firstName', '') : '';
    const middleName = _.get(user, 'middleName', '') !== null ? ` ${_.get(user, 'middleName', '')}` : '';
    const lastName = _.get(user, 'lastName', '') !== null ? ` ${_.get(user, 'lastName', '')}` : '';
    const providerType = _.get(user, 'providerType.providerTypeName', '') !== null ? ` ${_.get(user, 'providerType.providerTypeName', '')}` : '';
    return `${firstName}${middleName},${lastName},${providerType}`;
  });

  return (
    <div className="img-text-wrapper">
      <div className="img">
        {!Object.entries(selectedMyProviders?.verificationChecklist || {})
          .find(([, checklist]) => !checklist.verified) && (
            <div className="chat-tick"><img src={chatTick} alt="" width={16} height={16} /></div>)}
        <img src={user?.biography?.providerPhoto?.url} alt="" width={40} height={40} />
      </div>
      <span className="text">
        {providerName}
      </span>
    </div>
  );
}
