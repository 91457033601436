import React from 'react';
import RadioButton from '../radiobutton/RadioButton';

function RadioGroup({
  options,
  onChange,
  value,
  id,
  label,
  style,
}) {
  return (
    <div style={style}>
      {label && <label htmlFor="procedure-group">{label}</label>}
      {options.map((o) => (
        <RadioButton
          className="procedure-radio"
          label={o.label}
          checked={!o.disabled && o.value === value}
          value={o.value}
          onChange={onChange}
          name={id}
          disabled={o.disabled}
          style={{ marginBottom: '15px' }}
        />
      ))}
    </div>

  );
}

export default RadioGroup;
