import React from 'react';
import './_memberHomeScreen.scss';
import Thumbnails from '../../../../assets/imgs/thumbnails.png';
import Single from '../../../../assets/imgs/single.png';
import Horizontal from '../../../../assets/imgs/horizontal.png';
import HorizontalCircle from '../../../../assets/imgs/horizontalcircle.png';
import SingleFolder from '../../../../assets/imgs/singleFolder.png';
import Carousel from '../../../../assets/imgs/carousel.png';

export default function AddNewSection({ onChange }) {
  return (
    <div className="member-home-section-screen">
      <div>
        <span>What type of section would you like to add to the home screen?</span>
      </div>
      <div className="section-types">
        <div className="items" onClick={() => onChange('horizontalScroll')}>
          <span>Horizontal Scroll</span>
          <img src={Horizontal} alt="Horizontal Scroll" />
        </div>
        <div className="items items-single" onClick={() => onChange('singleCardWithText')}>
          <span>Single Card with Text</span>
          <img src={Single} alt="Single Card with Text" />
        </div>
        <div className="items" onClick={() => onChange('singleCardFolder')}>
          <span>Single Card Folder</span>
          <img src={SingleFolder} alt="Single Card Folder" />
        </div>
      </div>
      <div className="section-types">
        <div className="items" onClick={() => onChange('thumbnailGridSquares')}>
          <span>Thumbnail Grid - Squares</span>
          <img src={Thumbnails} alt="Thumbnail Grid - Squares" />
        </div>
        <div className="items items-single" onClick={() => onChange('thumbnailGridCircles')}>
          <span>Thumbnail Grid - Circles</span>
          <img src={HorizontalCircle} alt="Thumbnail Grid - Circles" />
        </div>
        <div className="items" onClick={() => onChange('carousel')}>
          <span>Carousel</span>
          <img src={Carousel} alt="Carousel" />
        </div>
      </div>
    </div>
  );
}
