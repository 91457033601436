/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import {
  Form, Image, Popconfirm, Space,
} from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import './_addAffiliation.scss';
import { isNew } from 'shared/utils/global';
import {
  createAffiliation, getAffiliation, updateAffiliation,
} from 'redux-store/affiliations/affiliationActions';
import { clearData } from 'redux-store/affiliations/affiliationSlice';
import InputField from 'shared/components/inputField/InputField';
import moment from 'moment';
import Button from 'shared/components/button/Button';
import {
  allowDecimalNumbers,
  allowOnlyANSI,
  applyTrim, domainNamePattern, emptyFieldValidator,
} from 'shared/utils/validators';
import DatePicker from 'shared/components/datePicker/DatePicker';
import StatesDropDown from 'shared/components/statesDropDown/StatesDropDown';
import UploadButton from 'shared/components/upload/Upload';
import TextAreaField from 'shared/components/textArea/TextArea';
import {
  GreyTrashIcon,
} from 'utils/grid/columns';
import RadioGroup from 'shared/components/radioGroup/RadioGroup';
import LinkedProcedure from '../procedureManagement/components/addMedicationSets/LinkedProcedure';

export default function AddNewAffiliation() {
  const { id } = useParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageCoverPhotoSrc, setImageCoverPhotSrc] = useState(null);
  const [goLive, setGoLive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {
    selectedAffiliation,
  } = useSelector((state) => state.affiliation);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getAffiliation(id));
    }
    return () => dispatch(clearData());
  }, []);

  const goBack = () => {
    form.resetFields();
    navigate('/dashboard/affiliation/affiliations');
  };

  useEffect(() => {
    if (!_.isEmpty(selectedAffiliation)) {
      setImageSrc(null);
      if (id && selectedAffiliation.affiliationImage?.url) {
        setImageSrc(selectedAffiliation.affiliationImage?.url);
      }
      if (id && selectedAffiliation.affiliationCoverPhoto?.url) {
        setImageCoverPhotSrc(selectedAffiliation.affiliationCoverPhoto?.url);
      }
      form.setFieldsValue({
        ...selectedAffiliation,
        affiliationImage: selectedAffiliation?.affiliationImage?.id,
        affiliationCoverPhoto: selectedAffiliation?.affiliationCoverPhoto?.id,
        effectiveDate: selectedAffiliation?.effectiveDate ? moment(new Date(selectedAffiliation?.effectiveDate)).format('YYYY-MM-DD') : null,
        termDate: selectedAffiliation?.termDate ? moment(new Date(selectedAffiliation?.termDate)).format('YYYY-MM-DD') : null,
      });
      setRefresh(!refresh);
    } else {
      form.setFieldsValue({ affiliationType: 'affiliation' });
    }
  }, [selectedAffiliation]);

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    vals.subscriptionYearlyPrice = 0;
    if (goLive) {
      vals._status = 'published';
    }
    vals.procedures = vals?.procedures?.map((p) => (p.id)) || [];
    vals.programs = vals?.programs?.map((p) => (p.id)) || [];
    if (id === 'new') {
      dispatch(createAffiliation(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateAffiliation({ id, params: vals })).then((payload) => {
        if (_.isEmpty(payload.error)) {
          if (goLive) {
            setGoLive(false);
            toast.success('Affiliation is live');
            goBack();
          }
        }
      });
    }
  };

  const onChangeValue = (key, val) => {
    if (key === 'affiliationType' && form.getFieldValue('affiliationType') !== 'revPlus' && form.getFieldValue('affiliationType') !== 'revPrivate') {
      form.setFields([{
        name: 'emailDomainName',
        value: null,
      },
      {
        name: 'city',
        value: null,
      },
      {
        name: 'stateCode',
        value: null,
      },
      {
        name: 'postalCode',
        value: null,
      }]);
    }
    form.setFields([{
      name: key,
      value: val,
    }]);
    setRefresh(!refresh);
  };

  const removeLinked = (key, index) => {
    const data = form.getFieldValue(key);
    if (data && data.length > 0 && index !== undefined && index !== -1) {
      data.splice(index, 1);
      form.setFields([{ name: key, value: data }]);
      setRefresh(!refresh);
    }
  };

  const onGoLive = () => {
    setGoLive(true);
    form.submit();
  };

  return (
    <div className="affiliation-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={(e) => {
          console.log(e);
          toast.error('Please fill the entire form');
        }}
      >
        <div className="affiliation-container__outer affiliation-college">
          <div className="affiliation-container__wrapper">
            <h1>
              {' '}
              {isNew(id) ? 'New ' : 'Edit '}
              {' '}
              Affiliation
            </h1>
            <div className="section">
              <Form.Item
                name="affiliationType"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioGroup
                  label="Type *"
                  options={[
                    { label: 'Affiliation', value: 'affiliation', disabled: (selectedAffiliation?._status === 'published' && form.getFieldValue('affiliationType') !== 'affiliation') },
                    { label: 'REV+', value: 'revPlus', disabled: selectedAffiliation?._status === 'published' && form.getFieldValue('affiliationType') !== 'revPlus' },
                    { label: 'REVPRIVATE', value: 'revPrivate', disabled: selectedAffiliation?._status === 'published' && form.getFieldValue('affiliationType') !== 'revPrivate' },
                  ]}
                  onChange={(e) => onChangeValue('affiliationType', e)}
                  disabled={selectedAffiliation?._status === 'published'}
                />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="affiliationName"
                rules={[{ required: true, message: 'This field is required' },
                ]}
              >
                <InputField
                  label="Name of the College/Business/Revdoc *"
                  placeholder="Name of the College/Business/Revdoc"
                  className="formfield"
                  maxLength={100}
                />
              </Form.Item>
            </div>
            { (form.getFieldValue('affiliationType') !== 'revPlus' && form.getFieldValue('affiliationType') !== 'revPrivate') && (
            <>
              <div className="section">
                <div className="sub-col">
                  <Form.Item
                    name="emailDomainName"
                    rules={[{ required: true, message: 'This field is required' }, domainNamePattern,
                    ]}
                  >
                    <InputField
                      label="Email Domain Name *"
                      placeholder="ex: revdoc.com"
                      className="formfield"
                      maxLength={250}
                      disabled={selectedAffiliation?._status === 'published'}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="section" style={{ flexDirection: 'column' }}>
                <label>Upload Logo *</label>
                <Form.Item
                  name="affiliationImage"
                  className="formfield"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <UploadButton
                    accept=".jpg, .jpeg, .png"
                    placeholder="Upload Logo"
                    onChange={(e) => setImageSrc(URL.createObjectURL(e))}
                  />
                </Form.Item>
                {imageSrc && (
                <Image
                  className="promo-image"
                  width={200}
                  src={imageSrc}
                />
                )}
              </div>
            </>
            )}
            {form.getFieldValue('affiliationType') !== 'revPrivate' && (
            <div className="section" style={{ flexDirection: 'column' }}>
              <label>Upload Cover Photo *</label>
              <Form.Item
                name="affiliationCoverPhoto"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <UploadButton
                  accept=".jpg, .jpeg, .png"
                  placeholder="Upload Cover Photo"
                  onChange={(e) => setImageCoverPhotSrc(URL.createObjectURL(e))}
                />
              </Form.Item>
              {imageCoverPhotoSrc && (
              <Image
                className="promo-image"
                width={200}
                src={imageCoverPhotoSrc}
              />
              )}
            </div>
            )}
            <div className={(form.getFieldValue('affiliationType') !== 'revPlus' && form.getFieldValue('affiliationType') !== 'revPrivate') ? 'section' : 'last-section'}>
              <div className="sub-col promo-cal">
                <Form.Item
                  name="effectiveDate"
                  className="formfield"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Effective Date *"
                    placeholder="MM/DD/YYYY"
                    disabledDate={(d) => !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    onChange={() => form.setFieldsValue({ termDate: null })}
                    disabled={selectedAffiliation?._status === 'published'}
                  />
                </Form.Item>
              </div>
              <div className="sub-col promo-cal">
                <Form.Item
                  name="termDate"
                  className="formfield"
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Termintion Date"
                    disabledDate={(d) => (form.getFieldValue('effectiveDate') ? moment(d).isBefore(moment(form.getFieldValue('effectiveDate'))) : !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      ))}
                    placeholder="MM/DD/YYYY"
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </div>
            </div>
            {(form.getFieldValue('affiliationType') !== 'revPlus' && form.getFieldValue('affiliationType') !== 'revPrivate') && (
            <>
              <div className="section">
                <div className="sub-col">
                  <Form.Item
                    name="city"
                    rules={[{ required: true, message: 'This field is required' },
                    ]}
                  >
                    <InputField
                      label="City *"
                      placeholder="City"
                      className="formfield"
                      maxLength={150}
                      disabled={selectedAffiliation?._status === 'published'}
                    />
                  </Form.Item>
                </div>
                <div className="sub-col">
                  <label className="check-label-promo">
                    <div>State *</div>
                  </label>
                  <Form.Item
                    name="stateCode"
                    className="formfield"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <StatesDropDown
                      value={form.getFieldValue('stateCode')}
                      placeholder="State"
                      disabled={selectedAffiliation?._status === 'published'}
                    />

                  </Form.Item>
                </div>
                <div className="sub-col">
                  <Form.Item
                    name="postalCode"
                    rules={[{ required: true, message: 'This field is required' },
                    ]}
                  >
                    <InputField
                      label="Zip *"
                      placeholder="Zip"
                      className="formfield"
                      maxLength={20}
                      disabled={selectedAffiliation?._status === 'published'}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="last-section business-welcome-text">
                <Form.Item
                  name="welcomeMessage"
                  rules={[{ required: true, message: 'This field is required' },
                    allowOnlyANSI]}
                >
                  <TextAreaField
                    label="Welcome Message *"
                    placeholder="Welcome Message"
                    rows={3}
                    maxLength={480}
                    className="formfield"
                  />
                </Form.Item>
                <span className="welcome-meesage-info">
                  {form.getFieldValue('welcomeMessage')?.length || 0}
                  /480
                </span>
              </div>
            </>
            )}
          </div>

          <div className="affiliation-container__wrapper extra-sections">
            <h1>Exclusive Procedures</h1>
            <div className="last-section">
              <div className="pgm-procedure-section">
                <div className="apmt-sub-section">
                  <div className="heading">Exclusive procedures available only for this affiliation :</div>
                  <div className="desc">Select which exclusive procedures will be a part of this affiliation.</div>
                </div>
                {
        form.getFieldValue('procedures')?.length > 0 && (
        <div className="card">
          {form.getFieldValue('procedures')?.map((provider, pIndex) => (
            <div className="card-item" key={`pro${pIndex}`}>
              <div className="left">
                <h5>{`${_.get(provider, 'procedureName', '')}`}</h5>
              </div>
              <div className="del-appointment">
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure?"
                    description="Do you want to delete this procedure?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => removeLinked('procedures', pIndex)}
                  >
                    <a>
                      <GreyTrashIcon />
                    </a>
                  </Popconfirm>
                </Space>
              </div>
            </div>
          ))}
        </div>
        )
                  }
                <Form.Item
                  name="procedures"
                  className="formfield"
                >
                  <LinkedProcedure
                    showGrid={false}
                    value={form.getFieldValue('procedures') || []}
                    onChange={(v) => {
                      onChangeValue('procedures', v);
                    }}
                    multiple
                    label=" "
                    tag="Procedure"
                    buttonText="Add Procedure"
                    key={3}
                    showIcon
                    showLabel={false}
                    filter={{ 'where[isRevPlusSpecials][equals]': true }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="affiliation-container__wrapper extra-sections">
            <h1>Exclusive Programs</h1>
            <div className="last-section">
              <div className="pgm-procedure-section">
                <div className="apmt-sub-section">
                  <div className="heading">Exclusive programs available only for this affiliation :</div>
                  <div className="desc">Select which exclusive programs will be a part of this affiliation.</div>
                </div>
                {
        form.getFieldValue('programs')?.length > 0 && (
        <div className="card">
          {form.getFieldValue('programs')?.map((program, pIndex) => (
            <div className="card-item" key={`pro${pIndex}`}>
              <div className="left">
                <h5>{`${_.get(program, 'programName', '')}`}</h5>
              </div>
              <div className="del-appointment">
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure?"
                    description="Do you want to delete this program?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => removeLinked('programs', pIndex)}
                  >
                    <a>
                      <GreyTrashIcon />
                    </a>
                  </Popconfirm>
                </Space>
              </div>
            </div>
          ))}
        </div>
        )
                  }
                <Form.Item
                  name="programs"
                  className="formfield"
                >
                  <LinkedProcedure
                    showGrid={false}
                    value={form.getFieldValue('programs') || []}
                    onChange={(v) => {
                      onChangeValue('programs', v);
                    }}
                    multiple
                    label=" "
                    tag="Program"
                    buttonText="Add Program"
                    key={4}
                    showIcon
                    showLabel={false}
                    filter={{ 'where[isAffiliationProgram][equals]': true }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {(form.getFieldValue('affiliationType') !== 'revPlus' && form.getFieldValue('affiliationType') !== 'revPrivate') && (
          <div className="affiliation-container__wrapper extra-sections">
            <h1>Pricing</h1>
            <div className="last-section college-pricing">
              <div className="lbl-name lbl-amount-name">
                <label>Subscription Price per month: *</label>
              </div>
              <div className="amount-text assessment-text">
                <div className="lbl-name-bold">
                  <label>$</label>
                </div>
                <Form.Item
                  name="subscriptionMonthlyPrice"
                  rules={[{ required: true, message: 'This field is required' },
                    allowDecimalNumbers,
                  ]}
                >
                  <InputField
                    type="number"
                    placeholder="00.00"
                    className="formfield"
                  />
                </Form.Item>
                <div className="lbl-name-bold">
                  <label>/mo</label>
                </div>
              </div>
            </div>
            {/* <div className="last-section college-pricing">
              <div className="lbl-name lbl-amount-name">
                <label>Subscription Price per year: *</label>
              </div>
              <div className="amount-text assessment-text">
                <div className="lbl-name-bold">
                  <label>$</label>
                </div>
                <Form.Item
                  name="subscriptionYearlyPrice"
                  rules={[{ required: true, message: 'This field is required' },
                    allowDecimalNumbers,
                  ]}
                >
                  <InputField
                    type="number"
                    placeholder="00.00"
                    className="formfield"
                  />
                </Form.Item>
                <div className="lbl-name-bold">
                  <label>/year</label>
                </div>
              </div>
            </div>
            <div className="last-section college-pricing">
              <div className="lbl-name lbl-amount-name">
                <label>Calculate the Savings:</label>
              </div>
              <div className="amount-text assessment-text">
                <div className="lbl-name-bold">
                  <label>
                    $
                    {parseFloat(((form.getFieldValue('subscriptionMonthlyPrice') || 0) * 12) -
                    (form.getFieldValue('subscriptionYearlyPrice') || 0)).toFixed(2)}
                    /year
                  </label>
                </div>
              </div>
            </div> */}
          </div>
          )}

          <div className="affiliation-container__outer">
            <div className="action-btn-wrapper">
              <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
              {!isNew(id) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated:</span>
                  <span className="orange">{moment(selectedAffiliation.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
                </div>
              )}

              <div className="btn-promo-outer">
                <Button variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
                {selectedAffiliation?._status !== 'published' && <Button className="green-button" onClick={() => onGoLive()} variant={Button.variant.filled}>Go Live</Button>}
              </div>

            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
