/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Form, AutoComplete, Select } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import './_addProviderState.scss';
import { isNew } from 'shared/utils/global';
import {
  getProviderState,
  listProviderState,
  updateProviderState,
} from 'redux-store/provider-state/providerStateActions';
import { clearData } from 'redux-store/provider-state/providerStateSlice';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import {
  emptyFieldValidator,
  applyTrim,
  blackListedChars,
} from 'shared/utils/validators';
import { URLS } from 'routes';
import moment from 'moment';
import { getMyProviderById, listAllProviders } from 'redux-store/myProviders/myProvidersActions';
import DatePicker from 'shared/components/datePicker/DatePicker';
import CheckBox from 'shared/components/checkbox/CheckBox';
import { getUniqueStates } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { clearProviderData } from 'redux-store/myProviders/myProvidersSlice';

export default function AddProviderState() {
  const { id } = useParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [searchByProviderNamePromise, setSearchByProviderNamePromise] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { selectedProviderState, providerStates } = useSelector((state) => state.providerState);
  const { allProviders, selectedMyProviders } = useSelector((state) => state.myProviders);
  const { uniqueStates } = useSelector((state) => state.userManagementSetups);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getProviderState(id));
    }
    dispatch(clearProviderData());
    dispatch(getUniqueStates({ limit: 0, page: 1 }));
    dispatch(listProviderState({
      limit: 0, page: 1, depth: 0,
    }));
    return () => {
      dispatch(clearProviderData());
      dispatch(clearData());
    };
  }, []);

  const goBack = () => {
    form.resetFields();
    navigate(URLS.PROVIDER_STATES_URL);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedProviderState)) {
      dispatch(getMyProviderById({ id: selectedProviderState?.ownerId }));
    }
  }, [selectedProviderState]);

  useEffect(() => {
    if (!_.isEmpty(selectedMyProviders) && !_.isEmpty(selectedProviderState)) {
      form.setFieldsValue({
        fullName: selectedMyProviders?.fullName,
        ownerId: selectedMyProviders?.id,
        firstName: selectedMyProviders?.firstName || '',
        middleName: selectedMyProviders?.middleName || '',
        lastName: selectedMyProviders?.lastName || '',
        postalCode: selectedMyProviders?.homeAddress?.postalCode || '',
        location: selectedMyProviders?.homeAddress?.location || [],
        ...selectedProviderState,
        licensedStateCodes: selectedMyProviders?.licensedStateCodes,
        termDate: selectedProviderState?.termDate ? moment(selectedProviderState?.termDate?.replace('Z', '')).format('YYYY-MM-DD') : null,
      });
      setRefresh(!refresh);
    } else if (!_.isEmpty(selectedMyProviders)) {
      form.setFields([
        { name: 'fullName', value: selectedMyProviders?.fullName },
        { name: 'ownerId', value: selectedMyProviders?.id },
        { name: 'firstName', value: selectedMyProviders?.firstName || '' },
        { name: 'middleName', value: selectedMyProviders?.middleName || '' },
        { name: 'lastName', value: selectedMyProviders?.lastName || '' },
        { name: 'postalCode', value: selectedMyProviders?.homeAddress?.postalCode || '' },
        { name: 'licensedStateCodes', value: selectedMyProviders?.licensedStateCodes || [] },
        { name: 'location', value: selectedMyProviders?.homeAddress?.location || [] },
      ]);
      setRefresh(!refresh);
    }
  }, [selectedMyProviders]);

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    const dataPayload = {
      termDate: vals?.termDate ? moment(vals?.termDate).format('YYYY-MM-DD') : null,
      'ownerId': vals?.ownerId,
      'isDefaultProvider': vals.isDefaultProvider || false,
      'location': vals.location,
      'postalCode': vals.postalCode,
    };
    if (id === 'new') {
      // get the edit id for the provider.
      const index = providerStates?.docs?.findIndex((o) => o.ownerId === vals?.ownerId);
      if (index !== undefined && index !== -1) {
        const providerStateId = providerStates?.docs[index]?.id;
        dispatch(updateProviderState({
          id: providerStateId,
          params: dataPayload,
        })).then((payload) => {
          if (_.isEmpty(payload.error)) {
            goBack();
          }
        });
      } else {
        toast.error('Default settings not found');
      }
    } else {
      dispatch(updateProviderState({ id, params: dataPayload }));
    }
  };

  const onChange = (data, option) => {
    form.setFields([
      { name: 'ownerId', value: data },
      { name: 'firstName', value: '' },
      { name: 'middleName', value: '' },
      { name: 'lastName', value: '' },
      { name: 'postalCode', value: '' },
      { name: 'licensedStateCodes', value: [] },
      { name: 'location', value: [] },
    ]);
    setRefresh(!refresh);
    if (_.isEmpty(option) && data?.length > 2) {
      const params = {
        limit: 20,
        page: 1,
        depth: 0,
        'where[or][0][firstName][like]': data,
        'where[or][1][lastName][like]': data,
        'where[or][2][middleName][like]': data,
        'where[or][3][npiNumber][like]': data,
      };
      if (searchByProviderNamePromise && searchByProviderNamePromise?.abort) {
        searchByProviderNamePromise?.abort();
      }
      const promise = dispatch(listAllProviders(params));
      setSearchByProviderNamePromise(promise);
      setRefresh(!refresh);
    }
  };

  const onSelect = (data, option) => {
    form.setFields([
      { name: 'fullName', value: null },
    ]);
    const optOutObj = providerStates?.docs?.find((o) => o.ownerId === option?.id);
    if (optOutObj === undefined
      || optOutObj.isOptedOut === undefined
      || optOutObj.isOptedOut === false) {
      dispatch(getMyProviderById({ id: option?.id }));
    } else {
      toast.error('Provider is already Opt Out');
    }
  };

  return (
    <div className="providerState-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={() => {
          toast.error('Please fill the entire form');
        }}
      >
        <div className="providerState-container__outer providerState-college">
          <div className="providerState-container__wrapper">
            <h1>
              {' '}
              {isNew(id) ? 'Add New ' : 'Edit '}
              {' '}
              Provider
            </h1>
            <div className="section">
              <div>
                <label>Search Provider</label>
              </div>
              <div className="provider-sub-container">
                <div className="provider-auto-search">
                  <Form.Item
                    name="postalCode"
                    className="formfield"
                    type="hidden"
                    noStyle
                  >
                    <InputField type="hidden" />
                  </Form.Item>
                  <Form.Item
                    name="ownerId"
                    className="formfield"
                    type="hidden"
                    noStyle
                  >
                    <InputField type="hidden" />
                  </Form.Item>
                  <Form.Item
                    name="location"
                    className="formfield"
                    type="hidden"
                    noStyle
                  >
                    <InputField type="hidden" />
                  </Form.Item>
                  <Form.Item
                    name="fullName"
                    className="formfield"
                    rules={[
                      {
                        validator: emptyFieldValidator,
                        message: 'This field is required',
                      },
                      {
                        min: 3,
                        message: 'This field should have at least 3 characters',
                      },
                      blackListedChars,
                    ]}
                  >
                    <AutoComplete
                      options={allProviders?.docs || []}
                      autoFocus={false}
                      style={{ width: 400 }}
                      onSelect={(v, o) => onSelect(v, o)}
                      onChange={(v, o) => onChange(v, o)}
                      waitTime={1500}
                      allowClear
                      placeholder="Search By Name/ NPI"
                      disabled={!isNew(id)}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="firstName"
                    className="formfield"
                  >
                    <InputField
                      placeholder="First Name"
                      maxLength={10}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="middleName"
                    className="formfield"
                  >
                    <InputField
                      placeholder="Middle Name"
                      maxLength={10}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="lastName"
                    className="formfield"
                  >
                    <InputField
                      placeholder="Last Name"
                      maxLength={10}
                      disabled
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="providerState-optin">
              <div className="last-section">
                <div className="state-multi-select">
                  <div>
                    <label>Provider License State(s)</label>
                  </div>
                  <Form.Item
                    name="licensedStateCodes"
                    className="formfield radio-promo"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select
                      disabled
                      mode="multiple"
                      placeholder="Select State(s)"
                      listHeight={200}
                      suffixIcon={(
                        <div>
                          <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.495053 2.42424L5.95521 8.79612C6.04322 8.89877 6.1524 8.98117 6.27525 9.03766C6.3981 9.09415 6.53171 9.1234 6.66693 9.1234C6.80214 9.1234 6.93576 9.09415 7.05861 9.03766C7.18146 8.98117 7.29064 8.89877 7.37865 8.79612L12.8388 2.42424C13.3599 1.81604 12.9279 0.876587 12.1271 0.876587H1.20521C0.404428 0.876587 -0.027603 1.81604 0.495053 2.42424Z" fill="#393B3D" />
                          </svg>
                        </div>
        )}
                      optionFilterProp="label"
                      style={{ width: '100%' }}
                      options={uniqueStates.map((item) => ({
                        value: item.stateCode,
                        label: item.stateName,
                      }))?.sort((a, b) => (a.label < b.label ? -1 : 1))}
                    />
                  </Form.Item>
                </div>

                <div className="sub-col promo-cal">
                  <Form.Item
                    name="termDate"
                    className="formfield"
                    getValueProps={(i) => ({ value: i && moment(i) })}
                  >
                    <DatePicker
                      label="Termination Date"
                      disabledDate={(d) => !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                      placeholder="MM/DD/YYYY"
                      defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    />
                  </Form.Item>
                </div>

                <div className="sub-col promo-cal def-provider">
                  <Form.Item
                    name="isDefaultProvider"
                    style={{ display: 'flex' }}
                    valuePropName="checked"
                  >
                    <CheckBox label="Default Provider" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="providerState-container__outer">
            <div className="action-btn-wrapper">
              <Button variant={Button.variant.outlined} onClick={goBack}>
                Cancel
              </Button>
              {!isNew(id) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated:</span>
                  <span className="orange">
                    {moment(selectedProviderState.updatedAt).format(
                      'MM/DD/YYYY hh:mm A',
                    )}
                  </span>
                </div>
              )}

              {(selectedProviderState?.isOptedOut === undefined
              || selectedProviderState?.isOptedOut === false)
              && (
              <div className="btn-promo-outer">
                <Button variant={Button.variant.filled} htmlType="submit">
                  {isNew(id) ? 'Save' : 'Update'}
                </Button>
              </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
