import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import Grid from 'shared/components/grid/Grid';
import { ProviderStateGridColumns } from 'utils/grid/columns';
import './_providerState.scss';
import { listProviderState } from 'redux-store/provider-state/providerStateActions';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { listAllProviders } from 'redux-store/myProviders/myProvidersActions';
import { getUniqueStates } from 'redux-store/userManagementSetup/userManagementSetupActions';

export default function ProviderStateList() {
  const INITIAL_PARAMS = {
    limit: 20, page: 1, depth: 0, searchText: '',
  };
  const [params, setParams] = useState(INITIAL_PARAMS);

  const { providerStates } = useSelector((state) => state.providerState);
  const { allProviders } = useSelector((state) => state.myProviders);
  const { providerTypes } = useSelector((state) => state.providerType);
  const { uniqueStates } = useSelector((state) => state.userManagementSetups);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.PROVIDER_STATES_URL}/${id}`);
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.PROVIDER_STATES_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    editClick(item?.id);
  };

  useEffect(() => {
    dispatch(getUniqueStates({ limit: 0, page: 1 }));
    dispatch(listProviderState({
      limit: 0, page: 1, depth: 0,
    }));
  }, []);

  useEffect(() => {
    if (providerStates?.docs?.length > 0) {
      const filter = providerStates?.docs
        ?.filter((p) => p.isDefaultProvider === true)
        ?.reduce((acc, o, i) => {
          acc[`where[id][in][${i}]`] = o.ownerId;
          return acc;
        }, {});
      dispatch(listAllProviders({ ...params, ...filter }));
    }
  }, [params, providerStates]);

  const onSearch = (v) => {
    setParams({
      ...params,
      limit: 20,
      page: 1,
      'where[or][0][firstName][like]': v,
      'where[or][1][lastName][like]': v,
      'where[or][2][middleName][like]': v,
      'where[or][3][npiNumber][like]': v,
    });
  };

  return (
    <div className="provider-list-container program-listing">
      <div className="provider-list-container__header">
        <div className="program-member-filter">
          <div>
            <span className="search-label">Search</span>
            <SearchBox placeholder="Search by Provider name" waitTime={500} onChange={onSearch} />
          </div>
        </div>
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Provider
          </div>
        </Button>
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={ProviderStateGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
            providerTypes,
            providerStates,
            uniqueStates,
          )}
          data={allProviders.docs}
          pagination={{
            total: allProviders.totalDocs,
            current: params.page,
            defaultPageSize: 20,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}
