/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import {
  Form, Image, Upload,
} from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import './_addGiveaway.scss';
import { isNew } from 'shared/utils/global';
import {
  createGiveaway,
  getGiveaway,
  listGiveaways,
  updateGiveaway,
} from 'redux-store/giveaways/giveawayActions';
import { clearData } from 'redux-store/giveaways/giveawaySlice';
import InputField from 'shared/components/inputField/InputField';
import moment from 'moment';
import Button from 'shared/components/button/Button';
import {
  applyTrim,
  emptyFieldValidator,
} from 'shared/utils/validators';
import DatePicker from 'shared/components/datePicker/DatePicker';
import TextAreaField from 'shared/components/textArea/TextArea';
import { RichTextEditor } from 'shared/components/richTextEditor/RichTextEditor';
import LinkedProcedure from '../procedureManagement/components/addMedicationSets/LinkedProcedure';
import { GiveawayCriteriaItem } from './GiveawayCriteriaItem';

export default function AddNewGiveaway() {
  const { id } = useParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [goLive, setGoLive] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { selectedGiveaway } = useSelector((state) => state.giveaway);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getGiveaway(id));
    }
    return () => dispatch(clearData());
  }, []);

  const goBack = () => {
    form.resetFields();
    navigate('/dashboard/giveaway/giveaways');
  };

  useEffect(() => {
    if (!_.isEmpty(selectedGiveaway)) {
      setFileList([]);
      const mappedCriteria = selectedGiveaway?.criterias
        ?.map((c) => ({
          ...c,
          criteriaLabel: c.criteriaLabel ? c.criteriaLabel : c.giveawayCriteria?.criteriaName,
          criteriaDescription: c.criteriaDescription ? c.criteriaDescription
            : c.giveawayCriteria?.criteriaDescription,
          isIncludeButton: c.isIncludeButton || c.giveawayCriteria?.isIncludeButton,
          textOnButton: c.textOnButton || c.giveawayCriteria?.textOnButton,
        }));
      if (id && selectedGiveaway.giveawayImages?.length > 0) {
        const imageList = selectedGiveaway.giveawayImages?.map((img) => ({ ...img.giveawayImage }));
        setFileList(imageList);
      }
      form.setFieldsValue({
        ...selectedGiveaway,
        giveawayImages: {
          fileList: selectedGiveaway?.giveawayImages
            ?.map((imgs) => (imgs?.giveawayImage?.id)),
        },
        effectiveDate: selectedGiveaway?.effectiveDate
          ? moment(selectedGiveaway?.effectiveDate?.replace('Z', '')).format(
            'YYYY-MM-DD',
          )
          : null,
        termDate: selectedGiveaway?.termDate
          ? moment(selectedGiveaway?.termDate?.replace('Z', '')).format('YYYY-MM-DD')
          : null,
        announcementDate: selectedGiveaway?.announcementDate
          ? moment(selectedGiveaway?.announcementDate?.replace('Z', '')).format('YYYY-MM-DD')
          : null,
        requiredCriterias: mappedCriteria?.filter((x) => x.isRequired === true)
          ?.map((s, i) => ({ ...s, order: i + 1 })),
        nonRequiredCriterias: mappedCriteria?.filter((x) => x.isRequired === false)
          ?.map((s, i) => ({ ...s, order: i + 1 })),
      });
      setRefresh(!refresh);
    } else {
      form.setFields([{ name: 'requiredCriterias', value: [] },
        { name: 'nonRequiredCriterias', value: [] }]);
    }
  }, [selectedGiveaway]);

  const handleSubmit = async (vals) => {
    if (vals?.giveawayImages?.fileList?.length > 0) {
      setError(false);
      vals = applyTrim(vals);
      vals.subscriptionYearlyPrice = 0;
      if (goLive) {
        vals._status = 'published';
      }
      vals.effectiveDate = vals?.effectiveDate ? moment(vals?.effectiveDate).format('YYYY-MM-DD') : null;
      vals.termDate = vals?.termDate ? moment(vals?.termDate).format('YYYY-MM-DD') : null;
      vals.announcementDate = vals?.announcementDate ? moment(vals?.announcementDate).format('YYYY-MM-DD') : null;
      const requiredCriteria = vals.requiredCriterias
        ?.map((x) => ({ ...x, giveawayCriteria: x.giveawayCriteria?.id })) || [];
      const nonRequiredCriteria = vals.nonRequiredCriterias
        ?.map((x) => ({ ...x, giveawayCriteria: x.giveawayCriteria?.id })) || [];
      const combinedCriteria = requiredCriteria.concat(nonRequiredCriteria);
      vals.criterias = combinedCriteria?.map((x, i) => ({ ...x, criteriaSequence: i + 1 }));
      if (vals.requiredCriterias) {
        delete vals.requiredCriterias;
      }
      if (vals.nonRequiredCriterias) {
        delete vals.nonRequiredCriterias;
      }
      if (id === 'new') {
        dispatch(createGiveaway(vals)).then((payload) => {
          if (_.isEmpty(payload.error)) {
            goBack();
          }
        });
      } else {
        const dataPayload = { ...vals };
        if (selectedGiveaway._status === 'published') {
          if (dataPayload.effectiveDate) {
            delete dataPayload.effectiveDate;
          }
        }
        dispatch(updateGiveaway({ id, params: dataPayload })).then((payload) => {
          if (_.isEmpty(payload.error)) {
            if (goLive) {
              setGoLive(false);
              toast.success('Giveaway is live');
              dispatch(listGiveaways({ page: 1, limit: 10 }));
              goBack();
            }
          }
        });
      }
    } else {
      setError(true);
      toast.error('Please fill the entire form');
    }
  };

  const onGoLive = () => {
    setGoLive(true);
    form.submit();
  };

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (uerror) => reject(uerror);
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
  };

  const handleChange = async ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <div style={{ marginTop: 8 }}>Upload Photo(s)</div>
    </button>
  );

  const getValues = () => {
    const requiredArray = form.getFieldValue('requiredCriterias')
      ?.map((x) => ({
        ...x,
        ...x.giveawayCriteria,
        isRequired: true,
        id: (x.giveawayCriteria?.isCustomCriteria === true
          || x.isCustomCriteria === true) ? null : x.giveawayCriteria?.id,
      })) || [];
    const nonRequiredArray = form.getFieldValue('nonRequiredCriterias')
      ?.map((x) => ({
        ...x,
        ...x.giveawayCriteria,
        isRequired: false,
        id: (x.giveawayCriteria?.isCustomCriteria === true
          || x.isCustomCriteria === true) ? null : x.giveawayCriteria?.id,
      })) || [];
    const combned = requiredArray.concat(nonRequiredArray);
    return combned;
  };

  const onChangeRequired = (index, type) => {
    const requiredCriteria = form.getFieldValue('requiredCriterias') || [];
    const nonRequiredCriteria = form.getFieldValue('nonRequiredCriterias') || [];
    if (type === 'requiredCriterias') {
      const item = requiredCriteria[index];
      requiredCriteria.splice(index, 1);
      nonRequiredCriteria.push({
        ...item,
        isRequired: false,
        order: (nonRequiredCriteria?.length || 0 + 1),
      });
    } else {
      const item = nonRequiredCriteria[index];
      nonRequiredCriteria.splice(index, 1);
      requiredCriteria.push({
        ...item,
        isRequired: true,
        order: (requiredCriteria?.length || 0 + 1),
      });
    }
    form.setFields([
      { name: 'requiredCriterias', value: requiredCriteria?.map((x, i) => ({ ...x, order: i + 1 })) },
      { name: 'nonRequiredCriterias', value: nonRequiredCriteria?.map((x, i) => ({ ...x, order: i + 1 })) },
    ]);
  };

  const onChangeOrder = (index, type, value) => {
    const data = JSON.parse(JSON.stringify(form.getFieldValue(type) || []));
    const oIndex = data.findIndex((x) => x.order === value);
    if (oIndex !== -1) {
      const itemToInsert = data[index];
      data.splice(index, 1);
      data.splice(oIndex, 0, itemToInsert);
      data?.map((x, j) => { x.order = j + 1; return x; });
      form.setFields([{ name: type, value: data }]);
    }
    setRefresh(!refresh);
  };

  const onAddCriteria = (v) => {
    const data = v?.filter((x) => (x.isRequired === false || x.isRequired === undefined));
    const validaCriteria = JSON.parse(JSON.stringify(data))?.map((x) => {
      if (x.giveawayCriteria === undefined) {
        x.giveawayCriteria = { ...x };
        x.criteriaLabel = x.criteriaLabel || x.criteriaName;
        x.isNew = true;
      }
      return x;
    });
    form.setFields([{ name: 'nonRequiredCriterias', value: validaCriteria }]);
    setRefresh(!refresh);
  };

  const emptyCriteriaFieldValidator = (m, v) => {
    const rv = form.getFieldValue('nonRequiredCriterias') || [];
    if ((v === undefined && rv === undefined) || (v?.length === 0 && rv.length === 0)) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  };

  return (
    <div className="giveaway-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={() => {
          setError(true);
          toast.error('Please fill the entire form');
        }}
      >
        <div className="giveaway-container__outer giveaway-college">
          <div className="giveaway-container__wrapper">
            <h1>
              {' '}
              {isNew(id) ? 'New ' : 'Edit '}
              {' '}
              Giveaway
            </h1>
            <div className="section">
              <Form.Item
                name="giveawayName"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <InputField
                  label="Name of Giveaway *"
                  placeholder="Name of Giveaway"
                  className="formfield"
                  maxLength={100}
                />
              </Form.Item>
            </div>

            <div className="section" style={{ flexDirection: 'column' }}>
              <label>Upload Photo(s) *</label>
              <Form.Item
                name="giveawayImages"
                className="formfield"
              >
                <Upload
                  beforeUpload={() => false}
                  accept=".jpg, .jpeg, .png"
                  placeholder="Upload Photo(s)"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  maxCount={8}
                  multiple
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
              </Form.Item>
              {previewImage && (
                <Image
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
              {(form.isFieldTouched('giveawayImages') || error)
                && (form.getFieldValue('giveawayImages')?.fileList?.length === undefined
                  || form.getFieldValue('giveawayImages')?.fileList?.length === 0) && (
                  <div className="promo-error">This field is required</div>
              )}
            </div>

            <div className="section business-welcome-text">
              <Form.Item
                name="giveawayDescription"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <TextAreaField
                  label="Giveaway Description *"
                  placeholder="Giveaway Description"
                  rows={3}
                  maxLength={480}
                  className="formfield"
                />
              </Form.Item>
              <span className="text-count-info">
                {form.getFieldValue('giveawayDescription')?.length || 0}
                /480
              </span>
            </div>

            <div className="section">
              <Form.Item
                name="termsAndConditionsLabel"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <InputField
                  label="Terms And Conditions Checkbox Label *"
                  placeholder="Terms And Conditions Checkbox Label"
                  className="formfield"
                  maxLength={100}
                />
              </Form.Item>
            </div>

            <div className="section">
              <div className="sub-col promo-cal">
                <Form.Item
                  name="effectiveDate"
                  className="formfield"
                  rules={[
                    {
                      validator: emptyFieldValidator,
                      message: 'This field is required',
                    },
                  ]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    disabled={selectedGiveaway?._status === 'published'}
                    label="Effective Date *"
                    placeholder="MM/DD/YYYY"
                    disabledDate={(d) => !d
                      || d.isBefore(
                        moment(new Date(), 'MM-DD-YYYY').subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    onChange={() => form.setFieldsValue({ termDate: null })}
                  />
                </Form.Item>
              </div>
              <div className="sub-col promo-cal">
                <Form.Item
                  name="termDate"
                  className="formfield"
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Termintion Date"
                    disabledDate={(d) => (form.getFieldValue('effectiveDate')
                      ? moment(d).isBefore(
                        moment(form.getFieldValue('effectiveDate')),
                      )
                      : !d
                          || d.isBefore(
                            moment(new Date(), 'MM-DD-YYYY').subtract(1, 'd'),
                          ))}
                    placeholder="MM/DD/YYYY"
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </div>
              <div className="sub-col promo-cal">
                <Form.Item
                  name="announcementDate"
                  className="formfield"
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Announcement Date"
                    disabledDate={(d) => d.isBefore(
                      moment(new Date(), 'MM-DD-YYYY').subtract(1, 'd'),
                    )}
                    placeholder="MM/DD/YYYY"
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="section criteria-section">
              <label className="check-label-promo">
                <div>Add Criteria *</div>
              </label>
              <div>
                <Form.Item noStyle name="criterias" />
                <LinkedProcedure
                  showGrid={false}
                  value={getValues()}
                  onChange={(v) => onAddCriteria(v)}
                  multiple
                  key={1}
                  buttonText="Add Criteria"
                  tag="Criteria"
                  showIcon={false}
                  showLabel={false}
                />
                <div>
                  {(form.isFieldTouched('requiredCriterias') || form.isFieldTouched('nonRequiredCriterias')
                || error)
                && ((form.getFieldValue('requiredCriterias')?.length === 0 && form.getFieldValue('nonRequiredCriterias')?.length === 0)
                    || (form.getFieldValue('requiredCriterias')?.length === undefined && form.getFieldValue('nonRequiredCriterias')?.length === undefined)) && (
                    <div className="promo-error">This field is required</div>
                  )}
                </div>
              </div>
              <div className="criteria-container">
                <div className="header">
                  <label>How to Enter (required criteria)</label>
                </div>
                <div className="cbody">
                  <Form.List
                    name="requiredCriterias"
                    rules={[{ validator: emptyCriteriaFieldValidator, message: 'This field is required' }]}
                  >
                    {(fields, { remove }) => (
                      <div className="criteria-outer">
                        {fields.map((item, index) => (
                          <GiveawayCriteriaItem
                            key={`requiredCriterias ${index}`}
                            onChangeRequired={onChangeRequired}
                            remove={remove}
                            index={index}
                            form={form}
                            item={item}
                            onChangeOrder={onChangeOrder}
                            type="requiredCriterias"
                            disabled={selectedGiveaway?._status === 'published'}
                          />
                        ))}
                      </div>
                    )}
                  </Form.List>
                </div>
              </div>
              <div className="criteria-container">
                <div className="header">
                  <label>Earn Additional Chances to Win (non-required criteria)</label>
                </div>
                <div className="cbody">
                  <Form.List
                    name="nonRequiredCriterias"
                  >
                    {(fields, { remove }) => (
                      <div className="criteria-outer">
                        {fields.map((item, index) => (
                          <GiveawayCriteriaItem
                            key={`nonRequiredCriterias ${index}`}
                            onChangeRequired={onChangeRequired}
                            remove={remove}
                            index={index}
                            form={form}
                            item={item}
                            onChangeOrder={onChangeOrder}
                            type="nonRequiredCriterias"
                            disabled={form.getFieldValue(['nonRequiredCriterias', item.name, 'isNew']) !== true && selectedGiveaway?._status === 'published'}
                          />
                        ))}
                      </div>
                    )}
                  </Form.List>
                </div>
              </div>
            </div>

            <div className="last-section business-welcome-text">
              <label className="check-label-promo">
                <div>T&C *</div>
              </label>
              <Form.Item
                name="termsAndConditions"
                rules={[
                  {
                    validator: (m, v) => {
                      const val = v && v.replace(/&nbsp;/g, '').replace(/ /g, '');
                      if (
                        val === '<style>p{margin:2px0px;}</style>'
                        || val === '<p></p><style>p{margin:2px0px;}</style>' || val === undefined
                      ) {
                        return Promise.reject(
                          new Error('This field is required'),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <RichTextEditor />
              </Form.Item>
            </div>
          </div>

          <div className="giveaway-container__outer">
            <div className="action-btn-wrapper">
              <Button variant={Button.variant.outlined} onClick={goBack}>
                Cancel
              </Button>
              {!isNew(id) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated:</span>
                  <span className="orange">
                    {moment(selectedGiveaway.updatedAt).format(
                      'MM/DD/YYYY hh:mm A',
                    )}
                  </span>
                </div>
              )}

              <div className="btn-promo-outer">
                {(selectedGiveaway?.termDate === null
                  || selectedGiveaway?.termDate === undefined
                  || (selectedGiveaway?.termDate
                    && moment().isSameOrBefore(
                      moment(selectedGiveaway?.termDate),
                    ))) && (
                    <Button variant={Button.variant.filled} htmlType="submit">
                      {isNew(id) ? 'Save' : 'Update'}
                    </Button>
                )}
                {selectedGiveaway?._status !== 'published' && (
                  <Button
                    className="green-button"
                    onClick={() => onGoLive()}
                    variant={Button.variant.filled}
                  >
                    Go Live
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
