import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  getProviderAlerts,
  listProviderAlerts, updateAlerts, updateMultipleAlerts,
} from './alertsActions';

const INITIAL_STATE = {
  isLoading: false,
  alerts: { docs: [] },
  providerAlerts: { docs: [] },
};

export const alerts = createSlice({
  name: 'alerts',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderAlerts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.alerts = {
        ...action.payload,
        docs: action.payload?.docs?.map((x) => ({ ...x, key: x.id })),
      };
    });
    builder.addCase(getProviderAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProviderAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderAlerts.fulfilled, (state, action) => {
      state.isLoading = false;
      const data = action.payload?.docs?.map((x) => {
        let { dueDate } = x;
        if (x.dueDate?.includes('Days') || x.dueDate?.includes('Expired')) {
          try {
            const regx = /\d{4}[-]\d{2}[-]\d{2}/gm;
            const regex = new RegExp(regx);
            const result = x.alertKey.match(regex);
            if (result?.length > 0) {
              dueDate = moment(result[0]).format('MM/DD/YYYY');
            }
          } catch (e) {
            console.log(e);
          }
        }
        return {
          ...x,
          dueDate,
        };
      });
      state.providerAlerts = { ...action.payload, docs: data };
    });
    builder.addCase(updateAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAlerts.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMultipleAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleAlerts.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = alerts.actions;
