import { createSlice } from '@reduxjs/toolkit';
import {
  getGiveaway,
  listGiveaways,
  createGiveaway,
  updateGiveaway,
} from './giveawayActions';

const INITIAL_STATE = {
  isLoading: false,
  giveaways: { docs: [] },
  selectedGiveaway: {},
};

export const giveawaySlice = createSlice({
  name: 'giveawaySlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listGiveaways.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listGiveaways.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listGiveaways.fulfilled, (state, action) => {
      state.isLoading = false;
      state.giveaways = action.payload;
    });
    builder.addCase(getGiveaway.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGiveaway.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getGiveaway.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedGiveaway = action.payload;
    });
    builder.addCase(createGiveaway.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createGiveaway.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createGiveaway.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateGiveaway.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateGiveaway.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateGiveaway.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = giveawaySlice.actions;
