/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getInsuranceSetup } from 'redux-store/insuranceSetup/insuranceSetupActions';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';
import {
  PRM_PHOTO, PRM_VIDEO, STAFF_PHOTO, STAFF_VIDEO,
} from 'shared/constants/global';
import { fetchGql } from 'shared/axios/superTokenClient';
import {
  MY_PROVIDERS_API_URL, SUPERVISION_API_URL, CONCIERAGE_PRIACTICES_API_URL, AVAILABILITY_API_URL,
  SUPERVISION_REQUEST_API_URL, IPROSPECT_REQUEST_API_URL,
} from './myProvidersUrls';
import { getProviderPayload, getProviderPayloadMin } from './myProviderGraphQl';

export const getAllProvidersApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(MY_PROVIDERS_API_URL(`?${query}`)).then(({ data }) => data);
};

export const listMyProviders = createAsyncThunk(
  'myProvidersSlice/listMyProviders',
  async (filters, { getState }) => {
    const state = getState();
    // eslint-disable-next-line no-unused-vars
    const myId = state?.auth?.user?.id;
    return getAllProvidersApiCall({
      'where[providerRelationshipManager][in][0]': myId,
      ...filters,
    });
  },
);

export const listAllProviders = createAsyncThunk(
  'myProvidersSlice/listAllProviders',
  async (filters) => {
    return getAllProvidersApiCall({
      ...filters,
    });
  },
);

export const getMyProviderById = createAsyncThunk(
  'myProvidersSlice/getMyProviderById',
  async ({ id }) => {
    const params = getProviderPayloadMin(id);
    return fetchGql(params).then(async ({ data }) => {
      return data?.Provider;
    });
  },
);

export const getMyProviders = createAsyncThunk(
  'myProvidersSlice/getMyProviders',
  async ({ id, uniqueStates }, { dispatch }) => {
    const params = getProviderPayload(id, uniqueStates);
    return fetchGql(params).then(async ({ data }) => {
      // gets the orgs for insurance
      const orgs = await Promise
        .all(
          _.get(data, 'Provider.insurancePlan', [])
            .map((i) => dispatch(getInsuranceSetup(`?where[plans][equals]=${i.id}`))),
        )
        .then((payload) => {
          // eslint-disable-next-line array-callback-return, consistent-return
          return payload.map((p) => {
            if (!p.error) {
              const insurance = data?.Provider?.insurancePlan?.find((i) => i.id === (p.meta.arg.split('=')?.[1]));
              const org = _.get(p, 'payload.docs[0]');
              return { ...insurance, org };
            }
          });
        });

      return { ...data?.Provider, insurancePlan: orgs };
    });
  },
);

export const createMyProviders = createAsyncThunk(
  'myProvidersSlice/createMyProviders',
  async (params) => {
    return axios.post(
      MY_PROVIDERS_API_URL(),
      params,
    ).then(({ data }) => {
      toast.success('Supply has been created');
      return data;
    });
  },
);

export const updateMyProviders = createAsyncThunk(
  'myProvidersSlice/updateMyProviders',
  async ({ id, params, uniqueStates }, { dispatch }) => {
    let imgID = _.get(params, 'profilePhoto', null);
    let videoID = _.get(params, 'profileVideo', null);
    if (!_.isEmpty(params, 'profilePhoto') && typeof (params.profilePhoto) === 'object') {
      const img = await createMedia(
        params.profilePhoto,
        params.isPRM ? PRM_PHOTO : STAFF_PHOTO,
      );
      imgID = img.doc.id;
      params.profilePhoto = imgID;
    }
    if (!_.isEmpty(params, 'profileVideo') && typeof (params.profileVideo) === 'object') {
      const video = await createMedia(
        params.profileVideo,
        params.isPRM ? PRM_VIDEO : STAFF_VIDEO,
      );
      videoID = video.doc.id;
      params.profileVideo = videoID;
    }

    return axios.patch(
      MY_PROVIDERS_API_URL(id),
      params,
    ).then(() => {
      toast.success('User has been updated');
      return dispatch(getMyProviders({ id, uniqueStates }));
    });
  },
);

export const deleteMyProviders = createAsyncThunk(
  'myProvidersSlice/deleteMyProviders',
  async (id, { dispatch }) => {
    return axios.patch(MY_PROVIDERS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('User has been deleted');
        return dispatch(listMyProviders());
      });
  },
);

export const updateMultipleMyProviders = createAsyncThunk(
  'myProvidersSlice/updateMultipleMyProviders',
  async ({ query, params }) => {
    let imgID = _.get(params, 'profilePhoto', null);
    let videoID = _.get(params, 'profileVideo', null);
    if (!_.isEmpty(params, 'profilePhoto') && typeof (params.profilePhoto) === 'object') {
      const img = await createMedia(
        params.profilePhoto,
        params.isPRM ? PRM_PHOTO : STAFF_PHOTO,
      );
      imgID = img.doc.id;
      params.profilePhoto = imgID;
    }
    if (!_.isEmpty(params, 'profileVideo') && typeof (params.profileVideo) === 'object') {
      const video = await createMedia(
        params.profileVideo,
        params.isPRM ? PRM_VIDEO : STAFF_VIDEO,
      );
      videoID = video.doc.id;
      params.profileVideo = videoID;
    }

    return axios.patch(
      MY_PROVIDERS_API_URL(`?${new URLSearchParams(query).toString()}`),
      params,
    ).then(() => {
      toast.success('User has been updated');
      return Promise.resolve();
    });
  },
);

export const getSuperVisionApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(SUPERVISION_API_URL(`?${query}`)).then(({ data }) => data);
};

export const getSupervisors = createAsyncThunk(
  'myProvidersSlice/getSupervisors',
  async (filters) => {
    return getSuperVisionApiCall({
      ...filters, depth: 1,
    });
  },
);

export const updateConciergePractices = createAsyncThunk(
  'myProvidersSlice/updateConciergePractices',
  async (data, { dispatch }) => {
    return axios.patch(CONCIERAGE_PRIACTICES_API_URL(data?.data?.id), data?.data)
      .then(() => {
        return dispatch(getMyProviders(data?.id));
      });
  },
);

export const getProviderAvailabilityApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(AVAILABILITY_API_URL(`?${query}`)).then(({ data }) => data);
};

export const getAvailabilities = createAsyncThunk(
  'myProvidersSlice/getAvailabilities',
  async (filters) => {
    return getProviderAvailabilityApiCall({
      ...filters,
    });
  },
);

export const getSuperVisionRequestApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(SUPERVISION_REQUEST_API_URL(`?${query}`)).then(({ data }) => data);
};

export const getSuperVisionRequest = createAsyncThunk(
  'myProvidersSlice/getSuperVisionRequest',
  async (filters) => {
    return getSuperVisionRequestApiCall({
      ...filters, depth: 1,
    });
  },
);

export const getProviderIProspectRequestApiCall = (id) => {
  return axios.get(IPROSPECT_REQUEST_API_URL(`/${id}/raw`)).then(({ data }) => data);
};

export const getIProspectDocument = createAsyncThunk(
  'myProvidersSlice/getIProspectDocument',
  async (id) => {
    return getProviderIProspectRequestApiCall(id);
  },
);
