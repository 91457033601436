import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { Form } from 'antd';
import './_howToPrepare.scss';
import { emptyFieldValidator } from 'shared/utils/validators';
import TextAreaField from 'shared/components/textArea/TextArea';
import { getPreparationSteps, savePreparationSteps } from 'redux-store/preparationSteps/preparationStepsActions';

export default function HowToPrepare() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);
  const { preparationSteps } = useSelector((s) => s.preparationSteps);

  const handleSubmit = (vals) => {
    dispatch(savePreparationSteps(vals));
  };

  useEffect(() => {
    dispatch(getPreparationSteps());
  }, []);

  useEffect(() => {
    form.setFieldsValue(preparationSteps);
  }, [preparationSteps]);

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={() => forceUpdate((v) => !v)}
      form={form}
    >
      <div className="add-new-member-faq-container prepContainer">
        <div className="add-new-member-faq-container__body" style={{ padding: 0, backgroundColor: 'unset' }}>
          <Form.Item
            name="preparationHousecall"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              { min: 10, message: 'This Field must be 10 characters' },
              { max: 1000, message: 'This Field can not exceed 1000 characters' },
            ]}
          >
            <TextAreaField
              className="preptextArea"
              label="How to prepare for a House Call *"
              placeholder="How to prepare for a House Call"
              rows={3}
            />
          </Form.Item>
          <Form.Item
            name="preparationVan"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              { min: 10, message: 'This Field must be 10 characters' },
              { max: 1000, message: 'This Field can not exceed 1000 characters' },
            ]}
          >
            <TextAreaField
              className="preptextArea"
              label="How to prepare for a Van Call *"
              placeholder="How to prepare for a Van Call"
              rows={3}
            />
          </Form.Item>
          <Form.Item
            name="preparationFacility"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              { min: 10, message: 'This Field must be 10 characters' },
              { max: 1000, message: 'This Field can not exceed 1000 characters' },
            ]}
          >
            <TextAreaField
              className="preptextArea"
              label="How to prepare for a Facility Visit *"
              placeholder="How to prepare for a Facility Visit"
              rows={3}
            />
          </Form.Item>
          <Form.Item
            name="preparationTelemedicin"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              { min: 10, message: 'This Field must be 10 characters' },
              { max: 1000, message: 'This Field can not exceed 1000 characters' },
            ]}
          >
            <TextAreaField
              className="preptextArea"
              label="How to prepare for a Telemedicine Call *"
              placeholder="How to prepare for a Telemedicine Call"
              rows={3}
            />
          </Form.Item>
          <Form.Item
            name="preparationTextMessage"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              { min: 10, message: 'This Field must be 10 characters' },
              { max: 1000, message: 'This Field can not exceed 1000 characters' },
            ]}
          >
            <TextAreaField
              className="preptextArea"
              label="How to prepare for a 100% Messaging *"
              placeholder="How to prepare for a 100% Messaging"
              rows={3}
            />
          </Form.Item>
          <div className="add-faq-action-btn-wrapper">
            <Button
              variant={Button.variant.outlined}
              onClick={() => form.setFieldsValue(preparationSteps)}
            >
              Cancel
            </Button>
            <Button variant={Button.variant.filled} htmlType="submit">Save</Button>
          </div>
        </div>
      </div>
    </Form>
  );
}
