import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NoAccess from 'shared/components/noAccess/NoAccess';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_content.scss';

function MenuItem({ isActive, title, className }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''} ${className ? 'tabs-heading-wrapper__heading' : ''}`}>
      <span>{title}</span>
    </div>
  );
}
function Content() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);
  if (!Object.values(access).find((v) => v)) {
    return (
      <NoAccess />
    );
  }
  return (
    <div className="dashBoardWrapper">
      <div className="tabs-heading-wrapper">
        <NavTabs>
          <NavTabs.Menu className="subNavMenu">
            <MenuItem title="member content" className="member content" />
            {(access?.memberFaq) && (
              <NavTabs.MenuItems isExactPath to={URLS.MEMBER_FAQ_URL}>
                <MenuItem title="Member FAQ" />
              </NavTabs.MenuItems>
            )}
            {(access?.memberTerms) && (
              <NavTabs.MenuItems isExactPath to={URLS.MEMBER_TERM_URL}>
                <MenuItem title="Member Terms & Conditions" />
              </NavTabs.MenuItems>
            )}
            {(access?.memberHowTos) && (
              <NavTabs.MenuItems isExactPath to={URLS.MEMBER_VIDEO_URL}>
                <MenuItem title="Member How To Videos" />
              </NavTabs.MenuItems>
            )}
            {(access?.memberInviteContent) && (
            <NavTabs.MenuItems to={URLS.MEMBER_INVITE_CONTENT_URL}>
              <MenuItem title="Member Invite" />
            </NavTabs.MenuItems>
            )}
            <MenuItem title="provider content" className="provider content" />
            {(access?.providerFaq) && (
              <NavTabs.MenuItems isExactPath to={URLS.PROVIDER_FAQ_URL}>
                <MenuItem title="Provider FAQ" />
              </NavTabs.MenuItems>
            )}
            {(access?.providerTerms) && (
              <NavTabs.MenuItems isExactPath to={URLS.PROVIDER_TERM_URL}>
                <MenuItem title="Provider Terms & Conditions" />
              </NavTabs.MenuItems>
            )}
            {(access?.providerHowTos) && (
              <NavTabs.MenuItems isExactPath to={URLS.PROVIDER_VIDEO_URL}>
                <MenuItem title="Provider How To Videos" />
              </NavTabs.MenuItems>
            )}
            <MenuItem title="global content" className="global content" />
            {(access?.privacyPolicy) && (
              <NavTabs.MenuItems isExactPath to={URLS.PRIVACY_POLICY_URL}>
                <MenuItem title="Privacy Policy" />
              </NavTabs.MenuItems>
            )}
            {(access?.programCancelationPolicy) && (
              <NavTabs.MenuItems to={URLS.PROGRAM_CANCELLATION_POLICY_URL}>
                <MenuItem title="Program Cancellation Policy" />
              </NavTabs.MenuItems>
            )}
            {(access?.howToPrepare) && (
              <NavTabs.MenuItems to={URLS.HOW_TO_PREPARE_URL}>
                <MenuItem title="How To Prepare" />
              </NavTabs.MenuItems>
            )}
          </NavTabs.Menu>
          <NavTabs.Content>
            <Outlet />
          </NavTabs.Content>
        </NavTabs>
      </div>
    </div>
  );
}

export default Content;
