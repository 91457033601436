/* eslint-disable no-useless-escape */
import _ from 'lodash';
import moment from 'moment';

/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
export const emptyFieldValidator = (m, v) => {
  if (v) {
    if (typeof (v) === 'string' && !v.trim()) {
      return Promise.reject(m);
    }
    if (typeof (v) === 'object' && _.isEmpty(v)) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const compareValidator = (k, a, b, v) => {
  if (a === b && !v) {
    return Promise.reject(k);
  }
  return Promise.resolve();
};

export const failSafeEmptyFieldValidator = (m, v) => {
  if (v !== null || v !== undefined) {
    if (typeof (v) === 'string' && !v.trim()) {
      return Promise.reject(m);
    }
    if (typeof (v) === 'object' && _.isEmpty(v)) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const passwordValidator = (m, v) => {
  if (!v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(v)) {
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const applyTrim = (vals = {}) => Object
  .entries(vals)
  .reduce((acc, [key, val]) => {
    acc[key] = typeof (val) === 'string' ? val.trim() : val;
    return acc;
  }, {});

export const failSafeEmptyNonZeroFieldValidator = (m, v) => {
  if (v !== null && v !== undefined && v !== '0' && v !== '' && v !== 0) {
    if (typeof (v) === 'string' && !v.trim()) {
      return Promise.reject(m);
    }
    if (typeof (v) === 'object' && _.isEmpty(v)) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const checkAgeGap = (m, v, d) => {
  if (v !== null && v !== undefined) {
    const compareTo = moment(new Date()).subtract(d, 'years');
    if (moment(v).isAfter(compareTo)) {
      return Promise.reject(m);
    }
  }
  return Promise.resolve();
};

export const emptyCompareFieldValidator = (m, v, k, c) => {
  if (c) {
    if (c === k) {
      return emptyFieldValidator(m, v);
    }
  }
  return Promise.resolve();
};

export const blackListedChars = { pattern: /^[^~^*]*$/, message: 'This field does not allow ~ ^ * ' };
export const allowOnlyNumbers = { pattern: /^[0-9]+$/, message: 'This field only allows numbers ' };
export const allowDecimalNumbers = { pattern: /^(?:\d*\.\d{1,2}|\d+)$/, message: 'This field only allows numbers' };
export const allowOnlyANSI = { pattern: /^[\x00-\x7F]*$/, message: 'This field only allows limited text and numbers ' };
export const domainNamePattern = { pattern: /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/, message: 'This field only allows domain names ' };
