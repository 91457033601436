import { createSlice } from '@reduxjs/toolkit';
import {
  getGiveawayCriteria,
  listGiveawayCriterias,
  createGiveawayCriteria,
  updateGiveawayCriteria,
} from './criteriaActions';

const INITIAL_STATE = {
  isLoading: false,
  criterias: { docs: [] },
  selectedCriteria: {},
};

export const criteriaSlice = createSlice({
  name: 'criteriaSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listGiveawayCriterias.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listGiveawayCriterias.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listGiveawayCriterias.fulfilled, (state, action) => {
      state.isLoading = false;
      state.criterias = action.payload;
    });
    builder.addCase(getGiveawayCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGiveawayCriteria.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getGiveawayCriteria.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCriteria = action.payload;
    });
    builder.addCase(createGiveawayCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createGiveawayCriteria.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createGiveawayCriteria.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateGiveawayCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateGiveawayCriteria.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateGiveawayCriteria.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = criteriaSlice.actions;
