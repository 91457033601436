import React, { useEffect, useState } from 'react';
import { ProfileImage } from 'shared/components/profileImage/ProfileImage';
import { AlertGridColumns } from 'utils/grid/columns';
import Grid from 'shared/components/grid/Grid';
import './_alert.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProviderAlerts,
  updateAlerts,
} from 'redux-store/alerts/alertsActions';

function AlertsList({ to, complaints }) {
  const dispatch = useDispatch();
  const { providerAlerts } = useSelector((state) => state.alerts);
  const INITIAL_PARAMS = {
    limit: 0, page: 1, depth: 0,
  };
  const [usersLoading, setUsersLoading] = useState(false);

  const getAllAlerts = (id) => {
    if (id) {
      setUsersLoading(true);
      let payload = {
        ...INITIAL_PARAMS,
        'where[provider][equals]': id,
        sort: '-createdAt',
        'where[or][0][alertText][equals]': 'State License Expiring',
        'where[or][1][alertText][equals]': 'HIPAA Training Expiring',
        'where[or][2][dueDate][equals]': 'Message Provider',
      };
      if (complaints) {
        payload = {
          ...INITIAL_PARAMS,
          'where[provider][equals]': id,
          sort: '-createdAt',
          'where[and][0][alertText][not_equals]': 'State License Expiring',
          'where[and][1][alertText][not_equals]': 'HIPAA Training Expiring',
          'where[and][2][dueDate][not_equals]': 'Message Provider',
        };
      }
      dispatch(
        getProviderAlerts(payload),
      ).then(() => {
        setUsersLoading(false);
      })
        .catch(() => {
          setUsersLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllAlerts(to?.id);
  }, [to]);

  const updateAlertRead = (record) => {
    if (record?.length > 0 && record[0].isRead === false) {
      dispatch(
        updateAlerts({ id: record[0]?.id, params: { isRead: true } }),
      ).then((payload) => {
        if (!payload.error) {
          getAllAlerts(to?.id);
        }
      });
    }
  };

  return (
    <div className="chatContainer">
      <div className="chat-header">
        <ProfileImage user={to} />
      </div>
      <Grid
        loading={usersLoading}
        columns={AlertGridColumns(() => {})}
        data={providerAlerts?.docs}
        // pagination={{
        //   total: providerAlerts.totalDocs,
        //   current: params.page,
        //   defaultPageSize: 10,
        //   onChange: (page, pageSize) => {
        //     setParams({ ...params, page, limit: pageSize });
        //   },
        // }}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            updateAlertRead([record]);
          },
        })}
        rowClassName={(record) => (!record?.isRead ? 'table-row-unread' : '')}
      />
    </div>
  );
}

export default AlertsList;
