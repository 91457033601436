/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

// eslint-disable-next-line no-unused-vars
import { MEMBER_INVITE_CONTENT_API_URL } from './memberInviteContentUrls';

export const listMemberInviteContent = createAsyncThunk(
  'memberInviteContentSlice/listMemberInviteContent',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(MEMBER_INVITE_CONTENT_API_URL(`versions?${query}`)).then(({ data }) => data);
  },
);

export const getMemberInviteContentLive = createAsyncThunk(
  'memberInviteContentSlice/getMemberInviteContentLive',
  async () => {
    return axios.get(MEMBER_INVITE_CONTENT_API_URL()).then(({ data }) => data);
  },
);

export const getMemberInviteContent = createAsyncThunk(
  'memberInviteContentSlice/getMemberInviteContent',
  async (id) => {
    return axios.get(MEMBER_INVITE_CONTENT_API_URL(`versions/${id}`)).then(({ data }) => data);
  },
);

export const createMemberInviteContent = createAsyncThunk(
  'memberInviteContentSlice/createMemberInviteContent',
  async (params) => {
    return axios.post(
      MEMBER_INVITE_CONTENT_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      params,
    ).then(({ data }) => {
      toast.success('Member Invite Content has been created');
      return data;
    });
  },
);

export const updateMemberInviteContent = createAsyncThunk(
  'memberInviteContentSlice/updateMemberInviteContent',
  async ({ id, params }) => {
    return axios.post(
      MEMBER_INVITE_CONTENT_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      { ...params, id },
    ).then(({ data }) => {
      toast.success('Member Invite Content has been updated');
      return data;
    });
  },
);

export const deleteMemberInviteContent = createAsyncThunk(
  'memberInviteContentSlice/deleteMemberInviteContent',
  async (id, { dispatch }) => {
    return axios.patch(MEMBER_INVITE_CONTENT_API_URL(id), {
      deletedInfoField:
      { isDeleted: true },
    })
      .then(() => {
        toast.success('Member Invite Content has been deleted');
        return dispatch(listMemberInviteContent());
      });
  },
);
