import { createSlice } from '@reduxjs/toolkit';
import {
  createPromosSetup,
  updateStatusPromosSetup,
  getPromosSetup,
  listPromosSetup,
  updatePromosSetup,
  updatePromosDeepLink,
  getMembers,
  getMembersByID,
  getDeepLinkMessages,
  getDeepLinkSettings,
  cancelPromosSetup,
} from './promosSetupActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  promosSetups: { docs: [] },
  members: [],
  membersSelected: [],
  selectedPromosSetupSlice: {},
  deepLinkMessages: { docs: [] },
  deepLinkSettings: {},
};

export const promosSetupSlice = createSlice({
  name: 'promosSetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listPromosSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listPromosSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listPromosSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.promosSetups = action.payload;
    });
    builder.addCase(getPromosSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPromosSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPromosSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedPromosSetupSlice = action.payload;
    });
    builder.addCase(getMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMembers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      const data = action.payload?.docs?.map((item) => ({ ...item, value: item.revdocId, label: `${item.revdocId} ${item.healthRecordId} ${item?.demographics?.lastName || ''} ${item?.demographics?.firstName || ''}, ${item?.mobilePhoneNumber || ''}` }));
      state.members = data;
    });
    builder.addCase(getMembersByID.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMembersByID.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMembersByID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.membersSelected = action.payload?.docs;
    });
    builder.addCase(createPromosSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPromosSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPromosSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePromosSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePromosSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePromosSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePromosDeepLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePromosDeepLink.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePromosDeepLink.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeepLinkMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDeepLinkMessages.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeepLinkMessages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deepLinkMessages = action.payload;
    });
    builder.addCase(getDeepLinkSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDeepLinkSettings.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeepLinkSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deepLinkSettings = action.payload;
    });
    builder.addCase(updateStatusPromosSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateStatusPromosSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateStatusPromosSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(cancelPromosSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelPromosSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(cancelPromosSetup.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = promosSetupSlice.actions;
