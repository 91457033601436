import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import {
  initialiseApp,
  login, logout, resetPassword, sendPasswordResetEmail, signup,
  sendSignInCode, verifyOTP,
} from './authActions';

const INITIAL_STATE = {
  isLoading: false,
  isLoggedIn: false,
  isOTPVerified: false,
  profile: {
    accessFields: {
      userManagement: false,
      categorySetup: false,
      procedureSetup: false,
      profileSetup: true,
      complaints: true,
      insurancePlans: false,
      suppliesSetup: false,
      specialistSetup: false,
      myProviders: true,
      promotionSetup: false,
      programSetup: false,
      memberHomeScreenSetup: false,
      welcomeMessageSetup: false,
      affiliationSetup: false,
      giveawaySetup: false,
      providerDefaultSetup: false,
      giveawayCriteriaSetup: false,
      programCancelationPolicy: false,
      memberInviteContent: false,
      memberHomeTopSetup: false,
    },
  },
  signInInfo: {},
  user: {},
  currentUser: null,
  resetPassword: {
    linkEmailed: false,
    passwordChanged: false,
  },
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
    clearResetPasswordData: () => INITIAL_STATE,
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
      state.isOTPVerified = action.payload;
    },
    initialiseUser: (state) => {
      state.user = JSON.parse(localStorage.getItem('user'));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initialiseApp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(initialiseApp.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(initialiseApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.profile = action.payload.profile;
      state.isLoggedIn = true;
      state.isOTPVerified = false;
    });
    builder.addCase(signup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.profile = action.payload.profile;
      state.isLoggedIn = true;
      state.isOTPVerified = false;
    });
    builder.addCase(sendPasswordResetEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendPasswordResetEmail.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendPasswordResetEmail.fulfilled, (state) => {
      state.isLoading = false;
      state.resetPassword.linkEmailed = true;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isLoading = false;
      state.resetPassword.passwordChanged = true;
    });
    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.isOTPVerified = false;
    });
    builder.addCase(sendSignInCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendSignInCode.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendSignInCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.signInInfo = action.payload;
    });
    builder.addCase(verifyOTP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOTP.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verifyOTP.fulfilled, (state) => {
      state.isLoading = false;
      state.isOTPVerified = true;
      const cookies = new Cookies();
      cookies.set('revdoc', true, {
        expire: state.signInInfo?.code_life_time,
      });
    });

    return builder;
  },
});

export const {
  clearData, setLoggedIn, initialiseUser, clearResetPasswordData,
} = authSlice.actions;
