/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { WELCOME_MESSAGE_API_URL } from './welcomeMessageUrls';

export const getWelcomeMessage = createAsyncThunk(
  'welcomeMessageSlice/getWelcomeMessage',
  async () => {
    return axios.get(WELCOME_MESSAGE_API_URL()).then(({ data }) => data);
  },
);

export const postWelcomeMessage = createAsyncThunk(
  'welcomeMessageSlice/postWelcomeMessage',
  async (params) => {
    return axios.post(
      WELCOME_MESSAGE_API_URL(),
      {
        ...params.params,
      },
    ).then(({ data }) => {
      toast.success(`${params?.id ? 'Welcome Message has been updated' : 'Welcome Message has been created'}`);
      return data;
    });
  },
);
