/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import CheckBox from '../checkbox/CheckBox';

function MultiCheckboxSelect({
  label,
  options = [],
  value = [],
  onChange,
  checkAll,
  single = false,
}) {
  const onCheck = (val, checked) => {
    let valExceptCurrent = value.filter((v) => v !== val);
    if (checked) {
      if (!single) {
        valExceptCurrent = [...valExceptCurrent, val];
      } else {
        valExceptCurrent = [val];
      }
    }
    onChange(valExceptCurrent);
  };

  useEffect(() => {
    if (checkAll) {
      onChange(options?.filter((o) => !o.unChecked).map((o) => o.value));
    }
  }, [checkAll]);
  return (
    <div className="provider-wrapper">
      <h3>{label}</h3>
      <div className="d-flex">
        <div>
          {options.map((o) => (
            <CheckBox
              label={o.label}
              checked={value?.includes(o.value) && !o.unChecked}
              onChange={(e) => onCheck(o.value, e.target.checked)}
              disabled={o?.disabled || false}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MultiCheckboxSelect;
