/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Form, Switch } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import './_addCriteria.scss';
import { isNew } from 'shared/utils/global';
import { clearData } from 'redux-store/criteria/criteriaSlice';
import InputField from 'shared/components/inputField/InputField';
import moment from 'moment';
import Button from 'shared/components/button/Button';
import { applyTrim, emptyFieldValidator } from 'shared/utils/validators';
import TextAreaField from 'shared/components/textArea/TextArea';
import {
  createGiveawayCriteria,
  getGiveawayCriteria,
  updateGiveawayCriteria,
} from 'redux-store/criteria/criteriaActions';

export default function AddNewCriteria() {
  const { id } = useParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { selectedCriteria } = useSelector((state) => state.criteria);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getGiveawayCriteria(id));
    }
    return () => dispatch(clearData());
  }, []);

  const goBack = () => {
    form.resetFields();
    navigate('/dashboard/giveaway/giveaway-criterias');
  };

  useEffect(() => {
    if (!_.isEmpty(selectedCriteria)) {
      form.setFieldsValue({
        ...selectedCriteria,
      });
      setRefresh(!refresh);
    }
  }, [selectedCriteria]);

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    vals.criteriaKey = 'customCriteria';
    vals.isCustomCriteria = true;
    if (id === 'new') {
      dispatch(createGiveawayCriteria(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateGiveawayCriteria({ id, params: vals }));
    }
  };

  return (
    <div className="giveaway-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={() => {
          toast.error('Please fill the entire form');
        }}
      >
        <div className="giveaway-container__outer giveaway-college">
          <div className="giveaway-container__wrapper">
            <h1>
              {' '}
              {isNew(id) ? 'New ' : 'Edit '}
              {' '}
              Criteria
            </h1>
            <div className="last-section">
              <Form.Item
                name="criteriaName"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <InputField
                  label="Criteria Name *"
                  placeholder="Criteria Name"
                  className="formfield"
                  maxLength={100}
                />
              </Form.Item>
            </div>

            <div className="last-section business-welcome-text">
              <Form.Item
                name="criteriaDescription"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <TextAreaField
                  label="Criteria Description *"
                  placeholder="Criteria Description"
                  rows={3}
                  maxLength={480}
                  className="formfield"
                />
              </Form.Item>
            </div>

            <div className="last-section include-qtn">
              <div className="member-label">Include a Button?</div>
              <div className="criteria-include">
                <Form.Item
                  valuePropName="checked"
                  name="isIncludeButton"
                  noStyle
                >
                  <Switch
                    checkedChildren="Yes"
                    className={`${
                      form.getFieldValue('isIncludeButton') === true
                        ? 'active'
                        : 'inactive'
                    }`}
                    unCheckedChildren="No"
                  />
                </Form.Item>
              </div>
            </div>

            {form.getFieldValue('isIncludeButton') === true && (
            <div className="last-section">
              <Form.Item
                name="textOnButton"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <InputField
                  label="Button Text *"
                  placeholder="Button Text"
                  className="formfield"
                  maxLength={40}
                />
              </Form.Item>
            </div>
            )}

          </div>

          <div className="giveaway-container__outer">
            <div className="action-btn-wrapper">
              <Button variant={Button.variant.outlined} onClick={goBack}>
                Cancel
              </Button>
              {!isNew(id) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated:</span>
                  <span className="orange">
                    {moment(selectedCriteria.updatedAt).format(
                      'MM/DD/YYYY hh:mm A',
                    )}
                  </span>
                </div>
              )}

              <div className="btn-promo-outer">
                <Button variant={Button.variant.filled} htmlType="submit">
                  {isNew(id) ? 'Save' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
