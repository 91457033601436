/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { listProgramsStaff, updateProgramProvider } from 'redux-store/programs/programActions';
import { AddProviderMemberGridColumns } from 'utils/grid/columns';
import './_programProvider.scss';
import Grid from 'shared/components/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { toast } from 'react-toastify';

const INITIAL_PARAMS = {
  limit: 10,
  page: 1,
  sort: '-updatedAt',
};
export default function ProgramProviders(props) {
  const {
    onCancelDialogPopup,
    id,
  } = props;
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deselectedRows, setDeselectedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const {
    programStaff,
  } = useSelector((state) => state.program);

  useEffect(() => {
    if (programStaff?.docs?.length > 0) {
      const staffs = JSON.parse(JSON.stringify(programStaff?.docs));
      setSelectedRows(staffs?.filter((x) => x.status === 'optedIn') ?? []);
    }
  }, [programStaff]);

  const onSelect = (row, selected) => {
    if (selected) {
      const temp = JSON.parse(JSON.stringify(selectedRows));
      const tempD = JSON.parse(JSON.stringify(deselectedRows));
      const index = temp?.findIndex((x) => x?.pid === row?.id);
      const indexD = tempD?.findIndex((x) => x?.pid === row?.id);
      if (index === -1) {
        temp.push({ ...row });
        setSelectedRows(temp);
        if (indexD !== -1) {
          tempD.splice(indexD, 1);
          setDeselectedRows(tempD);
        }
      }
    } else {
      const temp = JSON.parse(JSON.stringify(selectedRows));
      const tempD = JSON.parse(JSON.stringify(deselectedRows));
      const index = temp?.findIndex((x) => x?.pid === row?.id);
      const indexD = tempD?.findIndex((x) => x?.pid === row?.id);
      if (index !== -1) {
        if (indexD === -1) {
          tempD.push({ ...selectedRows[index] });
          setDeselectedRows(tempD);
        }
        temp.splice(index, 1);
        setSelectedRows(temp);
      }
    }
    setRefresh(!refresh);
  };

  const onAdd = () => {
    let errorDelProvider = false;
    const request = deselectedRows?.map((d) => dispatch(updateProgramProvider({ id: d?.pid, params: { status: 'optedOut' } }))
      .then((dPayload) => {
        if (!(!errorDelProvider && _.isEmpty(dPayload?.payload?.error))) {
          errorDelProvider = true;
        }
      }));
    const requestInvite = selectedRows?.filter((x) => x.status === 'optedOut')?.map((d) => dispatch(updateProgramProvider({ id: d?.pid, params: { status: 'invited' } }))
      .then((dPayload) => {
        if (!(!errorDelProvider && _.isEmpty(dPayload?.payload?.error))) {
          errorDelProvider = true;
        }
      }));
    if (requestInvite?.length > 0) {
      request.concat(requestInvite);
    }
    if (request?.length > 0 || requestInvite?.length > 0) {
      Promise.all(request);
      dispatch(listProgramsStaff({
        ...params,
        'where[and][0][program][equals]': id,
        'where[and][1][deletedInfoField.isDeleted][not_equals]': true,
      }));
      onCancelDialogPopup();
      toast.success('Staff status updated');
    }
  };

  useEffect(() => {
    dispatch(listProgramsStaff({
      ...params,
      'where[and][0][program][equals]': id,
      'where[and][1][deletedInfoField.isDeleted][not_equals]': true,
    }));
  }, [params]);

  const onSearch = (v) => {
    setParams({
      ...INITIAL_PARAMS,
      'where[and][0][program][equals]': id,
      'where[and][1][deletedInfoField.isDeleted][not_equals]': true,
      'where[and][2][or][0][provider.firstName][like]': v,
      'where[and][2][or][1][provider.lastName][like]': v,
      'where[and][2][or][2][provider.middleName][like]': v,
      'where[and][2][or][3][provider.npiNumber][like]': v,
      page: 1,
    });
  };

  return (
    <div className="program-provider-container">
      <div className="search-promo-text">
        <h2>Select Staff to invite to this program </h2>
      </div>
      <div className="search-promo-text">
        <SearchBox placeholder="Search Providers by name or NPI number" onChange={onSearch} waitTime={500} />
      </div>
      <div className="program-provider-container__body">
        <Grid
          columns={AddProviderMemberGridColumns(onSelect, selectedRows)}
          data={programStaff.docs}
          pagination={{
            total: programStaff.totalDocs,
            current: params.page,
            defaultPageSize: INITIAL_PARAMS.limit,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      <div className="btn-container">
        <Button
          onClick={onCancelDialogPopup}
        >
          Cancel
        </Button>
        <Button
          variant={Button.variant.filled}
          onClick={onAdd}
          disabled={deselectedRows.length === 0 && selectedRows?.filter((x) => x.status === 'optedOut').length === 0}
        >
          Save
        </Button>
      </div>

    </div>
  );
}
