/* eslint-disable import/prefer-default-export */
import { REVDOC_API_URL } from 'shared/config/appConfig';

export const PROGRAMS_API_URL = (query = '') => `${REVDOC_API_URL}/programs/${query}`;
export const PROGRAM_MEMEBERS_API_URL = (query = '') => `${REVDOC_API_URL}/program-members/${query}`;
export const PROGRAM_APPOINTMENTS_API_URL = (query = '') => `${REVDOC_API_URL}/program-appointments/${query}`;
export const PROGRAM_PROVIDERS_API_URL = (query = '') => `${REVDOC_API_URL}/program-providers/${query}`;
export const apiKey = 'pk.eyJ1IjoicmV2ZG9jLW1waXJ0bGUiLCJhIjoiY2xydGJkcjd3MDUwNDJrczBteWd4cWFyaCJ9.mxds_ZCGT9AuIOKQhOLXXQ';
export const bbox = '-125.000000,24.396308,-66.934570,49.384358'; // This Bounding Box is The MapBox requirement for US only addresses
export const FORWARD_GEO_CODE_URL = (query = '') => `https://api.mapbox.com/search/searchbox/v1/forward?access_token=${apiKey}&limit=10&proximity=ip&types=address,poi&bbox=${bbox}&language=en${query}`;
