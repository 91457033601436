/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './_welcomeMessage.scss'; import {
  allowOnlyANSI,
  applyTrim,
} from 'shared/utils/validators';
import {
  Form,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { toast } from 'react-toastify';
import TextAreaField from 'shared/components/textArea/TextArea';
import { getWelcomeMessage, postWelcomeMessage } from 'redux-store/welcomeMessage/welcomeMessageActions';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import CheckBox from 'shared/components/checkbox/CheckBox';

export default function WelcomeMessage() {
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { welcomeMessage } = useSelector((state) => state.welcomeMessage);

  useEffect(() => {
    dispatch(getWelcomeMessage());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(welcomeMessage)) {
      form.setFieldsValue({ ...welcomeMessage });
      setRefresh(!refresh);
    }
  }, [welcomeMessage]);

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    dispatch(postWelcomeMessage({ id: welcomeMessage?.id, params: vals }));
  };

  const goBack = () => {
    dispatch(getWelcomeMessage());
  };

  const onPreview = () => {
    setDialogOpen(true);
  };

  return (
    <div className="welcome-message-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={() => { toast.error('Please fill the entire form'); }}
      >
        <div className="welcome-message-container__outer">
          <div className="welcome-message-container__wrapper">
            <h1>
              Welcome Message
            </h1>
            <div className="section">
              <Form.Item
                name="message"
                rules={[{ required: true, message: 'This field is required' },
                  { min: 10, message: 'This field should have at least 10 characters' }, allowOnlyANSI]}
              >
                <TextAreaField
                  label="Body of Welcome Message *"
                  placeholder="Body of Welcome Message"
                  rows={3}
                  maxLength={160}
                  className="formfield"
                />
              </Form.Item>
              <div className="text-length">
                {form.getFieldValue('message')?.length || 0}
                /160
              </div>
            </div>
            <div className="section">
              <label className="check-label-promo">Non-Editable Text:</label>
              <div>
                <label className="non-editable">{welcomeMessage?.messagePostFix || ''}</label>
              </div>
            </div>
            <div className="section">
              <label className="check-label-promo">
                <div>Check this box to enable the welcome message:</div>
              </label>
              <Form.Item
                name="enabled"
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Enabled" />
              </Form.Item>
            </div>
            <div className="section no-border">
              <div className="action-btn-wrapper">
                <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
                <div className="btn-promo-outer">
                  <Button className="green-button" onClick={() => onPreview()} variant={Button.variant.filled}>Preview</Button>
                  <Button variant={Button.variant.filled} htmlType="submit">Save</Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Form>
      <DialogPopup
        className="welcome-message-preview-dialog"
        open={dialogOpen}
        title="Preview Welcome Message"
        handleOk={() => {}}
        handleCancel={() => setDialogOpen(false)}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <div className="welcome-message-preview">
          <div className="section no-border">
            <div className="message-body">
              <span>
                {`${form.getFieldValue('message')} `}
                <br />
                {welcomeMessage?.messagePostFix || ''}
              </span>
            </div>
          </div>
          <div className="section no-border">
            <div className="action-btn-wrapper">
              <div className="btn-promo-outer">
                <Button
                  onClick={() => setDialogOpen(false)}
                  variant={Button.variant.outlined}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => { form.submit(); setDialogOpen(false); }}
                  variant={Button.variant.filled}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </DialogPopup>
    </div>
  );
}
