import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import Button from 'shared/components/button/Button';
import { useSelector } from 'react-redux';
import './_preview.scss';
import { CopyIcon, TickIcon } from 'utils/grid/columns';
import moment from 'moment';
import { DELIVERY_METHODS } from 'shared/constants/global';

export default function PreviewPromo(params) {
  const {
    onCancelDialogPopup,
    onConfirmSave,
    showButtons,
    data,
    id,
    copyToClipboard,
    showCopied,
  } = params;
  const { selectedPromosSetupSlice } = useSelector((state) => state.promosSetups);
  const { deliveryMethods = { docs: [] } } = useSelector((s) => s.deliveryMethod);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    setImageSrc(null);
    if (typeof data?.promoCodeImage === 'object') {
      setImageSrc(URL.createObjectURL(data?.promoCodeImage));
    } else if (id && selectedPromosSetupSlice.promoCodeImage?.url) {
      setImageSrc(selectedPromosSetupSlice.promoCodeImage?.url);
    }
  }, [data]);

  const getDuration = () => {
    let durationType = 'N/A';
    if (data.durationInHours === true) {
      durationType = `${data?.durationIn} Hours`;
    } else if (data.durationDateRange === true) {
      durationType = `Until - ${data?.termDate ? moment(data.termDate).format('MM/DD/YYYY') : ''}`;
    } else if (data.untilAllRedemptionsFilled === true) {
      durationType = 'Until all the redemptions are filled';
    }
    return durationType;
  };

  return (
    <div className="promo-preview">
      <div className="preview-promo">
        <div className="promo-items-top">
          <div className="promo-item-title">
            <span>{data?.promoTitle || 'N/A'}</span>
          </div>
          <div className="promo-image">
            {imageSrc && (
            <Image
              src={imageSrc}
            />
            )}
          </div>
        </div>
        <div className="promo-items-desc">
          <span className="value">{data?.description || 'N/A'}</span>
        </div>
        <div className="promo-items">
          <span className="label">Promo Type</span>
          <span className="value">
            {[
              { label: 'Membership Fees', value: 'membershipFees' },
              { label: 'Procedures', value: 'procedures' },
              { label: 'At Home Labs', value: 'labs' },
              { label: 'Programs (% off only)', value: 'programs' },
              { label: 'Off Cart', value: 'offCart' },
            ]?.find((x) => x.value === data?.promoCodeType)?.label || 'N/A'}
          </span>
        </div>
        { data?.promoCodeType === 'procedures' && (
        <div className="promo-items">
          <span className="label">Procedure</span>
          <span className="value">
            {data?.procedures?.map((x) => (
              ` ${x.procedureName}`
            ))?.join(',') || 'N/A'}
          </span>
        </div>
        )}
        { data?.promoCodeType === 'labs' && (
        <div className="promo-items">
          <span className="label">At Home Labs</span>
          <span className="value">
            {data?.labs?.map((x) => (` ${x.labName}`
            ))?.join(',') || 'N/A'}
          </span>
        </div>
        )}
        { data?.promoCodeType === 'programs' && (
        <div className="promo-items">
          <span className="label">Programs</span>
          <span className="value">
            {data?.programs?.programName || 'N/A'}
          </span>
        </div>
        )}
        { data?.discountType === 'amountOff' && (
        <div className="promo-items">
          <span className="label">$ off</span>
          <span className="value">{data?.amountOffValue ? `$ ${data?.amountOffValue}` : 'N/A'}</span>
        </div>
        )}
        { data?.discountType === 'percentageOff' && (
        <div className="promo-items">
          <span className="label">% off</span>
          <span className="value">{data?.percentageOffValue ? `${data?.percentageOffValue} %` : 'N/A'}</span>
        </div>
        )}
        { data?.discountType === 'freeMonths' && (
        <div className="promo-items">
          <span className="label">Months Free</span>
          <span className="value">{data?.freeMonthsValue ? `${data?.freeMonthsValue} months` : 'N/A'}</span>
        </div>
        )}
        <div className="promo-items">
          <span className="label">Applies to Membership Level</span>
          <span className="value">
            {(data?.revBasic === false || data?.revBasic === undefined) && (data?.revPlus === false || data?.revPlus === undefined) && (data?.revPrivate === false || data?.revPrivate === undefined) && 'N/A'}
            {data?.revBasic === true && (<div><span className="value">REV1</span></div>)}
            {data?.revPlus === true && (<div><span className="value">REV+</span></div>)}
            {data?.revPrivate === true && (<div><span className="value">REVPRIVATE</span></div>)}
          </span>
        </div>
        <div className="promo-items">
          <span className="label">Promo Code</span>
          <span className="value">{data?.promoCode || 'N/A'}</span>
        </div>
        <div className="promo-items">
          <span className="label">Region</span>
          <span className="value">
            {data?.isRegionDependent === true ? data?.regions?.map((x) => (
              <div>
                {x?.city}
                ,
                {' '}
                {x?.stateCode}
              </div>
            )) : [data?.isRegionDependent === false ? 'Applies to all regions' : 'N/A']}
          </span>
        </div>
        <div className="promo-items">
          <span className="label">Delivery Methods</span>
          <span className="value">
            {data?.deliveryMethods && data?.deliveryMethods?.length > 0
              ? (deliveryMethods?.docs?.map((o) => ({ label: DELIVERY_METHODS[o.internalIdentifier], value: o.id }))?.map((x) => (data?.deliveryMethods?.includes(x.value) ? x.label : ''))?.filter((x) => x !== '')?.join(',')) : 'N/A'}
          </span>
        </div>
        <div className="promo-items">
          <span className="label">Redeemable </span>
          <span className="value">{data?.redemptionLimitType === 'specificNumber' ? `${data.maxRedemptions} Times` : data?.redemptionLimitType || 'N/A'}</span>
        </div>
        <div className="promo-items">
          <span className="label">Can it be Stacked</span>
          <span className="value">{data?.isStackable === true ? 'Yes' : [data?.isStackable === false ? 'No' : 'N/A']}</span>
        </div>
        <div className="promo-items">
          <span className="label">Duration</span>
          <span className="value">{getDuration()}</span>
        </div>
        <div className="promo-items">
          <span className="label">Generated Deep Link</span>
          { selectedPromosSetupSlice?.adDeepLink ? (
            <div className="promo-link">
              <span className="deep-link">
                {selectedPromosSetupSlice?.adDeepLink}
                <div onClick={() => copyToClipboard()}>
                  <CopyIcon />
                </div>
              </span>
              {
                    showCopied && (
                    <span className="deep-link-copy">
                      <TickIcon />
                      {' '}
                      Copied!
                    </span>
                    )
                  }
            </div>
          ) : <span className="value">N/A</span>}
        </div>
        <div className="promo-prview-link">
          { showButtons && (
          <div className="btn-container">
            <Button
              variant={Button.variant.outlined}
              onClick={onCancelDialogPopup}
            >
              Edit
            </Button>
            <Button
              variant={Button.variant.filled}
              onClick={onConfirmSave}
            >
              Save
            </Button>
          </div>
          )}
        </div>

      </div>
    </div>
  );
}
