import { createSlice } from '@reduxjs/toolkit';
import {
  createUserManagementSetup,
  deleteUserManagementSetup,
  getUserManagementSetup,
  listUserManagementSetup,
  updateUserManagementSetup,
  getUniqueStates,
} from './userManagementSetupActions';

const INITIAL_STATE = {
  isLoading: false,
  isCountiesLoading: false,
  userManagementSetups: { docs: [] },
  counties: { docs: [] },
  selectedUserManagementSetup: {},
  uniqueStates: [],
};

export const userManagementSetupSlice = createSlice({
  name: 'userManagementSetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listUserManagementSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listUserManagementSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listUserManagementSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userManagementSetups = action.payload;
    });
    builder.addCase(getUserManagementSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserManagementSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserManagementSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedUserManagementSetup = action.payload;
    });
    builder.addCase(createUserManagementSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUserManagementSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createUserManagementSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserManagementSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserManagementSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserManagementSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedUserManagementSetup = action.payload.doc;
    });
    builder.addCase(deleteUserManagementSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUserManagementSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteUserManagementSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUniqueStates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUniqueStates.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUniqueStates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.uniqueStates = action.payload.states;
    });

    return builder;
  },
});

export const {
  clearData,
} = userManagementSetupSlice.actions;
