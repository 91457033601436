/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { Upload } from 'antd';
import React from 'react';
// import { toast } from 'react-toastify';
import Button from '../button/Button';
import './Upload.scss';

function UploadButton({
  label,
  placeholder,
  onChange,
  // maxSize = 10,
  maxCount = 1,
  multiple = false,
  ...props
}) {
  const onSelect = (file) => {
    // if (maxSize && !(file.size / 1024 / 1024 < maxSize)) {
    //   toast.error(`File must smaller than ${maxSize}MB!`);
    //   return false;
    // }
    onChange(file);
    return false;
  };

  return (
    <div className="uploadButton">
      <Upload
        multiple={multiple}
        maxCount={maxCount}
        fileList={!props.value || typeof (props.value) === 'string' ? [] : [props.value]}
        {...props}
        beforeUpload={onSelect}
        onRemove={() => onChange()}
      >
        <label htmlFor="image">
          {label}
        </label>
        <Button variant={Button.variant.filled} htmlType="button">
          {placeholder}
        </Button>
      </Upload>
    </div>
  );
}

export default UploadButton;
