/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Form, Switch } from 'antd';
import DropDown from 'shared/components/dropdown/DropDown';
import { TrashIcon } from 'utils/grid/columns';
import InputField from 'shared/components/inputField/InputField';
import { emptyFieldValidator } from 'shared/utils/validators';
import TextAreaField from 'shared/components/textArea/TextArea';

export function GiveawayCriteriaItem(props) {
  const {
    remove, item, form, index, onChangeRequired, type, onChangeOrder, disabled,
  } = props;

  const referrals = new Array(10)
    .fill(null)
    .map((_a, i) => ({ value: i + 1, label: i + 1 }));

  return (
    <div className="criteria-items">
      <div className="criteria-items-outer">
        <div className="col-1">
          <div className="lbl-crieria">
            <label>Criteria Label *</label>
          </div>
          <Form.Item
            name={[item.name, 'criteriaLabel']}
            className="formfield"
            rules={[
              {
                validator: emptyFieldValidator,
                message: 'This field is required',
              },
            ]}
          >
            <InputField
              placeholder="Criteria Name"
              className="formfield"
              maxLength={100}
            />
          </Form.Item>
        </div>
        <div>
          <div>
            <label>Required</label>
          </div>
          <Form.Item
            valuePropName="checked"
            name={[item.name, 'isRequired']}
            noStyle
          >
            <Switch
              checkedChildren="Yes"
              className={`${
                form?.getFieldValue([type, item.name, 'isRequired']) === true
                  ? 'active'
                  : 'inactive'
              }`}
              unCheckedChildren="No"
              onChange={() => onChangeRequired(item.name, type)}
            />

          </Form.Item>
        </div>
        <div className="col-2">
          <Form.Item
            name={[
              item.name,
              'chancesToWin',
            ]}
            className="formfield"
            rules={[
              {
                validator: emptyFieldValidator,
                message: 'This field is required',
              },
            ]}
          >
            <DropDown
              label="Chances to Win *"
              placeholder="Chances to Win"
              className="biomarker-dropdown"
              isOptionsObj
              options={referrals}
            />
          </Form.Item>
        </div>
        <div className="col-2">
          <Form.Item
            name={[
              item.name,
              'criteriaSequence',
            ]}
            noStyle
          />
          <Form.Item
            name={[
              item.name,
              'order',
            ]}
            className="formfield"
            rules={[
              {
                validator: emptyFieldValidator,
                message: 'This field is required',
              },
            ]}
          >
            <DropDown
              label="Sequence *"
              placeholder="Sequence"
              className="biomarker-dropdown"
              isOptionsObj
              options={form?.getFieldValue(type)?.length > 0
                ? new Array(form?.getFieldValue(type)?.length)
                  .fill(null)
                  .map((_a, i) => ({ value: i + 1, label: i + 1 })) : []}
              onChange={(e) => onChangeOrder(item.name, type, e)}
            />
          </Form.Item>
        </div>

        <div className={disabled ? 'col-3 del-no-drop' : 'col-3'} onClick={() => ((!disabled) ? remove(index) : {})}>
          <TrashIcon />
        </div>

      </div>
      <div className="criteria-items-outer criteria-items-outer-row">
        <div className="col-1">
          <div className="lbl-crieria">
            <label>Criteria Description *</label>
          </div>
          <Form.Item
            name={[item.name, 'criteriaDescription']}
            className="formfield"
            rules={[
              {
                validator: emptyFieldValidator,
                message: 'This field is required',
              },
            ]}
          >
            <TextAreaField
              placeholder="Criteria Description"
              rows={3}
              maxLength={480}
              className="formfield"
            />
          </Form.Item>
        </div>
        <div className="col2r-2">
          <div>
            <label>Include a Button?</label>
          </div>
          <Form.Item
            valuePropName="checked"
            name={[item.name, 'isIncludeButton']}
            noStyle
          >
            <Switch
              checkedChildren="Yes"
              className={`${
                form?.getFieldValue([type, item.name, 'isIncludeButton']) === true
                  ? 'active'
                  : 'inactive'
              }`}
              unCheckedChildren="No"
            />
          </Form.Item>
        </div>

        <div className="col-2">
          {form?.getFieldValue([type, item.name, 'isIncludeButton']) === true && (
          <>
            <Form.Item
              name={[
                item.name,
                'textOnButton',
              ]}
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
              ]}
            >
              <InputField
                label="Button Text *"
                placeholder="Button Text"
                maxLength={40}
              />
            </Form.Item>
            <span className="text-count-info">
              {form.getFieldValue([
                type,
                item.name,
                'textOnButton',
              ])?.length || 0}
              /40
            </span>
          </>
          )}
        </div>
      </div>
    </div>
  );
}
