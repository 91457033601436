/* eslint-disable radix */
export function convertMiliseconds(miliseconds, format) {
  if (miliseconds === 0) {
    return 0;
  }
  const totalSeconds = parseInt(Math.floor(miliseconds / 1000));
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));
  const days = parseInt(Math.floor(totalHours / 24));

  const seconds = parseInt(totalSeconds % 60);
  const minutes = parseInt(totalMinutes % 60);
  const hours = parseInt(totalHours % 24);

  switch (format) {
    case 's':
      return totalSeconds;
    case 'm':
      return totalMinutes;
    case 'h':
      return totalHours;
    case 'd':
      return days;
    case 't': {
      let t = days !== 0 ? `${days} days ` : '';
      t = hours !== 0 ? `${t}${hours} hours ` : '';
      t = minutes !== 0 ? `${t}${minutes} minutes ` : '';
      t = seconds !== 0 ? `${t}${seconds} seconds ` : '';
      return t; }
    default:
      return {
        d: days, h: hours, m: minutes, s: seconds,
      };
  }
}

export function getStartOfDayStringFromDateString(dateString) {
  const [year, month, day] = dateString.split('-').map((v) => parseInt(v));

  const date = new Date();

  date.setFullYear(year);
  date.setMonth(month - 1);
  date.setDate(day);
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
}

export function getStartOfNextDayFromDateString(dateString, hours = 0) {
  const [year, month, day] = dateString.split('-').map((v) => parseInt(v));

  const date = new Date();

  date.setFullYear(year);
  date.setMonth(month - 1);
  date.setDate(day + 1);
  date.setHours(hours, 0, 0, 0);
  return date.toISOString();
}
