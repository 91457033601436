/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {
  Form, Switch, Popconfirm,
  Space,
} from 'antd';
import _ from 'lodash';
import {
  GreyTrashIcon,
} from 'utils/grid/columns';
import TextAreaField from 'shared/components/textArea/TextArea';
import DeliveryMethods from 'shared/components/deliveryMethods/DeliveryMethods';
import {
  allowDecimalNumbers,
  allowOnlyANSI, emptyFieldValidator,
  failSafeEmptyFieldValidator,
} from 'shared/utils/validators';
import MultiCheckboxSelect from 'shared/components/multiCheckboxSelect/MultiCheckboxSelect';
import CheckBox from 'shared/components/checkbox/CheckBox';
import InputField from 'shared/components/inputField/InputField';
import DropDown from 'shared/components/dropdown/DropDown';
import LinkedProcedure from '../procedureManagement/components/addMedicationSets/LinkedProcedure';

export const Appointments = function (props) {
  const {
    index, remove, form, onChangeValue, appointment, removeLinked, disabled,
  } = props;

  const days = new Array(31).fill(null).map((_a, i) => ({ value: i + 1, label: i + 1 }));

  const getDisabledDeliveyMethods = (name) => {
    let disabledDeliveryMethods = ['text-messaging'];
    if (form.getFieldValue('isOnlineOnly') === true) {
      disabledDeliveryMethods = ['facility', 'van', 'house-call', 'telemedicine'];
    } else if (form.getFieldValue(['appointments', name, 'appointmentTypeInfo']) === true) {
      disabledDeliveryMethods = ['text-messaging'];
    }
    return disabledDeliveryMethods;
  };

  const getDisabledOnOneTime = (i) => {
    let disabledOneTime = false;
    // if oneTimePayment and appointment has atleast one informational then
    // disable from index > 1 else index > 0
    if (form.getFieldValue('isOneTimePayment') === true) {
      const infoOnlyExist = form.getFieldValue('appointments')?.filter((a) => a?.appointmentTypeInfo === true)?.length > 0;
      if ((infoOnlyExist && i > 1) || (!infoOnlyExist && i > 0)) {
        disabledOneTime = true;
      }
    }
    return disabled || disabledOneTime;
  };

  const getFilter = (name) => {
    const filter = {
      'where[isRevPlusSpecials][not_equals]': true,
      'where[isRevTogether][not_equals]': true,
    };
    const deliveryMethod = form.getFieldValue(['appointments', name, 'deliveryMethod']);

    /* eslint-disable prefer-destructuring */
    if (deliveryMethod?.length > 0) {
      filter['where[deliveryMethods][in][0]'] = deliveryMethod[0];
    }
    if (form.getFieldValue(['appointments', name, 'appointmentTypeInfo']) === true) {
      filter['where[isGeneralProcedure][equals]'] = true;
    }
    return { ...filter };
  };

  const getCopyIndex = () => {
    let cindex = 0;
    if (form.getFieldValue('isOneTimePayment') === true) {
      const infoOnlyExist = form.getFieldValue('appointments')?.filter((a) => a?.appointmentTypeInfo === true)?.length > 0;
      if (infoOnlyExist) {
        cindex = 1;
      }
    }
    return cindex;
  };

  const procedureValidation = (m, v, i) => {
    const copyIndex = getCopyIndex();
    if (form.getFieldValue('programStructureType') === 'structured' && form.getFieldValue('isOneTimePayment') === true && form.getFieldValue('appointments')?.length > copyIndex) {
      const copyOverProcedure = form.getFieldValue(['appointments', copyIndex, 'procedures']);
      const appointmentTypeInfo = form.getFieldValue(['appointments', i, 'appointmentTypeInfo']);
      if (v?.length < copyOverProcedure?.length && appointmentTypeInfo !== true) {
        return Promise.reject(m);
      }
    }
    return Promise.resolve();
  };

  return (
    <div className="program-container__outer appointment-section">
      <div className="program-container__wrapper">
        <div className="appointment-heading">
          <div className="name-appointment">
            <Form.Item
              name={[appointment.name, 'appointmentName']}
              rules={[{ validator: emptyFieldValidator, message: 'This field is required' },
                { min: 2, message: 'This field should have at least 2 characters' },
                { max: 100, message: 'This field can not exceed 100 characters' }]}
            >
              <InputField
                label="Appointment Name *"
                placeholder="Appointment Name"
                className="formfield"
                maxLength={100}
                minLength={2}
              />
            </Form.Item>
          </div>
          {!disabled && (
          <div className="del-appointment">
            <Space size="middle">
              <Popconfirm
                title="Are you sure?"
                description="Do you want to delete this Appointment?"
                okText="Yes"
                cancelText="No"
                onConfirm={remove}
              >
                <a>
                  <GreyTrashIcon />
                </a>
              </Popconfirm>
            </Space>
          </div>
          )}
        </div>
        <div className="last-section apmt-desc">
          <Form.Item
            name={[appointment.name, 'appointmentDescription']}
            rules={[{ required: true, message: 'This field is required' }, allowOnlyANSI]}
          >
            <TextAreaField
              label="Appointment Description *"
              placeholder="Appointment Description"
              rows={3}
              maxLength={140}
              className="formfield"
            />
          </Form.Item>
          <div className="text-length">
            {form.getFieldValue(['appointments', appointment.name, 'appointmentDescription'])?.length || 0}
            /140
          </div>
        </div>
        <div className="last-section apmt-border">
          <div className="apmt-type-item">
            <Form.Item
              name={[appointment.name, 'appointmentTypeInfo']}
              className="formfield radio-promo"
              valuePropName="checked"
            >
              <CheckBox
                className="procedure-radio"
                label=""
                disabled={disabled || (form.getFieldValue(['appointments', appointment.name, 'appointmentTypeAssessment']) === true || index > 0)}
                onChange={(e) => onChangeValue(`appointments.${appointment.name}.appointmentTypeInfo`, e?.target?.checked)}
              />
            </Form.Item>
            <div>
              <div className="apmt-item-text">Informational Appointment</div>
              <div className="apmt-type-info">FREE</div>
              <div className="apmt-type-info">
                Offer interested patients a free telemedicine appointment
                for this program.
              </div>
            </div>
          </div>
          <div className="apmt-type-item">
            <Form.Item
              name={[appointment.name, 'appointmentTypeAssessment']}
              className="formfield radio-promo"
              valuePropName="checked"
            >
              <CheckBox
                className="procedure-radio"
                label=""
                disabled={disabled || form.getFieldValue(['appointments', appointment.name, 'appointmentTypeInfo']) === true}
                onChange={(e) => onChangeValue(`appointments.${appointment.name}.appointmentTypeAssessment`, e?.target?.checked)}
              />
            </Form.Item>
            <div>
              <div className="apmt-item-text">Assessment Appointment</div>
              <div className="apmt-type-info">
                Select if this appointment is to assess whether a
                patient is the right fit for this program..
              </div>
            </div>
          </div>

          { form.getFieldValue(['appointments', index, 'appointmentTypeAssessment']) === true && (
          <div className="amount-member-text assessment-text">
            <div className="lbl-name">
              <label>Assessment Fee $</label>
            </div>
            <Form.Item
              name={[appointment.name, 'assessmentFee']}
              rules={[
                {
                  validator: failSafeEmptyFieldValidator,
                  message: 'This field is required',
                },
                allowDecimalNumbers,
              ]}
            >
              <InputField
                suffix="$"
                type="number"
                className="formfield"
                placeholder="00.00"
                disabled={disabled}
              />
            </Form.Item>
          </div>
          )}
        </div>
        <div className="last-section">
          <div className="apmt-sub-section">
            <div className="heading">Delivery Method</div>
            <div className="desc">Select the delivery method patients can choose to complete this appointment. </div>
          </div>
          <Form.Item
            name={[appointment.name, 'deliveryMethod']}
            className="formfield"
            rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
          >
            <DeliveryMethods disabled={disabled} label=" " disabledItems={getDisabledDeliveyMethods(appointment.name)} single onChange={(v) => { onChangeValue(`appointments.${appointment.name}.deliveryMethod`, v); }} />
          </Form.Item>
        </div>

        <div className="last-section" style={{ display: 'none' }}>
          <div className="apmt-sub-section">
            <div className="heading">At-Home Labs available in this program:</div>
            <div className="desc">Select which at-home labs will be a part of this program.</div>
          </div>
          {form.getFieldValue(['appointments', appointment.name, 'labs'])?.length > 0 && (

          <div className="card">
            {form.getFieldValue(['appointments', appointment.name, 'labs'])?.map((lab, pIndex) => (
              <div className="card-item" key={`aplab${pIndex}`}>
                <div className="left">
                  <h5>{`${_.get(lab, 'labName', '')}`}</h5>
                </div>
                {!disabled && (
                <div className="del-appointment">
                  <Space size="middle">
                    <Popconfirm
                      title="Are you sure?"
                      description="Do you want to delete this At-Home Lab?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => removeLinked(['appointments', appointment.name, 'labs'], pIndex)}
                    >
                      <a>
                        <GreyTrashIcon />
                      </a>
                    </Popconfirm>
                  </Space>
                </div>
                )}
              </div>
            ))}
          </div>
          )}
          <Form.Item
            name={[appointment.name, 'labs']}
            className="formfield"
          >
            <LinkedProcedure
              showGrid={false}
              value={form.getFieldValue(`appointments.${appointment.name}.labs`) || []}
              onChange={(v) => {
                onChangeValue(`appointments.${appointment.name}.labs`, v);
              }}
              multiple
              label=" "
              tag="Lab"
              buttonText="Add At-Home Lab"
              key={`appointments.${appointment.name}.labs`}
              showIcon
              showLabel={false}
              disabled={disabled}
            />
          </Form.Item>
        </div>

        {form.getFieldValue(['appointments', appointment.name, 'deliveryMethod'])?.length > 0 && (
        <div className={index > 0 ? 'last-section apmt-border apmt-top-border' : 'last-section apmt-top-border'}>
          <div className="apmt-sub-section">
            <div className="heading">Procedures available in this appointment:</div>
            <div className="desc">Select which procedures will be a part of this appointment.</div>
          </div>
          {
form.getFieldValue(['appointments', appointment.name, 'procedures'])?.length > 0 && (
<div className="card">
  {form.getFieldValue(['appointments', appointment.name, 'procedures'])?.map((provider, pIndex) => (
    <div className="card-item" key={`apPro${pIndex}`}>
      <div className="left">
        <h5>{`${_.get(provider, 'procedureName', '')}`}</h5>
      </div>
      {(!getDisabledOnOneTime(appointment.name)) && (
      <div className="del-appointment">
        <Space size="middle">
          <Popconfirm
            title="Are you sure?"
            description="Do you want to delete this procedure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => removeLinked(['appointments', appointment.name, 'procedures'], pIndex)}
          >
            <a>
              <GreyTrashIcon />
            </a>
          </Popconfirm>
        </Space>
      </div>
      )}
    </div>
  ))}
</div>
)
          }
          <Form.Item
            name={[appointment.name, 'procedures']}
            className="formfield"
            rules={[{ validator: emptyFieldValidator, message: 'This field is required' },
              { validator: (m, v) => procedureValidation(m, v, appointment.name), message: 'Procedures should be same for all appointments. Please check the applicable delivery method.' },
            ]}
          >
            <LinkedProcedure
              showGrid={false}
              filter={getFilter(appointment.name)}
              value={(form.getFieldValue(`appointments.${appointment.name}.procedures`) && JSON.parse(JSON.stringify(form.getFieldValue(`appointments.${appointment.name}.procedures`)))) || []}
              onChange={(v) => {
                const data = form.getFieldValue('isOnlineOnly') === true ? [{ ...v }] : v;
                onChangeValue(['appointments', `${appointment.name}`, 'procedures'], data);
              }}
              multiple={form.getFieldValue('isOnlineOnly') !== true}
              label=" "
              tag="Procedure"
              buttonText="Select Procedure"
              key={`appointments.${appointment.name}.procedures`}
              showIcon
              showLabel={false}
              disabled={getDisabledOnOneTime(appointment.name)}
            />
          </Form.Item>
        </div>
        )}

        {index > 0 && (
        <div className="last-section">
          <div className="appointment-link-section">
            <div className="apmt-linked-qtn">
              <div className="member-label">Is this appointment dependent on other appointments within this program?</div>
              <div>
                <Form.Item valuePropName="checked" name={[appointment.name, 'isDependentOnOtherAppointments']} noStyle>
                  <Switch disabled={disabled} checkedChildren="Yes" className={`${form.getFieldValue(['appointments', appointment.name, 'isDependentOnOtherAppointments']) === true ? 'active' : 'inactive'}`} unCheckedChildren="No" />
                </Form.Item>
              </div>
            </div>
            {
                form.getFieldValue(['appointments', appointment.name, 'isDependentOnOtherAppointments']) === true
                && (
                <div className="apmt-linked-provider">
                  <div className="info-label">Check any appointments that must be completed before booking this appointment: </div>
                  <div className="last-section green-check">
                    <Form.Item
                      name={[appointment.name, 'prerequisiteAppointments']}
                      style={{ display: 'flex' }}
                      valuePropName="checked"
                      rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                    >
                      <MultiCheckboxSelect
                        label=" "
                        options={[{ label: `Appointment ${index}`, value: index, disabled }]}
                        value={form.getFieldValue(['appointments', appointment.name, 'prerequisiteAppointments'])?.filter((x) => x !== -1) || []}
                        onChange={(val) => onChangeValue(`appointments.${appointment.name}.prerequisiteAppointments`, val)}
                      />
                    </Form.Item>
                  </div>
                </div>
                )
            }
          </div>
        </div>
        )}

        <div className="apmt-linked-provider">
          <div>
            <label>Days to book next appointment</label>
          </div>
          <div className="section pgm-member drp-month">
            <div className="amount-text">
              <Form.Item
                name={[appointment.name, 'minDaysBetweenAppointments']}
              >
                <DropDown
                  label=""
                  placeholder=""
                  className="biomarker-dropdown"
                  isOptionsObj
                  options={days}
                  disabled={disabled}
                />
              </Form.Item>
              <span className="member-label">days</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export function BlueTick() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5757_83465)">
        <path d="M15.5564 7.97343C15.0811 7.48943 14.9751 6.7541 15.2944 6.15543C15.5224 5.72876 15.5384 5.22143 15.3371 4.78076C15.1364 4.34076 14.7417 4.01943 14.2697 3.91276C13.6091 3.76276 13.1217 3.20143 13.0677 2.52543C13.0284 2.04343 12.7671 1.60676 12.3597 1.34543C11.9537 1.08343 11.4484 1.02676 10.9931 1.19276C10.3557 1.4241 9.64239 1.2141 9.23172 0.67543C8.93839 0.29143 8.48239 0.0654297 7.99839 0.0654297C7.51505 0.0654297 7.05905 0.29143 6.76572 0.67543C6.35705 1.21476 5.64372 1.42476 5.00639 1.19343C4.55172 1.0281 4.04639 1.08476 3.63972 1.3461C3.23305 1.60743 2.97105 2.0441 2.93239 2.5261C2.87772 3.2021 2.39105 3.7641 1.73039 3.91343C1.25839 4.0201 0.86372 4.34143 0.663054 4.78143C0.462387 5.22143 0.478387 5.72943 0.706387 6.15543C1.02572 6.75343 0.91972 7.48943 0.445054 7.97343C0.10572 8.3181 -0.0529463 8.80143 0.0157203 9.2801C0.084387 9.75876 0.373054 10.1781 0.79572 10.4134C1.38772 10.7434 1.69705 11.4201 1.55839 12.0834C1.46039 12.5568 1.58772 13.0488 1.90439 13.4141C2.22172 13.7801 2.69105 13.9768 3.17239 13.9461C3.84972 13.9041 4.47439 14.3061 4.71705 14.9388C4.88972 15.3908 5.26372 15.7354 5.72839 15.8714C6.19172 16.0074 6.69305 15.9194 7.08239 15.6334C7.62839 15.2314 8.37305 15.2314 8.91839 15.6334C9.30772 15.9201 9.80905 16.0081 10.2724 15.8714C10.7371 15.7354 11.1111 15.3901 11.2844 14.9388C11.5264 14.3061 12.1517 13.9034 12.8284 13.9461C13.3111 13.9768 13.7797 13.7801 14.0964 13.4141C14.4131 13.0488 14.5411 12.5568 14.4424 12.0834C14.3044 11.4201 14.6131 10.7434 15.2057 10.4134C15.6277 10.1774 15.9157 9.75876 15.9851 9.2801C16.0544 8.80143 15.8951 8.3181 15.5564 7.97343ZM7.16705 11.5934L4.16705 8.6841L5.40505 7.4461L7.16705 9.11676L10.9291 5.26076L12.1671 6.49876L7.16705 11.5934Z" fill="#17A1FA" />
      </g>
      <defs>
        <clipPath id="clip0_5757_83465">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
