import axios from 'axios';
import { print } from 'graphql/language/printer';
import { REVDOC_API_URL } from 'shared/config/appConfig';

export const GRAPHQL_API_URL = () => `${REVDOC_API_URL}/graphql`;

export const supertokensClient = axios.create();
supertokensClient.interceptors.request.use((config) => config);

export async function fetchGql(query) {
  const passQuery = typeof query.query === 'string' ? query.query : print(query.query);

  return (
    await supertokensClient.post(GRAPHQL_API_URL(), {
      ...query,
      query: passQuery,
    })
  ).data;
}
