/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import './_addNewMedicationSet.scss';
import Grid from 'shared/components/grid/Grid';
import {
  AddNewLabsGridColumns,
  AddNewProcedureGridColumns,
  AddNewProgramGridColumns,
  AddCategoryGridColumns,
  AddProviderGridColumns,
  CriteriaGridColumns,
} from 'utils/grid/columns';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { listProceduresSetup } from 'redux-store/proceduresSetup/proceduresSetupActions';
import { listProgram } from 'redux-store/programs/programActions';
import { listAtHomeLab } from 'redux-store/atHomeLabs/atHomeLabsActions';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { getTermedFiltersOnField } from 'shared/utils/filters';
import { listCategorySetup } from 'redux-store/categorySetup/categorySetupActions';
import { listAllProviders } from 'redux-store/myProviders/myProvidersActions';
import _ from 'lodash';
import { listGiveawayCriterias } from 'redux-store/criteria/criteriaActions';

const INITIAL_PARAMS = {
  limit: 10,
  page: 1,
  depth: 0,
  sort: '-updatedAt',
};
export default function AddNewProcedureList({
  value, onChange, multiple = false, tag = 'Procedure', filter = null,
}) {
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const { proceduresSetups } = useSelector((state) => state.proceduresSetup);
  const { programs } = useSelector((state) => state.program);
  const { providerTypes } = useSelector((state) => state.providerType);
  const { atHomeLabs } = useSelector((state) => state.atHomeLabs);
  const { categorySetups } = useSelector((state) => state.categorySetup);
  const { allProviders } = useSelector((state) => state.myProviders);
  const { criterias } = useSelector((state) => state.criteria);

  useEffect(() => {
    if (value && !multiple) {
      if (value?.length === 1) {
        setSelectedRows([{ ...value[0] }]);
      } else if (value?.length === 0) {
        setSelectedRows(value);
      } else {
        setSelectedRows([value]);
      }
    } else if (value?.length > 0 && multiple) {
      setSelectedRows(value ?? []);
    }
  }, [value]);

  const onSelect = (row, selected) => {
    if (selected) {
      if (!multiple) {
        setSelectedRows([row]);
      } else {
        const temp = JSON.parse(JSON.stringify(selectedRows));
        temp.push(row);
        setSelectedRows(temp);
      }
    } else if (!multiple) {
      setSelectedRows([]);
    } else {
      const temp = JSON.parse(JSON.stringify(selectedRows));
      const index = temp?.findIndex((x) => x?.id === row?.id);
      if (index !== -1) {
        temp.splice(index, 1);
        setSelectedRows(temp);
      }
    }
    setRefresh(!refresh);
  };

  const onAdd = () => {
    if (!multiple) {
      if (!_.isEmpty(selectedRows[0])) {
        onChange(selectedRows);
      }
    } else {
      onChange(selectedRows);
    }
  };

  useEffect(() => {
    if (tag === 'Lab') {
      dispatch(listAtHomeLab({ ...params, ...getTermedFiltersOnField('effectiveDate', 'termDate'), ...filter }));
    } else if (tag === 'Program') {
      dispatch(listProgram({ ...params, ...filter, 'where[status][equals]': 'live' }));
    } else if (tag === 'Category') {
      dispatch(listCategorySetup({ ...params, ...getTermedFiltersOnField('procedureCategoryEffectiveDate', 'procedureCategoryTermDate'), ...filter }));
    } else if (tag === 'Provider') {
      dispatch(listAllProviders({
        ...params,
        ...filter,
        depth: 0,
      }));
    } else if (tag === 'Criteria') {
      dispatch(listGiveawayCriterias({ ...params, ...filter }));
    } else {
      dispatch(listProceduresSetup({
        ...params,
        depth: 1,
        ...getTermedFiltersOnField('procedureEffectiveDate', 'procedureTermDate'),
        ...filter,
      }));
    }
  }, [params]);

  const onSearch = (v) => {
    let obj = {
      [`where[${tag.toLowerCase()}Name][like]`]: v,
    };
    if (tag === 'Category') {
      obj = {
        'where[procedureCategoryName][like]': v,
      };
    } else if (tag === 'Provider') {
      obj = {
        'where[or][0][firstName][like]': v,
        'where[or][1][lastName][like]': v,
        'where[or][2][middleName][like]': v,
        'where[or][3][npiNumber][like]': v,
      };
    }
    setParams({
      ...INITIAL_PARAMS,
      ...filter,
      page: 1,
      ...obj,
    });
  };

  return (
    <div className="added-procedure-container">
      <div className="search-promo-text">
        <SearchBox placeholder={tag === 'Provider' ? 'Search Providers by name or NPI number' : `Search ${tag} by name `} onChange={onSearch} waitTime={1300} />
      </div>
      <div className="added-procedure-container__body">
        <Grid
          columns={tag === 'Criteria' ? CriteriaGridColumns(onSelect, selectedRows) : tag === 'Provider' ? AddProviderGridColumns(onSelect, selectedRows, providerTypes) : tag === 'Category' ? AddCategoryGridColumns(onSelect, selectedRows) : tag === 'Lab' ? AddNewLabsGridColumns(onSelect, selectedRows) : tag === 'Program' ? AddNewProgramGridColumns(onSelect, selectedRows) : AddNewProcedureGridColumns(onSelect, selectedRows)}
          data={tag === 'Criteria' ? criterias.docs : tag === 'Provider' ? allProviders.docs : tag === 'Category' ? categorySetups.docs : tag === 'Lab' ? atHomeLabs.docs : tag === 'Program' ? programs.docs : proceduresSetups.docs}
          pagination={{
            total: tag === 'Criteria' ? criterias.totalDocs : tag === 'Provider' ? allProviders.totalDocs : tag === 'Category' ? categorySetups.totalDocs : tag === 'Lab' ? atHomeLabs.totalDocs : tag === 'Program' ? programs.totalDocs : proceduresSetups.totalDocs,
            current: params.page,
            defaultPageSize: INITIAL_PARAMS.limit,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      <Button
        variant={Button.variant.filled}
        onClick={onAdd}
        disabled={!selectedRows?.length}
      >
        Add
        {' '}
        {tag}
      </Button>
    </div>
  );
}
