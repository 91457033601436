import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { LABELS } from 'shared/constants/fieldLabelMappings';
import Session from 'supertokens-auth-react/recipe/session';

const axiosIns = axios.create({
  timeout: 5 * 60000,
});
axiosIns.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 403 && !error?.config?.retried) {
    return Session.attemptRefreshingSession()
      .then(() => axiosIns.request({ ...error.config, retried: true }))
      .catch(() => {
        window.location.href = '/';
      });
  }
  if (_.get(error, 'response.data.errors')) {
    _.get(error, 'response.data.errors', []).forEach((er) => {
      if (_.get(er, 'message', '')?.includes('horizontalScrolls') && _.get(er, 'name', '') === 'ValidationError') {
        toast.error('Validation failed for few Items');
      } else if (_.get(er, 'data[0].field', '')) {
        toast.error(`${LABELS[_.get(er, 'data[0].field', '')] || _.get(er, 'data[0].field', '').toLowerCase()} ${_.get(er, 'data[0].message', '').toLowerCase()}`);
      } else {
        toast.error(er.message);
      }
    });
  } else {
    toast.error(error.message);
  }
  throw error;
});

export default axiosIns;
