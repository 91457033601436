/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import {
  PROVIDER_WELCOME_MATCH_API_URL,
} from './providerStateUrls';

export const listProviderState = createAsyncThunk(
  'providerStateSlice/listProviderState',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROVIDER_WELCOME_MATCH_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getProviderState = createAsyncThunk(
  'providerStateSlice/getProviderState',
  async (id) => {
    return axios.get(PROVIDER_WELCOME_MATCH_API_URL(id)).then(({ data }) => data);
  },
);

export const createProviderState = createAsyncThunk(
  'providerStateSlice/createProviderState',
  async (params) => {
    return axios.post(
      PROVIDER_WELCOME_MATCH_API_URL(),
      params,
    ).then(({ data }) => {
      toast.success('Provider State Configuration created successfully');
      return data;
    });
  },
);

export const updateProviderState = createAsyncThunk(
  'providerStateSlice/updateProviderState',
  async ({ id, params }) => {
    return axios.patch(
      PROVIDER_WELCOME_MATCH_API_URL(id),
      params,
    ).then(() => {
      toast.success('Provider State Configuration updated');
    });
  },
);
