import { createSlice } from '@reduxjs/toolkit';
import {
  getAffiliation,
  listAffiliations,
  createAffiliation,
  updateAffiliation,
} from './affiliationActions';

const INITIAL_STATE = {
  isLoading: false,
  affiliations: { docs: [] },
  selectedAffiliation: {},
};

export const affiliationSlice = createSlice({
  name: 'affiliationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listAffiliations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listAffiliations.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listAffiliations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliations = action.payload;
    });
    builder.addCase(getAffiliation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAffiliation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAffiliation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedAffiliation = action.payload;
    });
    builder.addCase(createAffiliation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAffiliation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createAffiliation.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAffiliation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAffiliation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAffiliation.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = affiliationSlice.actions;
