import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import chatTick from '../../../../assets/imgs/chatTick.png';

export default function MedicalLicense() {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);

  return (
    <div className="provider-pop-up-container__medical">
      {Object.entries(_.get(selectedMyProviders, 'licenses', {})).filter(([, v]) => !_.isEmpty(v) && (v.licenseNumber !== null
      && v.licenseExpirationDate !== null && v.licenseImage !== null
      && v.licenseNumber.licenseType !== null)).map(([k, l]) => (
        <div>
          <div>
            <Button
              variant={Button.variant.filled}
              onClick={() => window.open(l?.licenseImage?.url, '_blank')}
              style={{
                width: '100%',
                marginBottom: 16,
              }}
            >
              <FontAwesomeIcon icon={faLink} className="checkIcon" />
              &nbsp;
              Open File
            </Button>
          </div>
          <div className="d-flex">
            <span className="key">License Number</span>
            <span className="value">{l?.licenseNumber}</span>
          </div>
          <div className="d-flex">
            <span className="key">State</span>
            <span className="value">{k}</span>
          </div>
          <div className="d-flex">
            <span className="key">Expiration Date</span>
            <span className="value">{moment(l?.licenseExpirationDate?.replace('Z', '')).format('MM/DD/YYYY, hh:mm A')}</span>
          </div>
          <div className="d-flex">
            <span className="key">License Type</span>
            <span className="value">{l?.licenseType?.licenseTypeName}</span>
          </div>
          <div className="d-flex">
            <span className="key">License Status</span>
            <span className="value">TODO</span>
          </div>
        </div>
      ))}
      {
        _.get(selectedMyProviders, 'verificationChecklist.stateLicenses.verified') && (
          <p style={{ alignItems: 'center', display: 'flex' }}>
            <img src={chatTick} alt="" width={16} height={16} />
            Verified By Revdoc
          </p>
        )
      }
    </div>

  );
}
