import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { initialiseApp } from 'redux-store/auth/authActions';
import { setLoggedIn } from 'redux-store/auth/authSlice';
import { URLS } from 'routes';
import { doesSessionExist } from 'supertokens-web-js/recipe/session';
import Loader from 'shared/components/loader/Loader';
import Cookies from 'universal-cookie';

function VerifyLoggedIn({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    doesSessionExist()
      .then((isLoggedIn) => {
        const completePath = `${location.pathname}${location.search}`;
        const cookies = new Cookies();
        const isOTPVerified = cookies.get('revdoc');
        if (isLoggedIn && isOTPVerified) {
          if (location.pathname.includes('auth')) {
            setIsLoading(true);
          }
          Promise.all([dispatch(initialiseApp()),
            dispatch(setLoggedIn(isLoggedIn))]);
          setIsLoading(false);
          if (location.pathname.includes('auth')) {
            return navigate(URLS.DASHBOARD_URL);
          }
          return null;
        }
        if (!location.pathname.includes('auth')) {
          setIsLoading(false);
          return navigate(URLS.LOGIN_URL);
        }
        return navigate(completePath);
      });
  }, []);

  return (
    <>
      { isLoading && <Loader /> }
      { children}
    </>
  );
}
export default VerifyLoggedIn;
