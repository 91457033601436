/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './_header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { List, Popover, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import { logout } from 'redux-store/auth/authActions';
import _ from 'lodash';
import Cookies from 'universal-cookie';
// import bell from '../../../../assets/imgs/bell.png';
import person from '../../../../assets/imgs/person.png';
import appIcon from '../../../../assets/imgs/logo.png';

function Header() {
  const { isLoggedIn, isLoading, profile } = useSelector((state) => state.auth);
  const disptach = useDispatch();
  const navigate = useNavigate();

  const onLogout = async () => {
    const cookies = new Cookies();
    cookies.remove('revdoc');
    disptach(logout()).then((payload) => {
      if (!payload.error) {
        navigate(URLS.LOGIN_URL);
      }
    });
  };

  return (
    <div className="headerContainer">
      <div className="header">
        <Link to={URLS.DASHBOARD_URL}>
          <img src={appIcon} alt="" height={36} />
        </Link>
        {isLoggedIn && (
          <div className="iconContainer">
            <Popover
              trigger="click"
              overlayInnerStyle={{ padding: 0 }}
              content={(
                <List bordered style={{ cursor: 'pointer' }}>
                  <List.Item onClick={onLogout}>
                    {isLoading && <Spin />}
                    {'  '}
                    Logout
                  </List.Item>
                </List>
              )}
            >
              {/* eslint-disable-next-line */}
              <a href="#">
                <img src={_.get(profile, 'profilePhoto.url', person)} alt="" width={32} height={32} />
              </a>
            </Popover>
            {/* <img src={bell} alt="" width={32} height={32} /> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
