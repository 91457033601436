import { createSlice } from '@reduxjs/toolkit';
import {
  getHomePageIcons,
  postHomePageIcons,
} from './homePageIconsActions';

const INITIAL_STATE = {
  isLoading: false,
  homePageIcons: {},
};

export const homePageIconsSlice = createSlice({
  name: 'homePageIconsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
    reorder: (state, action) => {
      state.homePageIcons = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageIcons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHomePageIcons.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getHomePageIcons.fulfilled, (state, action) => {
      state.isLoading = false;
      state.homePageIcons = action.payload;
    });
    builder.addCase(postHomePageIcons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postHomePageIcons.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postHomePageIcons.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
  reorder,
} = homePageIconsSlice.actions;
