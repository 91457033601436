/* eslint-disable no-return-await */
import React from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import IconText from 'shared/components/iconText/IconText';
import { useDispatch, useSelector } from 'react-redux';
import { sendSignInCode, verifyOTP } from 'redux-store/auth/authActions';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import { toast } from 'react-toastify';

import { emptyFieldValidator } from 'shared/utils/validators';
import appIcon from '../../../assets/imgs/logo.png';
import '../_authentication.scss';

function OtpVerify() {
  const dispatch = useDispatch();
  const { isLoading, signInInfo, profile } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = (vals) => {
    if (signInInfo?.pre_auth_session_id) {
      if (vals?.user_input_code?.trim()?.length > 0) {
        const payload = {
          'pre_auth_session_id': signInInfo?.pre_auth_session_id,
          'code_id': signInInfo?.code_id,
          'device_id': signInInfo?.device_id,
          'user_input_code': vals?.user_input_code,
          'phone': profile?.mobilePhoneNumber,
        };
        dispatch(verifyOTP(payload))
          .then((data) => {
            if (!data.error) {
              navigate(URLS.DASHBOARD_URL);
            }
          });
      }
    } else {
      navigate(URLS.LOGIN_URL);
    }
  };
  const resendOtp = () => {
    if (profile?.mobilePhoneNumber) {
      dispatch(sendSignInCode({ phone: profile?.mobilePhoneNumber })).then((response) => {
        if (!response.error) {
          toast.success('OTP has been sent to your registered mobile number');
        }
      });
    }
  };

  return (
    <div className="container">
      <div className="formBox">
        <img src={appIcon} alt="" height={48} />
        <div className="formfield">
          <IconText text="OTP has been sent to your registered mobile number" />
        </div>
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="user_input_code"
            className="formfield"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
            ]}
          >
            <InputField label="OTP Verification" placeholder="OTP" type="password" />
          </Form.Item>
          <div className="formfield">
            <IconText onClick={() => resendOtp()} icon={faQuestionCircle} text="Resend OTP" />
          </div>
          <div className="actionContainer">
            <Button loading={isLoading} variant={Button.variant.filled} htmlType="submit">
              Verify OTP
            </Button>
            <div style={{ height: 8 }} />
            <Button onClick={() => navigate(URLS.LOGIN_URL)}>
              Cancel
            </Button>
            <div style={{ height: 8 }} />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default OtpVerify;
