import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_giveawaysDashboard.scss';

function MenuItem({ isActive, title, counter }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''}`}>
      <span>{title}</span>
      {counter && <div className="counter">{`${counter >= 10 ? '9+' : counter}`}</div>}
    </div>
  );
}

function GiveawaysDashboard() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);

  return (
    <div className="dashBoardWrapper">
      <NavTabs>
        <NavTabs.Menu className="subNavMenu">
          {access?.giveawaySetup && (
            <NavTabs.MenuItems to={URLS.GIVEAWAYS_URL}>
              <MenuItem title="Giveaways" />
            </NavTabs.MenuItems>
          )}
          {access?.giveawayCriteriaSetup && (
            <NavTabs.MenuItems to={URLS.GIVEAWAYS_CRITERIA_URL}>
              <MenuItem title="Giveaway Criteria" />
            </NavTabs.MenuItems>
          )}
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default GiveawaysDashboard;
