import { createSlice } from '@reduxjs/toolkit';
import {
  createInsuranceSetup,
  deleteInsuranceSetup,
  getInsuranceSetup,
  getInsuranceDetailSetup,
  listInsuranceSetup,
  updateInsuranceSetup,
} from './insuranceSetupActions';

const INITIAL_STATE = {
  isLoading: false,
  insuranceSetups: {},
  selectedInsuranceSetup: {},
  selectedInsurancePlanDetails: {},
};

export const insuranceSetupSlice = createSlice({
  name: 'insuranceSetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listInsuranceSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listInsuranceSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listInsuranceSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.insuranceSetups = action.payload;
    });
    builder.addCase(getInsuranceSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInsuranceSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInsuranceSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedInsuranceSetup = action.payload;
    });
    builder.addCase(getInsuranceDetailSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInsuranceDetailSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getInsuranceDetailSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedInsurancePlanDetails = action.payload;
    });
    builder.addCase(createInsuranceSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createInsuranceSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createInsuranceSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateInsuranceSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateInsuranceSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateInsuranceSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedInsuranceSetup = action.payload.doc;
    });
    builder.addCase(deleteInsuranceSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteInsuranceSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteInsuranceSetup.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = insuranceSetupSlice.actions;
