/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { Checkbox } from 'antd';
import React from 'react';
import './CheckBox.scss';

function CheckBox({
  label,
  checked,
  onChange,
  disabled,
}) {
  return (
    <div className="rv-checkbox-container">
      <Checkbox checked={checked} disabled={disabled} className="rv-checkbox" onChange={onChange}>
        <span className="rv-checkbox__text">
          {label}
        </span>
      </Checkbox>
    </div>
  );
}

export default CheckBox;
