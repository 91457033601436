import { createSlice } from '@reduxjs/toolkit';
import {
  getHomeScreenConfiguration,
  postHomeScreenConfiguration,
} from './homeScreenConfigActions';

const INITIAL_STATE = {
  isLoading: false,
  homeScreenConfiguration: {},
};

export const homeScreenConfigurationSlice = createSlice({
  name: 'homeScreenConfigurationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
    reorder: (state, action) => {
      state.homeScreenConfiguration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomeScreenConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHomeScreenConfiguration.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getHomeScreenConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.homeScreenConfiguration = action.payload;
    });
    builder.addCase(postHomeScreenConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postHomeScreenConfiguration.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postHomeScreenConfiguration.fulfilled, (state, action) => {
      if (action.payload?.length > 0 && action.payload[0]?.message?.includes('horizontalScrolls')
         && action.payload[0]?.name === 'ValidationError' && state.homeScreenConfiguration?.horizontalScrolls?.length > 0) {
        const errors = action.payload[0].data;
        state.homeScreenConfiguration?.horizontalScrolls
          ?.forEach((h, hIndex) => {
            h?.items?.forEach((i, iIndex) => {
              const itemIndex = errors?.findIndex((e) => e.field?.includes(`horizontalScrolls.${hIndex}.items.${iIndex}.`));
              if (itemIndex !== -1 && itemIndex !== undefined) {
                i.isValid = true;
                i.message = errors[itemIndex].message || '';
              }
            });
          });
      }
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
  reorder,
} = homeScreenConfigurationSlice.actions;
