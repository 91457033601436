import { createSlice } from '@reduxjs/toolkit';
import {
  createMemberInviteContent,
  deleteMemberInviteContent,
  getMemberInviteContent,
  getMemberInviteContentLive,
  listMemberInviteContent,
  updateMemberInviteContent,
} from './memberInviteContentActions';

const INITIAL_STATE = {
  isLoading: false,
  memberInviteContent: { docs: [] },
  selectedMemberInviteContent: {},
  liveMemberInviteContent: {},
};

export const MemberInviteContentSlice = createSlice({
  name: 'MemberInviteContentSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listMemberInviteContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listMemberInviteContent.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listMemberInviteContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.memberInviteContent = action.payload;
    });
    builder.addCase(getMemberInviteContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberInviteContent.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberInviteContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMemberInviteContent = action.payload;
    });
    builder.addCase(createMemberInviteContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMemberInviteContent.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMemberInviteContent.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberInviteContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMemberInviteContent.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberInviteContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMemberInviteContent = action.payload.doc;
    });
    builder.addCase(deleteMemberInviteContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMemberInviteContent.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMemberInviteContent.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberInviteContentLive.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberInviteContentLive.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberInviteContentLive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.liveMemberInviteContent = action.payload;
    });
    return builder;
  },
});

export const {
  clearData,
} = MemberInviteContentSlice.actions;
