import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cancelPromosSetup, getDeepLinkSettings, listPromosSetup } from 'redux-store/promosSetup/promosSetupActions';

import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import DropDown from 'shared/components/dropdown/DropDown';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import Grid from 'shared/components/grid/Grid';
import { PromoGridColumns, WarningIcon } from 'utils/grid/columns';
import './_promolist.scss';
import moment from 'moment';
import DeepLinkPromo from './DeepLink';

export default function Promo() {
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    searchText: '',
    depth: 0,
    'where[isPromoCodeAddon][equals]': false,
  };
  const [drpValue, setDrpValue] = useState('');
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [dialogOpen, setDialogOpen] = useState({
    title: '', isOpen: false, isCancel: false, isDeepLink: false, data: null,
  });
  const { promosSetups } = useSelector((state) => state.promosSetups);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.PROMOS_URL}/${id}`);
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.PROMOS_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      setDialogOpen({
        title: 'Cancel Promotion', isOpen: true, isCancel: true, isDeepLink: false, data: item,
      });
    } else if (identifier === 'deeplink') {
      setDialogOpen({
        title: 'Deep Link', isOpen: true, isCancel: false, isDeepLink: true, data: item,
      });
    }
  };
  useEffect(() => {
    dispatch(getDeepLinkSettings());
  }, []);

  useEffect(() => {
    dispatch(listPromosSetup({ ...params }));
  }, [params]);

  useEffect(() => {
    setParams({ ...params, page: promosSetups?.page || 1 });
  }, [promosSetups?.page]);

  const applyFilter = (val) => {
    setDrpValue(val);
    if (val !== '') {
      const payload = INITIAL_PARAMS;
      if (payload['where[_status][equals]']) {
        delete payload['where[_status][equals]'];
      }
      if (payload['where[cancelled][equals]']) {
        delete payload['where[cancelled][equals]'];
      }
      if (payload['where[cancelled][not_equals]']) {
        delete payload['where[cancelled][not_equals]'];
      }
      if (val === 'published') {
        setParams({
          ...payload,
          'where[and][0][_status][equals]': 'published',
          'where[and][1][cancelled][not_equals]': true,
          'where[and][2][or][0][and][0][redemptionLimitType][in][0]': 'once',
          'where[and][2][or][0][and][0][redemptionLimitType][in][1]': 'specificNumber',
          'where[and][2][or][0][and][1][durationType][in][0]': 'durationInHours',
          'where[and][2][or][0][and][1][durationType][in][1]': 'durationDateRange',
          'where[and][2][or][0][and][2][remainingRedemptions][greater_than]': 0,
          'where[and][2][or][0][and][3][effectiveDate][less_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][0][and][3][or][0][termDate][greater_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][0][and][3][or][1][termDate][equals]': null,
          'where[and][2][or][1][and][0][redemptionLimitType][equals]': 'unlimited',
          'where[and][2][or][1][and][1][durationType][in][0]': 'durationInHours',
          'where[and][2][or][1][and][1][durationType][in][1]': 'durationDateRange',
          'where[and][2][or][1][and][2][effectiveDate][less_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][1][and][2][or][0][termDate][greater_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][1][and][2][or][1][termDate][equals]': null,
          'where[and][2][or][2][and][0][durationType][equals]': 'untilAllRedemptionsFilled',
          'where[and][2][or][2][and][1][remainingRedemptions][greater_than]': 0,
        });
      } else if (val === 'draft') {
        setParams({
          ...payload,
          'where[_status][equals]': val,
          'where[cancelled][not_equals]': true,
        });
      } else if (val === 'cancelled') {
        setParams({ ...payload, 'where[cancelled][equals]': true });
      } else if (val === 'completed') {
        setParams({
          ...payload,
          'where[and][0][_status][equals]': 'published',
          'where[and][1][cancelled][not_equals]': true,
          'where[and][2][or][0][and][0][redemptionLimitType][in][0]': 'once',
          'where[and][2][or][0][and][0][redemptionLimitType][in][1]': 'specificNumber',
          'where[and][2][or][0][and][1][durationType][in][0]': 'durationInHours',
          'where[and][2][or][0][and][1][durationType][in][1]': 'durationDateRange',
          'where[and][2][or][0][and][2][or][0][remainingRedemptions][less_than_equal]': 0,
          'where[and][2][or][0][and][2][or][1][termDate][less_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][0][and][2][or][1][or][0][effectiveDate][less_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][0][and][2][or][1][or][1][termDate][equals]': null,
          'where[and][2][or][1][and][0][redemptionLimitType][equals]': 'unlimited',
          'where[and][2][or][1][and][1][durationType][in][0]': 'durationInHours',
          'where[and][2][or][1][and][1][durationType][in][1]': 'durationDateRange',
          'where[and][2][or][1][and][2][termDate][less_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][1][and][2][or][0][effectiveDate][less_than]': moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
          'where[and][2][or][1][and][2][or][1][termDate][equals]': null,
          'where[and][2][or][2][and][0][durationType][equals]': 'untilAllRedemptionsFilled',
          'where[and][2][or][2][and][1][remainingRedemptions][less_than_equal]': 0,
        });
      }
    } else {
      setParams({ ...INITIAL_PARAMS });
    }
  };

  const onCancelDialogPopup = () => {
    setDialogOpen({
      ...dialogOpen, title: '', isOpen: false, isCancel: false, isDeepLink: false, data: null,
    });
  };

  const onConfirmCancel = () => {
    dispatch(cancelPromosSetup({ id: dialogOpen.data?.id, status: 'cancelled' })).then((payload) => {
      if (_.isEmpty(payload.error)) {
        onCancelDialogPopup();
      }
    });
  };

  return (
    <div className="provider-list-container promo-listing">
      <div className="provider-list-container__header">
        <div>
          <span className="list-promo-filter">Status</span>
          <DropDown
            placeholder="Status"
            isOptionsObj
            className="provider-list-dropdown promo-drp"
            options={[{ label: 'ALL', value: '' },
              { label: 'LIVE', value: 'published' },
              { label: 'IN-PROGRESS', value: 'draft' },
              { label: 'CANCELLED', value: 'cancelled' },
              { label: 'COMPLETED', value: 'completed' },
            ]}
            onChange={(e) => { applyFilter(e); }}
            value={drpValue}
          />
        </div>
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Promo Code
          </div>
        </Button>
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={PromoGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )}
          data={promosSetups.docs}
          pagination={{
            total: promosSetups.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>

      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen.isOpen}
        title={dialogOpen.title}
        handleOk={() => {}}
        handleCancel={() => onCancelDialogPopup()}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <div className="promo-listing">
          {' '}
          {
          dialogOpen.isCancel && (
            <div className="cancel-promo">
              <div className="promo-container">
                <WarningIcon />
                {' '}
                <span>{`This will cancel the promotion ${dialogOpen?.data?.promoCode}, confirm to continue.`}</span>
              </div>
              <div className="btn-container">
                <Button
                  variant={Button.variant.outlined}
                  onClick={() => { onCancelDialogPopup(); }}
                >
                  Cancel
                </Button>
                <Button
                  variant={Button.variant.filled}
                  onClick={onConfirmCancel}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )
        }
          {
          dialogOpen.isDeepLink && (
            <DeepLinkPromo data={dialogOpen.data} tag="PromoCode" onClose={onCancelDialogPopup} />
          )
        }
        </div>

      </DialogPopup>
    </div>
  );
}
