/* eslint-disable import/prefer-default-export */
import { REVDOC_API_URL } from 'shared/config/appConfig';

export const MY_PROVIDERS_API_URL = (query = '') => `${REVDOC_API_URL}/providers/${query}`;
export const SUPERVISION_API_URL = (query = '') => `${REVDOC_API_URL}/supervision${query}`;
export const CONCIERAGE_PRIACTICES_API_URL = (query = '') => `${REVDOC_API_URL}/concierge-practices/${query}`;
export const AVAILABILITY_API_URL = (query = '') => `${REVDOC_API_URL}/provider-availabilities${query}`;
export const SUPERVISION_REQUEST_API_URL = (query = '') => `${REVDOC_API_URL}/supervision-requests${query}`;
export const IPROSPECT_REQUEST_API_URL = (query = '') => `${REVDOC_API_URL}/background-check-orders${query}`;
export const GRAPHQL_API_URL = () => `${REVDOC_API_URL}/graphql`;
