import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import { isNew } from 'shared/utils/global';
import { clearData } from 'redux-store/privacyPolicy/privacyPolicySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  allowOnlyANSI, applyTrim, blackListedChars, emptyFieldValidator,
} from 'shared/utils/validators';
import { createProgramCancellationPolicy, getProgramCancellationPolicy, updateProgramCancellationPolicy } from 'redux-store/programCancellationPolicy/programCancellationPolicyActions';
import { RichTextEditor } from 'shared/components/richTextEditor/RichTextEditor';

function AddProgramCancellationPolicy() {
  const { id } = useParams();
  const { selectedProgramCancellationPolicy } = useSelector((s) => s.programCancellationPolicy);
  const { profile } = useSelector((s) => s.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };

  const handleSubmit = (type) => (vals) => {
    vals.type = type;
    vals._status = type;
    vals.createdBy = profile.id;
    vals.policyText = vals.policyText.replace('&nsbp;', ' ');
    vals = applyTrim(vals);

    if (isNew(id)) {
      dispatch(createProgramCancellationPolicy(vals))
        .then((payload) => {
          if (_.isEmpty(payload.error)) {
            goBack();
          }
        });
    } else {
      dispatch(updateProgramCancellationPolicy({ id, params: vals }))
        .then((payload) => {
          if (_.isEmpty(payload.error)) {
            goBack();
          }
        });
    }
  };

  const onPublish = () => {
    form.validateFields()
      .then((values) => {
        handleSubmit('published')(values);
      }).catch(() => {
        toast.error('Please fill the required fields');
      });
  };

  useEffect(() => {
    if (!isNew(id)) {
      dispatch(getProgramCancellationPolicy(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedProgramCancellationPolicy)) {
      form.setFieldsValue(selectedProgramCancellationPolicy.version);
      forceUpdate();
    }
  }, [selectedProgramCancellationPolicy]);
  return (
    <div className="add-new-member-faq-container">
      <div className="add-new-member-faq-container__body">
        <div className="add-new-member-faq-container__heading">
          {isNew(id) ? 'Add New ' : 'Update '}
          Program Cancellation Policy
        </div>
        <Form
          onFinish={handleSubmit('draft')}
          onValuesChange={() => forceUpdate((v) => !v)}
          form={form}
          onFinishFailed={() => toast.error('Please fill the required fields')}
        >
          <Form.Item
            name="policyText"
            className="formfield"
            rules={[
              {
                validator: (m, v) => {
                  const val = v && v.replace(/&nbsp;/g, '').replace(/ /g, '');
                  if (
                    val === '<style>p{margin:2px0px;}</style>'
                      || val === '<p></p><style>p{margin:2px0px;}</style>' || val === undefined
                  ) {
                    return Promise.reject(
                      new Error('This field is required'),
                    );
                  } if (v?.length < 120) {
                    return Promise.reject(new Error('Minimum 120 characters'));
                  }
                  return Promise.resolve();
                },
              },
              allowOnlyANSI,
            ]}
          >
            <RichTextEditor />
          </Form.Item>
          <Form.Item
            name="versionName"
            className="formfield"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              blackListedChars,
            ]}
          >
            <InputField payload="Version Name" label="Version Name *" />
          </Form.Item>
          <div className="add-faq-action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            {!isNew(id) && (
              <div>
                <span className="grey-text">Added on:</span>
                <span className="orng-text">{moment(selectedProgramCancellationPolicy?.version?.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
              </div>
            )}
            <div className="mr-10">
              <Button variant={Button.variant.filled} onClick={onPublish}>Publish</Button>
              <Button variant={Button.variant.filled} htmlType="submit">Save</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddProgramCancellationPolicy;
