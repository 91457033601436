import { createSlice } from '@reduxjs/toolkit';
import {
  createProgramCancellationPolicy,
  deleteProgramCancellationPolicy,
  getProgramCancellationPolicy,
  getProgramCancellationPolicyLive,
  listProgramCancellationPolicy,
  updateProgramCancellationPolicy,
} from './programCancellationPolicyActions';

const INITIAL_STATE = {
  isLoading: false,
  programCancellationPolicy: { docs: [] },
  selectedProgramCancellationPolicy: {},
  liveProgramCancellationPolicy: {},
};

export const ProgramCancellationPolicySlice = createSlice({
  name: 'ProgramCancellationPolicySlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProgramCancellationPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProgramCancellationPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProgramCancellationPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.programCancellationPolicy = action.payload;
    });
    builder.addCase(getProgramCancellationPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProgramCancellationPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProgramCancellationPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProgramCancellationPolicy = action.payload;
    });
    builder.addCase(createProgramCancellationPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProgramCancellationPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProgramCancellationPolicy.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramCancellationPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProgramCancellationPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProgramCancellationPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProgramCancellationPolicy = action.payload.doc;
    });
    builder.addCase(deleteProgramCancellationPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProgramCancellationPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProgramCancellationPolicy.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProgramCancellationPolicyLive.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProgramCancellationPolicyLive.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProgramCancellationPolicyLive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.liveProgramCancellationPolicy = action.payload;
    });
    return builder;
  },
});

export const {
  clearData,
} = ProgramCancellationPolicySlice.actions;
