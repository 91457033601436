import { createSlice } from '@reduxjs/toolkit';
import { getChecklists } from 'shared/utils/checklist';
import {
  createMyProviders,
  deleteMyProviders,
  getMyProviders,
  listMyProviders,
  listAllProviders,
  updateMultipleMyProviders,
  updateMyProviders,
  getSupervisors,
  updateConciergePractices,
  getAvailabilities,
  getSuperVisionRequest,
  getIProspectDocument,
  getMyProviderById,
} from './myProvidersActions';

const INITIAL_STATE = {
  isLoading: false,
  myProviders: { docs: [] },
  allProviders: { docs: [] },
  selectedMyProviders: {},
  supervisors: { docs: [] },
  supervisionRequests: { docs: [] },
  availability: { docs: [] },
  iProspectDocument: null,
};

export const myProvidersSlice = createSlice({
  name: 'myProvidersSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
    clearProviderData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listMyProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.myProviders = action.payload;
    });
    builder.addCase(listAllProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listAllProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listAllProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      const data = {
        ...action.payload,
        docs: action.payload?.docs?.map((p) => ({
          id: p.id,
          biography: p.biography,
          firstName: p.firstName,
          middleName: p.middleName,
          lastName: p.lastName,
          providerType: p.providerType,
          verificationChecklist: p.verificationChecklist,
          completedHipaaTrainingDates: p.completedHipaaTrainingDates,
          deliveryAddresses: p.deliveryAddresses,
          defaultProcedures: p.defaultProcedures,
          licensedStateCodes: p.licensedStateCodes,
          providerStatus: p.providerStatus,
          mobilePhoneNumber: p.mobilePhoneNumber,
          email: p.email,
          homeAddress: p.homeAddress,
          label: p.fullName,
          value: p.id,
          key: p.id,
        })),
      };
      state.allProviders = data;
    });
    builder.addCase(getMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMyProviders.fulfilled, (state, action) => {
      const verificationCheckList = getChecklists(action.payload);
      state.selectedMyProviders = {
        ...action.payload,
        providerStatus: action.payload?.providerStatus?.replace('_', '-'),
        verificationChecklist: verificationCheckList,
      };
      state.isLoading = false;
    });
    builder.addCase(getMyProviderById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMyProviderById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMyProviderById.fulfilled, (state, action) => {
      state.selectedMyProviders = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMyProviders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMyProviders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMultipleMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleMyProviders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMyProviders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSupervisors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSupervisors.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSupervisors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supervisors = action.payload;
    });
    builder.addCase(getSuperVisionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSuperVisionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSuperVisionRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supervisionRequests = action.payload;
    });
    builder.addCase(getIProspectDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIProspectDocument.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getIProspectDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.iProspectDocument = action.payload;
    });
    builder.addCase(updateConciergePractices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateConciergePractices.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateConciergePractices.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAvailabilities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAvailabilities.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAvailabilities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.availability = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
  clearProviderData,
} = myProvidersSlice.actions;
