/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import {
  GIVE_AWAYS_CRITERIA_API_URL,
} from './criteriaUrls';

export const listGiveawayCriterias = createAsyncThunk(
  'giveawayCriteriaSlice/listGiveawayCriterias',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(GIVE_AWAYS_CRITERIA_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getGiveawayCriteria = createAsyncThunk(
  'giveawayCriteriaSlice/getGiveawayCriteria',
  async (id) => {
    return axios.get(GIVE_AWAYS_CRITERIA_API_URL(id)).then(({ data }) => data);
  },
);

export const createGiveawayCriteria = createAsyncThunk(
  'giveawayCriteriaSlice/createGiveawayCriteria',
  async (params) => {
    return axios.post(
      GIVE_AWAYS_CRITERIA_API_URL(),
      params,
    ).then(({ data }) => {
      toast.success('Criteria created successfully');
      return data;
    });
  },
);

export const updateGiveawayCriteria = createAsyncThunk(
  'giveawayCriteriaSlice/updateGiveawayCriteria',
  async ({ id, params }) => {
    return axios.patch(
      GIVE_AWAYS_CRITERIA_API_URL(id),
      params,
    ).then(() => {
      toast.success('Criteria updated');
    });
  },
);
