/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listDeliveryMethod } from 'redux-store/deliveryMethod/deliveryMethodActions';
import { DELIVERY_METHODS } from 'shared/constants/global';
import { getNonDeletedRecordsFilter } from 'shared/utils/filters';
import MultiCheckboxSelect from '../multiCheckboxSelect/MultiCheckboxSelect';

function DeliveryMethods({
  value = [],
  onChange,
  checkAll,
  disabled,
  label,
  single = false,
  disabledItems = [],
  unCheckedItems = [],
}) {
  const INITIAL_PARAMS = {
    limit: 0,
    page: 1,
    ...getNonDeletedRecordsFilter(),
  };
  const [search] = useState(INITIAL_PARAMS);
  const { deliveryMethods = { docs: [] } } = useSelector((s) => s.deliveryMethod);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listDeliveryMethod(search));
  }, [search]);

  return (
    <div style={disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
      <MultiCheckboxSelect
        checkAll={checkAll}
        label={label || 'Delivery Method *'}
        options={deliveryMethods
          .docs.map((o) => ({
            label: DELIVERY_METHODS[o.internalIdentifier],
            value: o.id,
            disabled: disabledItems?.includes(o.internalIdentifier),
            unChecked: unCheckedItems?.includes(o.internalIdentifier),
          }))}
        value={value}
        onChange={onChange}
        single={single}
      />
    </div>
  );
}

export default DeliveryMethods;
