import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import './_addNewProgram.scss';
import { isNew } from 'shared/utils/global';
import { createProgramMember, getProgramMember, updateProgramMemebr } from 'redux-store/programs/programActions';
import { clearData } from 'redux-store/programs/programSlice';
import InputField from 'shared/components/inputField/InputField';
import moment from 'moment';
import Button from 'shared/components/button/Button';
import {
  applyTrim, checkAgeGap, emptyFieldValidator, emptyCompareFieldValidator,
  blackListedChars,
} from 'shared/utils/validators';
import DropDown from 'shared/components/dropdown/DropDown';
import DatePicker from 'shared/components/datePicker/DatePicker';
import CheckBox from 'shared/components/checkbox/CheckBox';

export default function AddNewMemberProgram() {
  const { id, pmId } = useParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {
    selectedProgramMember,
  } = useSelector((state) => state.program);

  useEffect(() => {
    if (pmId !== 'new') {
      dispatch(getProgramMember(pmId));
    }
    return () => dispatch(clearData());
  }, []);

  const goBack = () => {
    form.resetFields();
    navigate(`/dashboard/program/programs/${id}/members`);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedProgramMember)) {
      form.setFieldsValue({
        ...selectedProgramMember,
        effectiveDate: selectedProgramMember?.effectiveDate ? moment(new Date(selectedProgramMember?.effectiveDate)).format('YYYY-MM-DD') : null,
        terminatedDate: selectedProgramMember?.terminatedDate ? moment(new Date(selectedProgramMember?.terminatedDate)).format('YYYY-MM-DD') : null,
      });
      setRefresh(!refresh);
    } else if (pmId === 'new') {
      form.setFieldsValue({ status: 'invited', isSendDeepLink: true, effectiveDate: moment().format('YYYY-MM-DD') });
      setRefresh(!refresh);
    }
  }, [selectedProgramMember]);

  const onChangeValue = (k, v) => {
    if (k === 'status' && v === 'terminated') {
      form.setFieldsValue({
        ...selectedProgramMember,
        effectiveDate: selectedProgramMember?.effectiveDate ? moment(new Date(selectedProgramMember?.effectiveDate)).format('YYYY-MM-DD') : null,
        terminatedDate: moment(new Date()).format('YYYY-MM-DD'),
      });
    }

    form.setFieldsValue({ [k]: v });
    setRefresh(!refresh);
  };

  const isDisabled = () => pmId !== 'new';

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    vals.program = id;
    vals.memberEnrollType = 'employerBasedProgram';
    vals.memberInfo.dateOfBirth = vals?.memberInfo?.dateOfBirth ? moment(vals?.memberInfo?.dateOfBirth).format('YYYY-MM-DD') : null;
    if (pmId === 'new') {
      dispatch(createProgramMember(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateProgramMemebr({ id: pmId, params: vals })).then((payload) => {
        if (_.isEmpty(payload.error) && vals.status === 'terminated') {
          goBack();
        }
      });
    }
  };

  const getStatus = () => {
    let options = [{ label: 'Terminated', value: 'terminated' }];
    if (pmId === 'new') {
      options = [{ label: 'Invited', value: 'invited' }];
    } else if (selectedProgramMember?.status === 'enrolled') {
      options = [{ label: 'Enrolled', value: 'enrolled' },
        { label: 'Suspended', value: 'suspended' },
        { label: 'Terminated', value: 'terminated' }];
    } else if (selectedProgramMember?.status === 'suspended') {
      options = [
        { label: 'Suspended', value: 'suspended' },
        { label: 'Terminated', value: 'terminated' }];
    }
    return options;
  };

  return (
    <div className="program-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={(e) => {
          console.log(e);
          toast.error('Please fill the entire form');
        }}
      >
        <div className="program-container__outer program-member">
          <div className="program-container__wrapper">
            <h1>
              {' '}
              {isNew(pmId) ? 'Add New ' : 'Update '}
              {' '}
              Member
            </h1>
            <div className="section">
              <div className="sub-col">
                <Form.Item
                  name={['memberInfo', 'firstName']}
                  rules={[{ required: true, message: 'This field is required' },
                  ]}
                >
                  <InputField
                    label="First Name *"
                    placeholder="First Name"
                    className="formfield"
                    maxLength={100}
                    disabled={isDisabled()}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name={['memberInfo', 'lastName']}
                  rules={[{ required: true, message: 'This field is required' },
                  ]}
                >
                  <InputField
                    label="Last Name *"
                    placeholder="Last Name"
                    className="formfield"
                    maxLength={100}
                    disabled={isDisabled()}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="section">
              <div className="sub-col">
                <Form.Item
                  name={['memberInfo', 'emailAddress']}
                  rules={[{ required: true, message: 'This field is required' },
                    { type: 'email', message: 'This is not a valid email' },
                    blackListedChars,
                  ]}
                >
                  <InputField
                    label="Email Address *"
                    placeholder="Email Address"
                    className="formfield"
                    maxLength={100}
                    disabled={isDisabled()}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name={['memberInfo', 'phoneNumber']}
                  rules={[{ validator: emptyFieldValidator, message: 'This field should atleast have 9 characters long' },
                    { pattern: /^[\d]{9,13}$/, message: 'This field should have a minimum of 9 characters and maximum of 13 characters' },
                  ]}
                >
                  <InputField
                    label="Phone Number *"
                    placeholder="Phone Number"
                    className="formfield"
                    maxLength={100}
                    disabled={isDisabled()}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="section">
              <div className="sub-col promo-cal">
                <Form.Item
                  name="effectiveDate"
                  className="formfield"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Effective Date *"
                    disabled={isDisabled() && form.getFieldValue('status') === 'terminated'}
                    disabledDate={(d) => !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    onChange={() => form.setFieldsValue({ termDate: null })}
                  />
                </Form.Item>
              </div>
              <div className="sub-col promo-cal">
                <Form.Item
                  name="terminatedDate"
                  className="formfield"
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{ validator: (m, v) => emptyCompareFieldValidator(m, v, 'terminated', form.getFieldValue('status')), message: 'This field is required' }]}
                >
                  <DatePicker
                    label={`Termintion Date ${form.getFieldValue('status') === 'terminated' ? '*' : ''}`}
                    disabledDate={(d) => (d.isBefore(
                      moment(
                        new Date(),
                        'MM-DD-YYYY',
                      ).subtract(1, 'd'),
                    ) || d.isAfter(
                      moment(
                        new Date(),
                        'MM-DD-YYYY',
                      ),
                    ))}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    disabled={form.getFieldValue('status') !== 'terminated'}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="last-section">
              <div className="sub-col promo-cal">
                <Form.Item
                  name={['memberInfo', 'dateOfBirth']}
                  className="formfield"
                  rules={[{ validator: (m, v) => checkAgeGap(m, v, 18), message: 'Age must be 18 years to register' }]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Date of Birth"
                    disabled={isDisabled() && form.getFieldValue('status') === 'terminated'}
                    disabledDate={(d) => !d
                      || d.isAfter(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    onChange={() => form.setFieldsValue({ termDate: null })}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DropDown
                    label="Status *"
                    placeholder="Status"
                    className="biomarker-dropdown"
                    isOptionsObj
                    options={getStatus()}
                    onChange={(v) => onChangeValue('status', v)}
                  />
                </Form.Item>
              </div>
            </div>
            { pmId === 'new' && (
            <div className="last-section invitation-link">
              <Form.Item
                name="isSendDeepLink"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Send Deep Link" />
              </Form.Item>
            </div>
            )}
          </div>
          <div className="program-container__outer">
            <div className="action-btn-wrapper">
              <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
              {!isNew(pmId) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated:</span>
                  <span className="orange">{moment(selectedProgramMember.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
                </div>
              )}

              <div className="btn-promo-outer">
                {selectedProgramMember?.status !== 'terminated' && (<Button variant={Button.variant.filled} htmlType="submit">{isNew(pmId) ? 'Save' : 'Update'}</Button>)}
              </div>

            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
