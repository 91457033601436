/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { allowOnlyANSI, applyTrim } from 'shared/utils/validators';
import { getDeepLinkMessages, updatePromosDeepLink } from 'redux-store/promosSetup/promosSetupActions';
import { CopyIcon, TickIcon, WarnigAlertlIcon } from 'utils/grid/columns';
import Button from 'shared/components/button/Button';
import CheckBox from 'shared/components/checkbox/CheckBox';
import TextAreaField from 'shared/components/textArea/TextArea';
import { toast } from 'react-toastify';
import './_promos.scss';
import _ from 'lodash';
import moment from 'moment';
import { convertMiliseconds } from 'shared/utils/helper';

export default function DeepLinkPromo(params) {
  const { data, onClose, tag } = params;
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showCopied, setShowCopeied] = useState(false);
  const {
    isLoading,
    deepLinkMessages,
    deepLinkSettings,
  } = useSelector((state) => state.promosSetups);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [lastMessageSent, setLastMessageSent] = useState({ message: '' });

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const query = {
        'where[settings.type][equals]': tag,
        sort: '-createdAt',
      };
      dispatch(getDeepLinkMessages(query));
    }
  }, [data]);

  useEffect(() => {
    if (deepLinkMessages?.docs?.length > 0) {
      form.setFieldsValue({
        isPhoneDeepLink: true,
        promotionMessage: '',
        isEmailDeepLink: false,
        isPushNotificationDeepLink: false,
      });
      setRefresh(!refresh);
      const lastMessageSentDuration = (new Date()).getTime()
      - new Date(deepLinkMessages?.docs[0].createdAt).getTime();
      if (lastMessageSentDuration > 0) {
        const duration = convertMiliseconds(lastMessageSentDuration, 't');
        setLastMessageSent({ message: duration });
        setTimeout(() => {
          setLastMessageSent({ message: null });
          setRefresh(!refresh);
        }, deepLinkSettings.minimumTimeBetweenTextMessageMarketingCampaignsMs);
      }
    }
  }, [deepLinkMessages]);

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    dispatch(updatePromosDeepLink({ id: data?.id, params: { ...vals, tag } }));
    if (onClose) {
      onClose();
    }
  };

  const copyToClipboard = () => {
    const copyText = data?.adDeepLink || data?.deepLink;
    const isCopy = copy(copyText);
    if (isCopy) {
      setShowCopeied(isCopy);
    }
  };

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => {
        setShowCopeied(false);
      }, 500);
    }
  }, [showCopied]);

  return (
    <div>
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={(e) => {
          console.log(e);
          toast.error('Please fill the entire form');
        }}
      >
        <div className="add-new-specialty-container promo-deep-link">
          {isLoading && 'Loading...'}
          <div className="add-new-specialty-container__wrapper">
            <span className="promo-header">
              {tag === 'PromoCode' ? `Promo Code ${data?.promoCode}` : `Program ${data?.programName}`}
            </span>
            <div className="section no-border">
              {(data?.adDeepLink || data?.deepLink) && (
                <div>
                  <div>
                    <label>Generated Deep Link</label>
                  </div>
                  <div className="promo-link">
                    <label className="deep-link">
                      {data?.adDeepLink || data?.deepLink}
                      <div onClick={() => copyToClipboard()}>
                        <CopyIcon />
                      </div>
                    </label>
                    {showCopied && (
                      <span className="deep-link-copy">
                        <TickIcon />
                        {' '}
                        Copied!
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="check-label-promo">
                <label>{tag === 'PromoCode' ? 'Promo Shared Method' : 'Program Shared Method'}</label>
              </div>
              <div className="deep-link-item">
                <Form.Item
                  name="isPhoneDeepLink"
                  style={{ display: 'flex' }}
                  valuePropName="checked"
                >
                  <CheckBox label="Share with Text" />
                </Form.Item>

                {form.getFieldValue('isPhoneDeepLink') === true && (
                <div>
                  { (lastMessageSent.message
                  && deepLinkSettings?.minimumTimeBetweenTextMessageMarketingCampaignsMs
                  && deepLinkMessages?.docs?.length > 0
                  && (new Date()).getTime()
                  - new Date(deepLinkMessages?.docs[0].createdAt).getTime()
                  < deepLinkSettings.minimumTimeBetweenTextMessageMarketingCampaignsMs) && (
                  <div className="promo-alert">
                    <WarnigAlertlIcon />
                    <span>
                      The last SMS promotion was sent out less than
                      {' '}
                      {lastMessageSent.message || 'few minutes'}
                      ago. You must wait until
                      {' '}
                      {moment(new Date(deepLinkMessages?.docs[0].createdAt))
                        .add(deepLinkSettings?.minimumTimeBetweenTextMessageMarketingCampaignsMs, 'ms').format('MM/DD/YYYY hh:mm:ss')}
                    </span>
                  </div>
                  )}
                  <Form.Item
                    name="promotionMessage"
                    rules={[
                      { required: true, message: 'This field is required' },
                      { min: 10, message: 'This field should have at least 10 characters' },
                      allowOnlyANSI,
                    ]}
                  >
                    <TextAreaField
                      label="Text Preview"
                      rows={2}
                      maxLength={160}
                      className="formfield"
                      disabled={lastMessageSent.message && deepLinkMessages?.docs?.length > 0
                        && (new Date()).getTime()
                        - new Date(deepLinkMessages?.docs[0].createdAt).getTime()
                         < deepLinkSettings.minimumTimeBetweenTextMessageMarketingCampaignsMs}
                    />
                  </Form.Item>
                  <div className="text-length">
                    {form.getFieldValue('promotionMessage')?.length || 0}
                    /160
                  </div>
                </div>
                )}
              </div>
              <div className="deep-link-item">
                <Form.Item
                  name="isEmailDeepLink"
                  style={{ display: 'flex' }}
                  valuePropName="checked"
                >
                  <CheckBox label="Share With Email" />
                </Form.Item>
                {form.getFieldValue('isEmailDeepLink') === true && (
                <div>
                  <div className="promo-alert">
                    <WarnigAlertlIcon />
                    <span>
                      The last Email promotion was sent out less than 5 days ago.
                      You must wait until
                      {' '}
                      {moment().add(5, 'd').format('MM/DD/YYYY')}
                    </span>
                  </div>
                  <div>
                    <Form.Item
                      name="emailSubjectLine"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <TextAreaField
                        label="Email Subject Line"
                        rows={3}
                        maxLength={40}
                        className="formfield"
                      />
                    </Form.Item>
                    <div className="text-length">
                      {form.getFieldValue('emailSubjectLine')?.length || 0}
                      /40
                    </div>
                  </div>
                  <div>
                    <Form.Item
                      name="emailBody"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <TextAreaField
                        label="Email Body"
                        rows={3}
                        maxLength={1500}
                        className="formfield"
                      />
                    </Form.Item>
                    <div className="text-length">
                      {form.getFieldValue('emailBody')?.length || 0}
                      /1500
                    </div>
                  </div>
                </div>
                )}
              </div>
              <div className="deep-link-item">
                <Form.Item
                  name="isPushNotificationDeepLink"
                  style={{ display: 'flex' }}
                  valuePropName="checked"
                >
                  <CheckBox label="Share With Push Notification" />
                </Form.Item>
                {form.getFieldValue('isPushNotificationDeepLink') === true && (
                <div>
                  <div className="promo-alert">
                    <WarnigAlertlIcon />
                    <span>
                      The last Push Notification promotion was sent out
                      less than 5 days ago.
                      You must wait until
                      {' '}
                      {moment().add(5, 'd').format('MM/DD/YYYY')}
                    </span>
                  </div>
                  <div>
                    <Form.Item
                      name="pushNotificationHeader"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <TextAreaField
                        label="Push Notification Body"
                        rows={1}
                        maxLength={35}
                        className="formfield"
                      />
                    </Form.Item>
                    <div className="text-length">
                      {form.getFieldValue('pushNotificationHeader')?.length || 0}
                      /35
                    </div>
                  </div>
                  <div>
                    <Form.Item
                      name="pushNotificationBody"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <TextAreaField
                        label="Push Notification Body"
                        rows={2}
                        maxLength={160}
                        className="formfield"
                      />
                    </Form.Item>
                    <div className="text-length">
                      {form.getFieldValue('pushNotificationBody')?.length || 0}
                      /160
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="section no-border">
              <div className="action-btn-wrapper">
                <div className="btn-promo-outer">
                  <Button variant={Button.variant.outlined} onClick={onClose}>Cancel</Button>
                  <Button variant={Button.variant.filled} htmlType="submit">Send Deep Link</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
