/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { GIVEAWAY_IMAGE } from 'shared/constants/global';
import { createMedia } from 'redux-store/media/mediaActions';
import _ from 'lodash';
import {
  GIVE_AWAYS_API_URL,
} from './giveawayUrls';

export const listGiveaways = createAsyncThunk(
  'giveawaySlice/listGiveaways',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(GIVE_AWAYS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getGiveaway = createAsyncThunk(
  'giveawaySlice/getGiveaway',
  async (id) => {
    return axios.get(GIVE_AWAYS_API_URL(id)).then(({ data }) => data);
  },
);

export const createGiveaway = createAsyncThunk(
  'giveawaySlice/createGiveaway',
  async (params, { dispatch }) => {
    const images = _.get(params, 'giveawayImages', null);
    const uploadRequests = images?.fileList
      ?.map((imgID) => createMedia(imgID?.originFileObj, GIVEAWAY_IMAGE)?.then((img) => {
        return img.doc.id;
      }));
    Promise.all(uploadRequests).then((x) => {
      const giveawayImages = x?.map((imageId) => ({ giveawayImage: imageId }));
      return axios.post(
        GIVE_AWAYS_API_URL(),
        {
          ...params,
          giveawayImages,
        },
      ).then(({ data }) => {
        toast.success('Giveaways created successfully');
        if (params._status === 'published') {
          toast.success('Giveaways is live');
        }
        dispatch(listGiveaways({ page: 1, limit: 10 }));
        return data;
      });
    });
  },
);

export const updateGiveaway = createAsyncThunk(
  'giveawaySlice/updateGiveaway',
  async ({ id, params }, { dispatch }) => {
    const images = _.get(params, 'giveawayImages', null);
    const imageIds = images?.fileList?.map((imgID) => {
      if (imgID && (typeof (imgID) !== 'object' || imgID.id !== undefined)) {
        if (typeof (imgID) === 'object') {
          return imgID.id;
        }
        return imgID;
      }
      return null;
    })?.filter((x) => x !== null);
    const newuploads = images?.fileList?.map((imgID) => {
      if (imgID && typeof (imgID) === 'object' && imgID.id === undefined) {
        return imgID;
      }
      return null;
    })?.filter((x) => x !== null);
    if (newuploads?.length > 0) {
      const uploadRequests = newuploads
        ?.map((imgID) => createMedia(imgID?.originFileObj, GIVEAWAY_IMAGE)?.then((img) => {
          return img.doc.id;
        }));
      Promise.all(uploadRequests).then((x) => {
        const ids = imageIds.concat(x);
        const giveawayImages = ids?.map((imageId) => ({ giveawayImage: imageId }));
        return axios.patch(
          GIVE_AWAYS_API_URL(id),
          {
            ...params,
            giveawayImages,
          },
        ).then(() => {
          toast.success('Giveaways updated');
          dispatch(getGiveaway(id));
        });
      });
    } else {
      const giveawayImages = imageIds?.map((imageId) => ({ giveawayImage: imageId }));
      return axios.patch(
        GIVE_AWAYS_API_URL(id),
        {
          ...params,
          giveawayImages,
        },
      ).then(() => {
        toast.success('Giveaways updated');
        dispatch(getGiveaway(id));
      });
    }
    return null;
  },
);
