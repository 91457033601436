/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Spin } from 'antd';
import './_loader.scss';
import { useSelector } from 'react-redux';

function Loader() {
  const isLoading = useSelector((state) => (
    state.categorySetup.isLoading
    || state.suppliesSetup.isLoading
    || state.proceduresSetup.isLoading
    || state.providerType.isLoading
    || state.deliveryMethod.isLoading
    || state.insuranceSetup.isLoading
    || state.supplyVendors.isLoading
    || state.userManagementSetups.isLoading
    || state.myProviders.isLoading
    || state.specialitySetups.isLoading
    || state.complaints.isLoading
    || state.symptoms.isLoading
    || state.atHomeLabs.isLoading
    || state.memberFAQs.isLoading
    || state.providerFAQs.isLoading
    || state.memberTerms.isLoading
    || state.howTos.isLoading
    || state.providerTerms.isLoading
    || state.privacyPolicies.isLoading
    || state.bioMarkers.isLoading
    || state.quickLinks.isLoading
    || state.quickLinks.isLoadingSections
    || state.medicationSet.isLoading
    || state.products.isLoading
    || state.feedbacks.isLoading
    || state.preparationSteps.isLoading
    || state.promosSetups.isLoading
    || state.welcomeMessage.isLoading
    || state.program.isLoading
    || state.homeScreenConfiguration.isLoading
    || state.affiliation.isLoading
    || state.giveaway.isLoading
    || state.providerState.isLoading
    || state.criteria.isLoading
    || state.programCancellationPolicy.isLoading
    || state.homePageIcons.isLoading
  ));
  return isLoading && (
    <div className="loader">
      <Spin />
    </div>
  );
}

export default Loader;
