/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import _ from 'lodash';
import { HOME_SCREEN_CONFIG_API_URL } from './homeScreenConfigUrls';

export const getHomeScreenConfiguration = createAsyncThunk(
  'homeScreenConfiguration/get',
  async () => {
    return axios.get(HOME_SCREEN_CONFIG_API_URL()).then(({ data }) => data);
  },
);

export const postHomeScreenConfiguration = createAsyncThunk(
  'homeScreenConfiguration/postHomeScreenConfiguration',
  async (params, { dispatch }) => {
    return axios.post(
      HOME_SCREEN_CONFIG_API_URL(),
      {
        ...params.params,
      },
    ).then(({ data }) => {
      toast.success('Member Home Screen Configuration updated');
      dispatch(getHomeScreenConfiguration());
      return data;
    }).catch((error) => {
      const errorData = _.get(error, 'response.data.errors');
      return errorData;
    });
  },
);
