import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getDeepLinkSettings } from 'redux-store/promosSetup/promosSetupActions';
import { clearData } from 'redux-store/programs/programSlice';

import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import Grid from 'shared/components/grid/Grid';
import { ProgramsMembersGridColumns } from 'utils/grid/columns';
import './_programs.scss';
import { getPrograms, listProgramMembers } from 'redux-store/programs/programActions';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { VectorSign } from 'shared/components/providerBanner/ProviderBanner';
import DropDown from 'shared/components/dropdown/DropDown';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import DeepLinkPromo from '../promos/DeepLink';

export default function ProgramMembers() {
  const { id } = useParams();
  const INITIAL_PARAMS = { limit: 10, page: 1, searchText: '' };
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [drpValue, setDrpValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [dialogOpen, setDialogOpen] = useState({
    title: '', isOpen: false, isDeepLink: false, data: null,
  });

  const { programMembers, selectedProgram } = useSelector((state) => state.program);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (prgramMemberId) => {
    navigate(`${URLS.PROGRAMS_URL}/${id}/members/${prgramMemberId}`);
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.PROGRAMS_URL}/${id}/members/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'deeplink') {
      setDialogOpen({
        title: 'Deep Link', isOpen: true, isDeepLink: true, data: item,
      });
    }
  };
  useEffect(() => {
    dispatch(getDeepLinkSettings());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(listProgramMembers({ ...params, 'where[and][0][program][equals]': id }));
    }
  }, [params, id]);

  useEffect(() => {
    if (id) {
      dispatch(getPrograms(id));
    }
    return () => dispatch(clearData());
  }, [id]);

  const onSearch = (v) => {
    setSearchText(v);
    setParams({
      limit: 10,
      page: 1,
      'where[and][1][or][0][member.demographics.firstName][like]': v,
      'where[and][1][or][1][member.demographics.lastName][like]': v,
      'where[and][1][or][2][memberInfo.firstName][like]': v,
      'where[and][1][or][3][memberInfo.lastName][like]': v,
    });
  };

  const applyFilter = (val) => {
    setDrpValue(val);
    const payload = { ...INITIAL_PARAMS };
    if (searchText) {
      payload['where[and][1][or][0][member.demographics.firstName][like]'] = searchText;
      payload['where[and][1][or][1][member.demographics.lastName][like]'] = searchText;
      payload['where[and][1][or][2][memberInfo.firstName][like]'] = searchText;
      payload['where[and][1][or][3][memberInfo.lastName][like]'] = searchText;
    }
    if (val !== '' && val !== 'completed') {
      setParams({ ...payload, 'where[status][equals]': val });
    } else if (val === 'completed') {
      setParams({ ...payload, 'where[allAppointmentsBooked][equals]': true });
    } else {
      setParams({ ...payload });
    }
  };

  const onCancelDialogPopup = () => {
    setDialogOpen({
      ...dialogOpen, title: '', isOpen: false, isDeepLink: false, data: null,
    });
  };

  const getStatusOptions = () => {
    let options = [{ label: 'ALL', value: '' },
      { label: 'Invited', value: 'invited' },
      { label: 'Enrolled', value: 'enrolled' },
      { label: 'Suspended', value: 'suspended' },
      { label: 'Terminated', value: 'terminated' },
    ];
    if (selectedProgram?.programType !== 'employerBasedProgram') {
      options = [{ label: 'ALL', value: '' },
        { label: 'Enrolled', value: 'enrolled' },
        { label: 'Completed', value: 'completed' },
        { label: 'Cancelled', value: 'terminated' },
      ];
    }

    return options;
  };

  return (
    <div className="provider-list-container program-listing">
      <div className="program-listing__header1">
        <span onClick={() => navigate(`${URLS.PROGRAMS_URL}`)}>Programs</span>
        <VectorSign />
        <h4>{selectedProgram?.programName}</h4>
      </div>
      <div className="provider-list-container__header">
        <div className="program-member-filter">
          <div>
            <span className="search-label">Search</span>
            <SearchBox placeholder="Search Member Name" waitTime={500} onChange={onSearch} />
          </div>
          <div>
            <span className="search-label">Status</span>
            <DropDown
              placeholder="Status"
              isOptionsObj
              className="provider-list-dropdown promo-drp"
              options={getStatusOptions()}
              onChange={(e) => { applyFilter(e); }}
              value={drpValue}
            />
          </div>
        </div>
        {(selectedProgram?.programType === 'employerBasedProgram' && selectedProgram?.status === 'live') && (
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Member
          </div>
        </Button>
        )}
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={ProgramsMembersGridColumns(
            { isEditable: selectedProgram?.programType === 'employerBasedProgram', ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )}
          data={programMembers.docs}
          pagination={{
            total: programMembers.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen.isOpen}
        title={dialogOpen.title}
        handleOk={() => {}}
        handleCancel={() => onCancelDialogPopup()}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <div className="cancel-section">
          {
          dialogOpen.isDeepLink && (
            <DeepLinkPromo data={{ ...dialogOpen.data, ...selectedProgram }} tag="Program" onClose={onCancelDialogPopup} />
          )
        }
        </div>
      </DialogPopup>
    </div>
  );
}
