import React, { useEffect } from 'react';
import './_addMedicationSet.scss';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { clearData } from 'redux-store/medicationSet/medicationSetSlice';
import { isNew } from 'shared/utils/global';
import { toast } from 'react-toastify';
import {
  allowOnlyNumbers,
  applyTrim,
  blackListedChars,
  emptyFieldValidator,
  failSafeEmptyFieldValidator,
} from 'shared/utils/validators';
import {
  getMedicationSet,
  createMedicationSet,
  updateMedicationSet,
} from 'redux-store/medicationSet/medicationSetActions';
import DropDown from 'shared/components/dropdown/DropDown';
import LinkedProcedure from './LinkedProcedure';
import { ReactComponent as AddMedicationSupply } from '../../../../../../assets/svgs/add-medication-supply.svg';
import MedicationSupply from './MedicationSupply';

export const QUANTITY_UOM_OPTIONS = [
  { label: 'Applicator', value: 'C62412' },
  { label: 'Blister', value: 'C54564' },
  { label: 'Caplet', value: 'C64696' },
  { label: 'Capsule', value: 'C48480' },
  { label: 'Each', value: 'C64933' },
  { label: 'Film', value: 'C53499' },
  { label: 'Gram', value: 'C48155' },
  { label: 'Gum', value: 'C69124' },
  { label: 'Implant', value: 'C48499' },
  { label: 'Insert', value: 'C62276' },
  { label: 'Kit', value: 'C48504' },
  { label: 'Lancet', value: 'C120263' },
  { label: 'Lozenge', value: 'C48506' },
  { label: 'Milliliter', value: 'C28254' },
  { label: 'Packet', value: 'C48521' },
  { label: 'Pad', value: 'C65032' },
  { label: 'Patch', value: 'C48524' },
  { label: 'Pen Needle', value: 'C120216' },
  { label: 'Ring', value: 'C62609' },
  { label: 'Sponge', value: 'C53502' },
  { label: 'Stick', value: 'C53503' },
  { label: 'Strip', value: 'C48538' },
  { label: 'Suppository', value: 'C48539' },
  { label: 'Swab', value: 'C53504' },
  { label: 'Tablet', value: 'C48542' },
  { label: 'Troche', value: 'C48548' },
  { label: 'Unspecified', value: 'C38046' },
  { label: 'Wafer', value: 'C48552' },
];
export default function AddNewMedicationSet() {
  const { id } = useParams();
  const { isLoading, selectedMedicationSet } = useSelector((s) => s.medicationSet);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };

  const handleSubmit = (vals) => {
    vals = {
      ...vals,
      procedure: vals.procedure.id,
      medicationSet: vals.medicationSet.map((m) => {
        m.supplyGroup = m.supplyGroup.map((s) => ({
          ...s,
          productId: s.productId.id,
          quantityUOM: s.quantityId,
        }));
        return m;
      }),
    };
    vals = applyTrim(vals);
    if (id === 'new') {
      dispatch(createMedicationSet(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateMedicationSet({ id, data: vals }));
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getMedicationSet(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedMedicationSet)) {
      form.setFieldsValue(selectedMedicationSet);
    } else {
      form.setFieldsValue({ refills: 0 });
    }
  }, [selectedMedicationSet]);

  function procedureActionClickHanlder(identifier) {
    switch (identifier) {
      case 'delete':
        form.setFieldsValue({
          procedure: null,
        });
        break;
      default:
        break;
    }
  }

  function onSelectProduct(value, index) {
    form.setFieldValue('medicationSet', form.getFieldValue('medicationSet').map(
      (mSet, i) => {
        if (i === index) {
          const newProd = value.map((o) => ({
            productId: { id: o.id, productId: o.id },
            id: o.id,
            productName: o.productName,
            defaultProduct: false,
          }));
          mSet.supplyGroup = mSet?.supplyGroup ? [...mSet.supplyGroup, ...newProd] : newProd;
          return mSet;
        }
        return mSet;
      },
    ));
  }

  return (
    <div className="add-medication-set-container">
      <div className="add-medication-set-container__body">
        <h4>
          {isNew(id) ? 'Add New ' : 'Update '}
          Medication Set
        </h4>
        <Form
          onFinish={handleSubmit}
          form={form}
          onFinishFailed={() => toast.error('Please fill the form with required fields')}
          onValuesChange={(a) => {
            a?.medicationSet?.forEach((ms, mdIdx) => {
              ms?.supplyGroup?.forEach((sg, sgIdx) => {
                if (sg?.quantityUOM?.ncpdpPreferredTerm) {
                  const findLabelExp = (i) => i.value === sg?.quantityUOM?.ncpdpPreferredTerm;
                  const value = QUANTITY_UOM_OPTIONS.find(findLabelExp)?.label || null;
                  form.setFieldValue('medicationSet', form.getFieldValue('medicationSet').map(
                    (mSet, i) => {
                      if (i === mdIdx) {
                        mSet.supplyGroup = mSet?.supplyGroup.map((sGroup, j) => {
                          if (j === sgIdx) {
                            return {
                              ...sGroup,
                              quantityValue: value,
                              quantityId: QUANTITY_UOM_OPTIONS.find(findLabelExp)?.value,
                            };
                          }
                          return sGroup;
                        });
                        return mSet;
                      }
                      return mSet;
                    },
                  ));
                }
              });
            });
          }}
        >
          <div className="input-field-wrapper">
            <Form.Item
              name="name"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Medication Set Name *"
                placeholder=" Medication Set Name"
                maxLength={250}
              />
            </Form.Item>
            <div className="length">
              {form.getFieldValue('name')?.length || 0}
              /250
            </div>
          </div>
          <div className="input-field-wrapper">
            <Form.Item
              name="procedure"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This Field is required',
                },
                blackListedChars,
              ]}
            >
              <LinkedProcedure
                tag="Procedure"
                procedureActionClickHanlder={procedureActionClickHanlder}
                filter={{ 'where[isProgramOnlyProcedure][not_equals]': true }}
              />
            </Form.Item>
          </div>
          <br />
          <div className="input-field-wrapper">
            <Form.Item
              name="pharmacyNpi"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Pharmacy NPI *"
                placeholder=" Pharmacy NPI"
                maxLength={250}
              />
            </Form.Item>
          </div>
          <div className="input-field-wrapper">
            <Form.Item
              name="diagnosisIcdTenCode"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Diagnosis *"
                placeholder=" Diagnosis"
                maxLength={20}
              />
            </Form.Item>
          </div>
          <div className="input-field-wrapper">
            <Form.Item
              name="diagnosisName"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Diagnosis Description *"
                placeholder=" Diagnosis Description"
                maxLength={250}
              />
            </Form.Item>
          </div>
          <div className="medication-three-fields-wrapper">
            <Form.Item
              name="vendorName"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <DropDown
                label=" Vendor Name *"
                placeholder=" Vendor Name"
                className="medication-set-dropdown"
                options={[
                  // 'surescript',
                  'Empower',
                ]}
              />
            </Form.Item>
            <Form.Item
              name="daySupply"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Days Supply *"
                placeholder=" Days Supply"
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              name="shippingOption"
              className="formfield"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Shipping Option *"
                placeholder=" Shipping Option"
                maxLength={250}
              />
            </Form.Item>
            <Form.Item
              name="refills"
              className="formfield"
              rules={[
                {
                  validator: failSafeEmptyFieldValidator,
                  // min: 0,
                  // max: ,
                  message: 'This field is required',
                },
                allowOnlyNumbers,
              ]}
            >
              <InputField
                label=" Refills *"
                placeholder=" Refills"
                maxLength={250}
              />
            </Form.Item>
          </div>
          <Form.List
            name="medicationSet"
            rules={[
              {
                validator: emptyFieldValidator,
                message: 'This field is required',
              },
              blackListedChars,
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((medicationSupplyObject, index) => (
                  <MedicationSupply
                    medicationSupplyObject={medicationSupplyObject}
                    medicationSet={form.getFieldValue('medicationSet')[index] || null}
                    key={`MS_${index}`}
                    removeSupply={() => remove(medicationSupplyObject.name)}
                    onSelectProduct={(value) => onSelectProduct(value, index)}
                  />
                ))}
                <span
                  className="add-new-medication-supply-btn"
                  role="presentation"
                  onClick={() => add()}
                >
                  <AddMedicationSupply />
                </span>
              </>
            )}
          </Form.List>
          <div className="action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>
              Cancel
            </Button>
            <Button
              loading={isLoading}
              variant={Button.variant.filled}
              htmlType="submit"
            >
              {isNew(id) ? 'Save' : 'Update'}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
