import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getMyProviders } from 'redux-store/myProviders/myProvidersActions';
import { URLS } from 'routes';
import { ProviderBanner } from 'shared/components/providerBanner/ProviderBanner';
import { getUniqueStates } from 'redux-store/userManagementSetup/userManagementSetupActions';
import _ from 'lodash';
import { listProviderType } from 'redux-store/providerType/providerTypeActions';
import About from './About';
import Delivery from './Delivery';
import EducationCareer from './EducationCareer';

export default function Providers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uniqueStates } = useSelector((state) => state.userManagementSetups);

  const { id } = useParams();

  useEffect(() => {
    dispatch(listProviderType({ limit: 0 }));
    if (uniqueStates === undefined || _.isEmpty(uniqueStates)) {
      dispatch(getUniqueStates({ limit: 0, page: 1 }));
    }
  }, [id]);

  useEffect(() => {
    if (uniqueStates && uniqueStates?.length > 0) {
      dispatch(getMyProviders({ id, uniqueStates }));
    }
  }, [uniqueStates]);

  return (
    <div className="my-provider-container">
      <ProviderBanner
        fromPage={{
          label: 'My Providers',
          onClick: () => navigate(`${URLS.MY_PROVIDERS_URL}`),
        }}
      />
      <About />
      <EducationCareer />
      <Delivery />
    </div>
  );
}
