/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { createMedia } from 'redux-store/media/mediaActions';
import { PROMOCODE_IMAGE } from 'shared/constants/global';

import { listProgram } from 'redux-store/programs/programActions';
import { PROMOS_SETUPS_API_URL, MARKETING_CAMPAIGN_API_URL, PROMOS_MEMBERS_API_URL } from './promosSetupUrls';

export const listPromosSetup = createAsyncThunk(
  'promosSetupSlice/listPromosSetup',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROMOS_SETUPS_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const getPromosSetup = createAsyncThunk(
  'promosSetupSlice/getPromosSetup',
  async (id) => {
    return axios.get(PROMOS_SETUPS_API_URL(id)).then(({ data }) => data);
  },
);

export const createPromosSetup = createAsyncThunk(
  'promosSetupSlice/createPromosSetup',
  async (params) => {
    const img = await createMedia(params.promoCodeImage, PROMOCODE_IMAGE);
    return axios.post(PROMOS_SETUPS_API_URL(), {
      ...params,
      promoCodeImage: _.get(img, 'doc.id'),
    }).then(({ data }) => {
      toast.success('Promo Code created successfully');
      if (params._status === 'published') {
        toast.success('Promo Code is live');
      }
      return data;
    });
  },
);

export const updatePromosSetup = createAsyncThunk(
  'promosSetupSlice/updatePromosSetup',
  async ({ id, params }) => {
    let imgID = _.get(params, 'promoCodeImage', null);
    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.promoCodeImage, PROMOCODE_IMAGE);
      imgID = img.doc.id;
    }
    return axios.patch(PROMOS_SETUPS_API_URL(id), {
      ...params,
      promoCodeImage: imgID,
    }).then(({ data }) => {
      toast.success('Promo has been updated');
      return data;
    }).catch((e) => {
      console.log(e, '----');
    });
  },
);

export const updatePromosDeepLink = createAsyncThunk(
  'promosSetupSlice/updatePromosDeepLink',
  async ({ id, params }, { dispatch }) => {
    const url = params?.tag === 'PromoCode' ? 'promo-code/text-message' : 'program/text-message';
    const tag = params?.tag === 'PromoCode' ? 'promoCodeId' : 'programId';
    const payload = {
      [tag]: id,
      ...params,
    };
    return axios.post(MARKETING_CAMPAIGN_API_URL(url), payload).then(({ data }) => {
      toast.success(params?.tag === 'PromoCode' ? 'Promo Deep Link has been updated' : 'Program Deep Link has been updated');
      if (params?.tag === 'PromoCode') {
        dispatch(listPromosSetup({ depth: 0 }));
      } else {
        dispatch(listProgram({ depth: 0 }));
      }

      return data;
    }).catch((e) => {
      console.log(e, '----');
    });
  },
);

export const getDeepLinkMessages = createAsyncThunk(
  'promosSetupSlice/getDeepLinkMessages',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(MARKETING_CAMPAIGN_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getDeepLinkSettings = createAsyncThunk(
  'promosSetupSlice/getDeepLinkSettings',
  async () => {
    return axios.get(MARKETING_CAMPAIGN_API_URL('settings')).then(({ data }) => data);
  },
);

export const updateStatusPromosSetup = createAsyncThunk(
  'promosSetupSlice/updateStatusPromosSetup',
  async (data, { dispatch }) => {
    return axios.patch(PROMOS_SETUPS_API_URL(data?.id), { id: data?.id, '_status': data?.status, effectiveDate: data?.effectiveDate })
      .then(() => {
        toast.success(`${data?.status === 'cancelled' ? 'Promo Code has been cancelled' : 'Promo Code is live'}`);
        dispatch(listPromosSetup({ depth: 0 }));
      });
  },
);

export const cancelPromosSetup = createAsyncThunk(
  'promosSetupSlice/cancelPromosSetup',
  async (data, { dispatch }) => {
    return axios.patch(PROMOS_SETUPS_API_URL(`${data?.id}/cancel`), {})
      .then(() => {
        toast.success('Promo Code has been cancelled');
        dispatch(listPromosSetup({ depth: 0 }));
      });
  },
);

export const getMembers = createAsyncThunk(
  'promosSetupSlice/getMembers',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROMOS_MEMBERS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getMembersByID = createAsyncThunk(
  'promosSetupSlice/getMembersByID',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROMOS_MEMBERS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);
