/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getComplaints } from 'redux-store/complaints/complaintsActions';
import { getMyProviders } from 'redux-store/myProviders/myProvidersActions';
import { URLS } from 'routes';
import { ProviderBanner } from 'shared/components/providerBanner/ProviderBanner';
import { getUniqueStates } from 'redux-store/userManagementSetup/userManagementSetupActions';
import ChatUI from '../providerList/Chat';
import ComplaintDetailSection from './DetailsSection';

export default function ComplaintDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedComplaints } = useSelector((state) => state.complaints);
  const { uniqueStates } = useSelector((state) => state.userManagementSetups);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getComplaints(id));
    }
    if (uniqueStates === undefined || _.isEmpty(uniqueStates)) {
      dispatch(getUniqueStates({ limit: 0, page: 1 }));
    }
  }, [id]);

  useEffect(() => {
    if (selectedComplaints?.providerId?.id && uniqueStates) {
      dispatch(getMyProviders({ id: selectedComplaints?.providerId?.id, uniqueStates }));
    }
  }, [selectedComplaints?.providerId?.id, uniqueStates]);

  return (
    <div className="my-provider-container">
      <ProviderBanner
        fromPage={{
          label: 'Complaints',
          onClick: () => navigate(`${URLS.COMPLAINTS_URL}`),
        }}
      />
      <div className="my-provider-container__body">
        <ComplaintDetailSection />
      </div>
      {!_.isEmpty(selectedComplaints) && (
        <div style={{ width: '45%' }}>
          <ChatUI
            to={{
              id: selectedComplaints?.memberId?.id,
              biography: {
                providerPhoto: selectedComplaints?.memberId?.profilePhoto,
              },
              firstName: selectedComplaints?.memberId?.demographics?.firstName,
              lastName: selectedComplaints?.memberId?.demographics?.lastName,
            }}
            complaintId={selectedComplaints?.id}
          />
        </div>
      )}
    </div>
  );
}
