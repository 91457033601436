/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

// eslint-disable-next-line no-unused-vars
import { PROGRAM_CANCELLATION_POLICY_API_URL } from './programCancellationPolicyUrls';

export const listProgramCancellationPolicy = createAsyncThunk(
  'programCancellationPolicySlice/listProgramCancellationPolicy',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROGRAM_CANCELLATION_POLICY_API_URL(`versions?${query}`)).then(({ data }) => data);
  },
);

export const getProgramCancellationPolicyLive = createAsyncThunk(
  'programCancellationPolicySlice/getProgramCancellationPolicyLive',
  async () => {
    return axios.get(PROGRAM_CANCELLATION_POLICY_API_URL()).then(({ data }) => data);
  },
);

export const getProgramCancellationPolicy = createAsyncThunk(
  'programCancellationPolicySlice/getProgramCancellationPolicy',
  async (id) => {
    return axios.get(PROGRAM_CANCELLATION_POLICY_API_URL(`versions/${id}`)).then(({ data }) => data);
  },
);

export const createProgramCancellationPolicy = createAsyncThunk(
  'programCancellationPolicySlice/createProgramCancellationPolicy',
  async (params) => {
    return axios.post(
      PROGRAM_CANCELLATION_POLICY_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      params,
    ).then(({ data }) => {
      toast.success('Program Cancellation Policy has been created');
      return data;
    });
  },
);

export const updateProgramCancellationPolicy = createAsyncThunk(
  'programCancellationPolicySlice/updateProgramCancellationPolicy',
  async ({ id, params }) => {
    return axios.post(
      PROGRAM_CANCELLATION_POLICY_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      { ...params, id },
    ).then(({ data }) => {
      toast.success('Program Cancellation Policy has been updated');
      return data;
    });
  },
);

export const deleteProgramCancellationPolicy = createAsyncThunk(
  'programCancellationPolicySlice/deleteProgramCancellationPolicy',
  async (id, { dispatch }) => {
    return axios.patch(PROGRAM_CANCELLATION_POLICY_API_URL(id), {
      deletedInfoField:
      { isDeleted: true },
    })
      .then(() => {
        toast.success('Program Cancellation Policy has been deleted');
        return dispatch(listProgramCancellationPolicy());
      });
  },
);
