import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import Grid from 'shared/components/grid/Grid';
import { GiveawayCriteriaGridColumns } from 'utils/grid/columns';
import './_criteria.scss';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { listGiveawayCriterias } from 'redux-store/criteria/criteriaActions';

export default function Criteria() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, depth: 0, searchText: '',
  };
  const [params, setParams] = useState(INITIAL_PARAMS);

  const { criterias } = useSelector((state) => state.criteria);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.GIVEAWAYS_CRITERIA_URL}/${id}`);
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.GIVEAWAYS_CRITERIA_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    editClick(item?.id);
  };

  useEffect(() => {
    dispatch(listGiveawayCriterias({ ...params }));
  }, [params]);

  const onSearch = (v) => {
    setParams({
      limit: 10,
      page: 1,
      'where[and][1][criteriaName][like]': v,
    });
  };

  return (
    <div className="provider-list-container program-listing">
      <div className="provider-list-container__header">
        <div className="program-member-filter">
          <div>
            <span className="search-label">Search</span>
            <SearchBox placeholder="Search by Criteria name" waitTime={500} onChange={onSearch} />
          </div>
        </div>
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Criteria
          </div>
        </Button>
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={GiveawayCriteriaGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )}
          data={criterias.docs}
          pagination={{
            total: criterias.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}
