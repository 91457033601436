const licenses = (uniqueStates) => uniqueStates
  ?.map(
    (code) => `${code.stateCode}{ requireSupervision licenseExpirationDate licenseType{licenseTypeName} licenseNumber licenseImage{url}}`,
  )
  .join(' ');

export const getProviderPayload = (id, uniqueStates) => {
  const query = `query getProviderById($id: String!) { Provider(id: $id) { backgroundCheck{backgroundCheckOrder{iProspectReportId id consentDocument{filename} webhookRequests{requestBody}} passedCountyCriminal passedNationalCriminalSexOffender passedFacisLevelThree passedNationalCriminalSexOffenderByPrm passedNationalCriminalSexOffenderByPrmUpdatedDate passedCountyCriminalByPrm passedCountyCriminalByPrmUpdatedDate passedFacisLevelThreeByPrm passedFacisLevelThreeByPrmUpdatedDate medicalLicenses{ id passedMedicalLicense passedMedicalLicenseByPrm passedMedicalLicenseByPrmUpdatedDate stateCode }} biography{ gender selfDescription languagesSpoken{ name deletedInfoField{ isDeleted } updatedAt createdAt } providerPhoto{ url deletedInfoField{ isDeleted } updatedAt createdAt } providerVideo{ url deletedInfoField{ isDeleted } updatedAt createdAt }} certifications{ certificationName certificationImage{ url }} completedHipaaTrainingDates{ date } conciergePractice{ maxMembers id approvedForPrivatePractices approvedForPrivatePracticesDate } defaultDeliveryMethods{ active deliveryMethod{internalIdentifier}address{id addressName addressLine1 city stateCode postalCode location}} deliveryAddresses{ hsAccountNumber updatedAt createdAt } fellowship{ fellowshipName stateCode } firstName fullName graduateEducation{ degree universityName graduationYear stateCode diplomaImage{ url }} homeAddress{ addressLine1 addressName postalCode location city stateCode } insurancePlan{ planName id } id internship{ internshipName stateCode } lastName licensedStateCodes licenses{ ${licenses(
    uniqueStates,
  )}} manualVerification middleName patientAgeHigh patientAgeLow primaryCounty{ countyName stateName } providerRelationshipManager{ profilePhoto{url} firstName lastName middleName counties{ countyName stateCode } startDate} providerStatus providerStartDate providerType{ canCreatePrivatePractice canSupervise providerTypeName} rating ratingCount residency{ residencyName stateCode} undergraduateEducation{ degree universityName universityName graduationYear stateCode diplomaImage{url}} verificationChecklist{ backgroundCheck{ verified verifiedById{ id } timestamp } boardCertification{ verified verifiedById{ id } timestamp } supervisorCheck{ verified verifiedById{ id } timestamp } profileComplete{ verified verifiedById{ id } timestamp  } availabilityCreated{ verified verifiedById{ id } timestamp  } hipaaTraining{ verified verifiedById{ id } timestamp  } henryScheinOrderSetup{ verified verifiedById{ id } timestamp  } meetAndGreet{ verified verifiedById{ id } timestamp  }} workHistories{ startDate employerName endDate employerAddress{ addressLine1 addressLine2 city stateCode postalCode }}}}`;
  return { operationName: 'getProviderById', variables: { id }, query };
};

export const getProviderPayloadMin = (id) => {
  const query = 'query getProviderById($id: String!) { Provider(id: $id) {id fullName firstName lastName middleName homeAddress{location postalCode} licensedStateCodes}}';
  return { operationName: 'getProviderById', variables: { id }, query };
};
