import { createSlice } from '@reduxjs/toolkit';
import {
  getWelcomeMessage,
  postWelcomeMessage,
} from './welcomeMessageActions';

const INITIAL_STATE = {
  isLoading: false,
  welcomeMessage: {},
};

export const welcomeMessageSlice = createSlice({
  name: 'welcomeMessageSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(getWelcomeMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWelcomeMessage.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getWelcomeMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.welcomeMessage = action.payload;
    });
    builder.addCase(postWelcomeMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postWelcomeMessage.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postWelcomeMessage.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = welcomeMessageSlice.actions;
