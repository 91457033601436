/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import Grid from 'shared/components/grid/Grid';
import {
  MedicationProcedureGridColumns,
  MedicationProgramsGridColumns,
  MedicationAtHomeLabsGridColumns,
  CategoryGridColumns,
  ProviderGridColumns,
  PlusIcon,
} from 'utils/grid/columns';
import Button from 'shared/components/button/Button';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import AddNewProcedureList from './AddNewProcedureList';

export default function LinkedProcedure({
  value,
  onChange,
  procedureActionClickHanlder,
  showGrid = true,
  multiple = false,
  tag = 'Procedure',
  label = '',
  buttonText = '',
  showIcon = false,
  showLabel = true,
  filter = null,
  disabled = false,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [procedure, setProcedure] = useState([]);

  useEffect(() => {
    if (value !== null && value !== undefined && !multiple) {
      setProcedure([value]);
    } else if (value?.length > 0 && multiple) {
      setProcedure(value ?? []);
    } else {
      setProcedure([]);
    }
  }, [value]);

  function linkProcedure() {
    setDialogOpen(true);
  }

  const onSelect = (v) => {
    if (!multiple) {
      onChange(v?.[0] || null);
    } else {
      onChange(v || null);
    }

    setDialogOpen(false);
  };

  function ActionClickHanlder(i, row) {
    switch (i) {
      case 'edit':
        setDialogOpen(true);
        break;
      default:
        return procedureActionClickHanlder(i, row);
    }
    return null;
  }

  return (
    <>
      <div className="input-field-wrapper">
        {showLabel && (
          <>
            <label htmlFor="procedure_link_btn">
              {label || `${tag} to link to *`}
            </label>
            <br />
          </>
        )}

        { !showIcon ? (
          <Button
            id="procedure_link_btn"
            onClick={linkProcedure}
            variant={Button.variant.filled}
            disabled={disabled}
          >
            { buttonText || `Add ${tag}`}
          </Button>
        ) : (
          !disabled && (
          <div className="add-icon" onClick={linkProcedure}>
            <PlusIcon />
            <label>
              { buttonText || `Add ${tag}`}
            </label>
          </div>
          )
        )}
      </div>
      {showGrid && (
        <>
          <br />
          <div className="input-field-wrapper">
            <label htmlFor="linked_procedures">
              Linked
              {' '}
              {tag}
            </label>
            <Grid
              columns={tag === 'Provider' ? ProviderGridColumns({ ActionClickHanlder }) : tag === 'Category' ? CategoryGridColumns({ ActionClickHanlder }) : tag === 'Lab' ? MedicationAtHomeLabsGridColumns({ ActionClickHanlder }) : tag === 'Program' ? MedicationProgramsGridColumns({ ActionClickHanlder }) : MedicationProcedureGridColumns({ ActionClickHanlder })}
              data={procedure}
              pagination={false}
            />
          </div>
        </>
      )}
      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen}
        title={`Select ${tag}`}
        handleOk={() => {}}
        handleCancel={() => setDialogOpen(false)}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <AddNewProcedureList
          filter={filter}
          tag={tag}
          multiple={multiple}
          value={value}
          onChange={onSelect}
        />
      </DialogPopup>
    </>
  );
}
